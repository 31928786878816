import Cookie from 'js-cookie';

export default {
    props: {
        referenceType: {
            type: String,
            default: 'customer'
        },
        referenceId: {},
        canAdd: {
            type: Boolean,
            default: true,
        },
        canDelete: {
            type: Boolean,
            default() {
                return this.canAdd;
            },
        },
        buttonText: {
            type: String,
            default: 'Add Files'
        },
        mimeTypes: {
            type: Array,
            default() {
                return [];
            }
        },
        autoUpload: {
            type: Boolean,
            default: false
        },
        autoLoadFiles: {
            type: Boolean,
            default: true
        },
        group: {
            type: String,
            required: true
        },
        multiple: {
            type: Boolean,
            default: true
        },
        allowDrop: {
            type: Boolean,
            default: false
        },
        metaData: {
            type: Object,
            default() {
                return {};
            }
        },
    },

    computed: {
        uploadUrl() {
            return `${import.meta.env.VITE_API_URL}/${this.referenceType}/${this.referenceId}/files`;
        },
        uploadHeaders() {
            return {
                'Authorization': 'Bearer ' + Cookie.get('vat')
            };
        },
        uploadParams() {
            return {
                group: this.group,
                ...(Object.keys(this.metaData).length ? {data: this.metaData} : {})
            };
        }
    }
}
