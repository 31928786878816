<template>
    <form-group :name="name" :label="label" :errors="errors" :required="required">
        <div slot="actions">
            <a v-if="todaySelector" href="#" :class="{ 'disabled': disabled }" @click.prevent="selectToday">Today</a>
        </div>

        <ks-datepicker
            :month-picker="true"
            :value="value"
            :disabled="disabled"
            :min-date="minDate"
            @input="emitInput"
        ></ks-datepicker>

        <slot></slot>
    </form-group>
</template>

<script>
    import { formatDate } from 'kickstart-ui/lib/dates';

    import { snakeToTitle } from 'helpers/strings.js';

    export default {
        name: 'DateSelect',

        props: {
            value: {
                type: String,
            },

            name: {
                type: String,
                required: true,
            },

            label: {
                type: String,
                default() {
                    return snakeToTitle(this.name);
                },
            },

            errors: {
                type: Object,
                default: () => ({}),
            },

            required: {
                type: [Boolean, Number],
                default: false,
            },

            disabled: {
                type: Boolean,
                default: false,
            },

            minDate: {
                type: String,
                default: null,
            },

            todaySelector: {
                type: Boolean,
                default: true,
            },
        },

        methods: {
            selectToday() {
                this.emitInput(formatDate(new Date(), 'Y-m-d'));
            },

            emitInput(value) {
                this.$emit('input', value);
            },
        }
    }
</script>
