import MatchVisitFiltersToggle from '@Vault/WorkOrder/components/Show/MatchVisitFiltersToggle.vue';

export default {
    props: {
        selectedCount: {
            type: Number,
            default: 0,
        },

        canUseFilters: {
            default: false
        },

        formFilters: {
            default() {
                return {};
            }
        },

        searchResultCount: {
            type: Number,
            default: 0
        }
    },

    data() {
        return {
            match_all: false,
        }
    },

    methods: {
        applySearchFilters(filters) {
            if ( this.canUseFilters && this.match_all ) {
                return {
                    ...filters,
                    form_filters: this.formFilters,
                    match_all: this.match_all
                }
            }
            return filters;
        }
    },

    components: {
        MatchVisitFiltersToggle
    }
}
