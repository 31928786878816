<template>
    <div class="admin-data-sources">
        <router-view></router-view>
    </div>
</template>


<script>
    import BreadCrumbs from 'BreadCrumbs';

    export default {
        name: 'AdminDataSources',

        mixins: [
            BreadCrumbs,
        ],

        props: {},

        computed: {},

        data() {
            return {};
        },

        beforeMount() {
            this.pushBreadcrumb({
                title: 'Data Sources',
                to: {
                    name: 'admin-data-sources-list'
                }
            });
        },


        mounted() {

        },

        methods: {},

        components: {},
    }
</script>
