import AdminCertifications from '@Vault/Admin/Certifications/components/AdminCertifications.vue';

export default [
    {
        path: 'certifications',
        component: AdminCertifications,
        children: [
            {
                path: '',
                name: 'admin-certifications',
                component: AdminCertifications,
                props: true,
                meta: {
                    perms: ['certifications_view'],
                },
            },
            {
                path: 'types',
                name: 'admin-certification-types',
                component: AdminCertifications,
                props: true,
                meta: {
                    perms: ['certification_types_manage'],
                },
            },
        ],
    },
]
