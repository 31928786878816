<template>
    <ks-modal ref="modal" title="Impersonation Error" :danger="true" max-width="480px">
        <div>An error occurred trying to impersonate. Reload the page and try impersonating the user again.</div>

        <div slot="footer">
            <vault-button class="error" :spin="spin" @click.prevent="reload">Reload</vault-button>
            <button class="button outline" @click.prevent="$refs.modal.close()">Cancel</button>
        </div>
    </ks-modal>
</template>

<script>
    export default {
        name: 'CsrfErrorModal',

        data() {
            return {
                spin: false,
            };
        },

        methods: {
            open() {
                this.$refs.modal.open();
            },

            reload() {
                this.spin = true;
                window.location.reload();
            },
        },
    };
</script>
