/**
 * Transform a custom field value into a string for display
 *
 * @param {CustomField} field
 * @param {*} value
 * @returns {String}
 */
export function displayValue(field, value) {
    // Check if the field has options
    if ( field.requires_options ) {
        // For options we need a dictionary
        if ( 'dictionary' in field ) {
            // For multi value fields we should expect an array of values
            if ( field.is_multi_value_field ) {
                if ( typeof value != 'object' ) {
                    value = [value];
                }

                return value?.map(v => v in field.dictionary ? field.dictionary[v] : v)?.join(', ');
            } else {
                // For simple option fields just lookup the value in the dictionary
                if ( value in field.dictionary ) {
                    return field.dictionary[value];
                }
            }
        }
    }

    return value;
}

/**
 * Normalize a value for comparison, converting numbers to strings, nulls => '', and sorting array values.
 * Arrays containing only empty values are returned as ''.
 *
 * @param value
 * @returns {string|string[]}
 */
export function normalizeValueForComparison(value) {
    if ( Array.isArray(value) ) {
        let normalized_values = value.map(normalizeValueForComparison).sort();

        // if the array has any non-empty values, return the entire array
        for ( let normalized_value of normalized_values ) {
            if ( !['', null, undefined].includes(normalized_value) ) {
                return normalized_values;
            }
        }

        // otherwise return an empty string
        return '';
    }

    if ( value !== null && typeof value === 'object' ) {
        value = JSON.stringify({...value});
    }

    return ((typeof value === 'number') ? String(value) : value ?? '').trim();
}
