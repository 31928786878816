const List = () => import('./components/ExportTemplateIndex.vue');
const Create = () => import('./components/ExportTemplateCreate.vue');
const Edit = () => import('./components/ExportTemplateEdit.vue');

export default [
    {
        path: 'list',
        name: 'admin-exports-list',
        component: List
    },
    {
        path: 'create',
        name: 'admin-exports-create',
        component: Create
    },
    {
        path: 'edit/:export_template_id',
        props: true,
        name: 'admin-exports-edit',
        component: Edit
    },
]
