<template>
    <div>
        <router-view></router-view>
    </div>
</template>

<script>
    import BreadCrumbs from '../../../../BreadCrumbs';

    export default {
        name: 'AdminBillingTerms',

        mixins: [BreadCrumbs],

        beforeMount() {
            this.pushBreadcrumb({
                title: 'Billing: Terms',
                to: {
                    name: 'admin-price-profiles'
                }
            });
        },
    }
</script>
