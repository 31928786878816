import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

import routes from './routes';

/**
 * Immediately refresh the browser to the given href
 * @param {string} href
 */
export const refresh = (href) => {
    console.info(`${import.meta.env.VITE_APP_NAME} has been updated, refreshing...`);
    window.location.href = href;
}

const router = new VueRouter({
    base: '/app/',
    mode: 'history',
    routes,

    /**
     * Customizes scroll behavior when navigating between pages using the back
     * button or when navigating between fragments on the same route. For
     * similar functionality on mounted() when navigating between routes,
     * @see UI/mixins/ScrollsToHash.js
     */
    scrollBehavior(to, from, position) {
        if ( position ) {
            // If clicking back, return to the previously scrolled location.
            return position;

        } else if ( to.hash ) {
            // If the route has a hash (anchor link), scroll to the target
            // element. Use an offset so the scroll target doesn't end up
            // below any sticky/header elements.
            return { selector: to.hash, offset: { x: 0, y: 150 }, behavior: 'smooth' };

        } else {
            // For all other routes, scroll to the top of the page.
            return { x: 0, y: 0, behavior: 'smooth' };
        }
    },
});

export default router;
