<template>
    <div id="inventory-products">
        <ks-loader-spin v-if="loading" :show="true" size="large"></ks-loader-spin>

        <router-view v-else></router-view>
    </div>
</template>

<script>
    import BreadCrumbs from '@Vault/BreadCrumbs';

    export default {
        name: 'InventoryProducts',

        mixins: [BreadCrumbs],

        data() {
            return {
                loading: false,
            };
        },

        beforeMount() {
            this.resetBreadcrumb({
                title: 'Inventory',
                to: { name: 'inventory-dashboard' },
            });

            this.pushBreadcrumb({
                title: 'Products',
                to: { name: 'inventory-products' },
            });
        },
    }
</script>
