<template functional>
    <div :ref="data.ref"
         :class="[data.class, data.staticClass]"
         :style="[data.style, data.staticStyle]"
         v-bind="data.attrs"
    >
        <form-group>
            <ks-checkbox
                :checked="props.matchAll"
                :value="true"
                :false-value="false"
                v-on="listeners.input ? {input: listeners.input} : {}"
                class="mt-4"
            >
                {{props.actionLabel}} additional
                {{props.itemLabel}} matching the filter criteria?
            </ks-checkbox>
        </form-group>

        <div class="form-group text-small muted ml-6 pl-1">
            {{props.additionalCount}} more than selected
        </div>
    </div>
</template>
<script>

    export default {
        name: 'MatchVisitFiltersToggle',

        model: {
            prop: 'matchAll',
            event: 'input'
        },

        props: {
            actionLabel: {
                default: ""
            },
            itemLabel: {
                default: 'records'
            },
            matchAll: {
                default: false
            },
            additionalCount: {
                default: 0
            }
        },
    }
</script>