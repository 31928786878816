<template>
    <div class="sm-12">
        <router-view></router-view>
    </div>
</template>

<script>
    // External Dependencies

    // Internal Dependencies
    import BreadCrumbs from 'BreadCrumbs';

    export default {
        name: 'WorkQuotes',

        mixins: [BreadCrumbs],

        beforeMount() {
            this.pushBreadcrumb({
                title: 'Quotes',
                to: {
                    name: 'work-quotes'
                }
            });
        }
    }
</script>