<template>
    <div v-if="$userCan('admin_view_data_map')">
        <div v-for="data_map in dataMaps" class="flex align-center muted" style="cursor: default">
            <slot name="icon" :icon="icons.sync">
                <vault-icon :icon="icons.sync" class="mr-2.5 text-purple-300 text-xs"></vault-icon>
            </slot>
            <div class="mr-2.5">
                <div class="muted">{{data_map.source_name}}: {{data_map.source_id}}</div>
                <ks-tooltip trigger="click">
                    <div class="text-xs muted">
                        <strong>Last Update:</strong> <br/>
                        {{data_map.updated_at | date }}
                        {{data_map.updated_at | time }}
                        ({{data_map.updated_tz}})
                    </div>
                </ks-tooltip>
            </div>
        </div>
    </div>
</template>


<script>
    import {faExchangeAlt} from '@fortawesome/pro-duotone-svg-icons/faExchangeAlt';

    export default {
        name: 'TargetDataMapsView',

        props: {
            target: {
                type: Object,
                required: true,
            },
        },

        computed: {
            dataMaps() {
                return (this.target.target_data_maps || []).map((data_map) => {
                    return {
                        ...data_map,
                        source_name: data_map.data_source?.source_name
                    };
                });
            }
        },

        data() {
            return {
                icons: {
                    sync: faExchangeAlt,
                }
            };
        },
    }
</script>
