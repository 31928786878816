<template>
    <div>
        <router-view></router-view>
    </div>
</template>

<script>

    import BreadCrumbs from '../../../BreadCrumbs';

    export default {
        name: 'AdminPriceProfiles',

        mixins: [BreadCrumbs],

        beforeMount() {
            this.pushBreadcrumb({
                title: 'Billing: Price Profiles',
                to: {
                    name: 'admin-price-profiles'
                }
            });
        },

    }
</script>
