<template>
    <div class="custom-fields-view">
        <slot :values="customFieldValues">
            <ul v-if="display === 'list'" class="basic-list no-border">
                <li
                    v-for="(value, custom_field_id, index) in customFieldValues"
                    :key="custom_field_id"
                    class="no-border slim"
                    :class="{ ...liClass, 'mt-2': index > 0 }"
                >
                    <custom-fields-view-value
                        :value="value"
                        :muted-titles="mutedTitles"
                        :uppercase-titles="uppercaseTitles"
                        :text-size="textSize"
                    ></custom-fields-view-value>
                </li>
            </ul>

            <div v-else-if="display === 'flex'" class="row row-collapse">
                <custom-fields-view-value
                    v-for="(value, key) in customFieldValues"
                    :key="key"
                    class="mt-2 sm-12"
                    :class="{ 'tab-6 lg-4': isColumnable(value) }"
                    :value="value"
                    :muted-titles="mutedTitles"
                    :uppercase-titles="uppercaseTitles"
                    :text-size="textSize"
                ></custom-fields-view-value>
            </div>
        </slot>
    </div>
</template>


<script>
    import CustomFieldValuesHelper from '@Vault/CustomFields/mixins/CustomFieldValuesHelper';
    import ServiceItemConditionsMixin from '@Vault/CustomFields/mixins/ServiceItemConditionsMixin.js';
    import ConditionChecker from '@Vault/Forms/helpers/ConditionChecker';
    import CustomFieldsViewValue from '@Vault/CustomFields/components/CustomFieldsViewValue.vue';

    export default {
        name: 'CustomFieldsView',

        mixins: [
            CustomFieldValuesHelper,
            ServiceItemConditionsMixin,
        ],

        props: {
            fieldValues: {
                type: Array,
                required: true,
            },
            liClass: {
                type: Object,
                default() {
                    return {}
                }
            },
            uppercaseTitles: {
                type: Boolean,
                default: true
            },
            textSize: {
                type: String,
                default: 'tiny'
            },
            skipTransform: {
                type: Boolean,
                default: false,
            },
            mutedTitles: {
                type: Boolean,
                default: true,
            },
            customer: {},
            location: {},
            display: {
                type: String,
                default: 'list',
                validator: (value) => ['list', 'flex'].includes(value),
            },
        },

        computed: {
            customFieldValues() {
                return this.skipTransform
                    ? this.checkedFieldValues
                    : this.transformValues(this.checkedFieldValues);
            },

            fieldValuesDictionary() {
                return this.transformValues(this.fieldValues, true);
            },

            checkedFieldValues() {
                return this.fieldValues.filter(({field}) => this.conditionCheck(field));
            },
        },

        methods: {
            conditionCheck(field) {
                return (new ConditionChecker(field?.options?.conditions))
                    .testConditions(this.fieldValuesDictionary, this.location, null, this.customer, this.conditionsServiceItemData);
            },

            isColumnable(value) {
                let field = Array.isArray(value) ? value[0].field : value.field;
                return !['textarea', 'signature'].includes(field.field_type);
            },
        },

        components: {
            CustomFieldsViewValue,
        },
    }
</script>
