const WorkQuotes = () => import('./components/WorkQuotes.vue');
const WorkQuoteList = () => import('./components/WorkQuoteList.vue');
const WorkQuoteForm = () => import('./components/WorkQuoteForm.vue');

export default [
    {
        path: 'quotes',
        component: WorkQuotes,
        meta: {
            group: 'main',
            title: 'Quotes',
            perms: ['work_quote_manage', 'work_quote_publish', 'work_quote_delete']
        },
        children: [
            {
                path: '',
                component: WorkQuoteList,
                name: 'work-quotes',
            },
            {
                path: 'create',
                component: WorkQuoteForm,
                name: 'work-quote-create',
            },
            {
                path: ':work_quote_number(\\d+)/edit',
                component: WorkQuoteForm,
                name: 'work-quote-edit',
                props: true,
            }
        ]
    },
]
