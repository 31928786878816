<template>
    <div>
        <page-header>
            <page-breadcrumb></page-breadcrumb>
        </page-header>

        <router-view></router-view>
    </div>
</template>

<script>
    // External Dependencies

    // Internal Dependencies
    import Breadcrumbs from 'BreadCrumbs';

    export default {
        name: 'TaskBase',

        mixins: [Breadcrumbs],

        beforeMount() {
            this.pushBreadcrumb({
                title: 'Tasks',
                to: {
                    name: 'tasks'
                }
            })
        },
    }
</script>
