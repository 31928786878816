// Components
const NotAuthorized = () => import('../Vault/StaticPages/Errors/NotAuthorized.vue');
const NotFound = () => import('../Vault/StaticPages/Errors/NotFound.vue');

export default [
    {
        path: '/not-authorized',
        component: NotAuthorized,
        name: 'not-authorized',
    },
    {
        path: '/not-found',
        component: NotFound,
        name: 'not-found',
    }
]
