// External Dependencies
import api from 'kickstart-ui/lib/api';
import {mapGetters} from 'vuex';

import CsrfErrorModal from '@Vault/Support/CsrfErrorModal.vue';

export default {
    computed: {
        ...mapGetters([
            'user_type',
            'is_impersonating',
            'permissions',
            'is_system_admin',
        ]),
    },

    methods: {
        canImpersonate(user) {
            if ( this.is_system_admin ) {
                return true;
            }

            if (
                this.is_impersonating ||
                !user.role ||
                user.role.role_type === 'account_admin' ||
                !this.$userCan('admin_impersonate_users')
            ) {
                return false;
            }

            return true;
        },

        impersonateUser(user) {
            if ( !this.canImpersonate(user) ) {
                this.$toast({
                    type: 'error',
                    message: this.is_impersonating ?
                        'You are already impersonating a user' :
                        'You do not have access to this feature.'
                });
                return;
            }

            api.post(`/impersonate/${user.user_id}`)
                .then(() => {
                    window.location.href =  !user.customer_id ? '/app/dashboard' : '/customer/dashboard';
                })
                .catch(({response}) => {
                    // handle CSRF error
                    if ( parseInt(response?.status) === 419 ) {
                        this.$refs.csrfErrorModal?.open();
                        return;
                    }

                    this.$toast({
                        type: 'error',
                        message: response?.data?.message || 'An error occurred when trying to impersonate'
                    })
                });
        },

        stopImpersonating() {
            api.delete('/impersonate')
                .then(() => {
                    try {
                        let location = window.sessionStorage.getItem('fossa_impersonate_exit_location');
                        if ( location ) {
                            window.location.href = location;
                            return;
                        }
                    } finally {
                        window.sessionStorage.removeItem('fossa_impersonate_exit_location');
                    }

                    // redirect to APP dashboard if no exit location
                    window.location.href = '/app/dashboard';
                });
        },
    },

    components: {
        CsrfErrorModal,
    },
}
