<template>
    <div class="panel">
        <ul class="page-side-nav">
            <template v-for="link in authorizedLinks">
                <router-link
                    v-if="!link.hide"
                    :key="link.title"
                    :to="link.to"
                    tag="li"
                    :class="{ 'router-link-active': link.active }"
                >
                    <a href="#">{{ link.title }}</a>
                </router-link>
            </template>
        </ul>
    </div>
</template>

<script>
    import { routeIsAuthorized } from 'helpers/permissions.js';

    export default {
        name: 'PageSideNav',

        props: {
            links: {
                type: Array,
                default: () => [],
                validator: (links) => {
                    // links must be non-empty
                    // each link should have a title and a route name
                    return links.length > 0 &&
                        links.every(link => link.title && link.route);
                },
            },
        },

        computed: {
            authorizedLinks() {
                // resolve each link and filter out unauthorized routes
                return this.links.map(link => ({
                        ...link,
                        to: {...this.$router.resolve({name: link.route}).resolved},
                    }))
                    .filter(({to}) => routeIsAuthorized(to));
            },
        },

        created() {
            // Catch unauthorized direct navigation and redirect to first authorized link
            // or 404 as needed. The logic below ensures the current route has an ancestor
            // in one of the authorized links.

            const route_is_authorized = this.authorizedLinks
                .map(({to}) => to.path.replace(/\/$/, ''))
                .some(path => this.$route.path.match(new RegExp(`^(?:${path}).*`)) !== null);

            if ( !route_is_authorized ) {
                this.$router.replace(this.authorizedLinks[0]?.to || {name: 'not-authorized'});
            }
        },
    };
</script>
