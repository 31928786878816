<template>
    <div id="certifications-list">
        <div class="row row-collapse">
            <div class="sm-6 flex">
                <search-bar
                    v-model="term"
                    placeholder="Search..."
                    :delay="300"
                    class="flex-1"
                    @search="updateTerm($event)"
                ></search-bar>

                <div>
                    <saved-search-filters
                        ref="saved_search_filters"
                        namespace="certifications"
                        class="ml-2"
                        @apply="applySavedSearch"
                        @menu-open="clickedFilter('saved_searches')"
                    ></saved-search-filters>
                </div>
            </div>

            <div class="sm-6 t-right">
                <vault-button v-if="$userCan('certifications_manage')" type="add" @click="$refs.editCertificationModal.open()" :disabled="types.length === 0">
                    Add User Certification
                </vault-button>
            </div>
        </div>

        <a
            href="#"
            class="button right text-sm ml-2"
            @click.prevent="exportCertifications"
        >
            Export Certifications
        </a>

        <div v-if="hasFilters || (admin && types.length > 0)" class="text-sm">
            <drop-down-filter
                ref="certification_type_filter"
                name="certification-type"
                :selected="typeFilterSelected"
                @clicked="clickedFilter('certification_type_filter')"
            >
                <template slot="button">
                    {{ typeFilterLabel }}
                </template>

                <div slot="body">
                    <ks-autocomplete
                        v-model="type"
                        name="certification_type_id"
                        empty-message="No certification types found"
                        selection-key="name"
                        :paginated="true"
                        :single-deselect="true"
                        :multiple="true"
                        @search="searchCertificationTypes"
                    ></ks-autocomplete>
                </div>
            </drop-down-filter>

            <drop-down-filter
                ref="user_filter"
                name="user"
                :selected="userFilterSelected"
                @clicked="clickedFilter('user_filter')"
            >
                <template slot="button">
                    User<template v-if="userFilterSelected">: {{ user.display_name }}</template>
                </template>

                <div slot="body">
                    <ks-autocomplete
                        v-model="user"
                        name="user_id"
                        empty-message="No user found"
                        placeholder="Search users..."
                        selection-key="display_name"
                        :paginated="true"
                        :single-deselect="true"
                        @search="searchUsers"
                    ></ks-autocomplete>
                </div>
            </drop-down-filter>

            <drop-down-filter
                ref="user_status_filter"
                name="user_status"
                :selected="user_status !== 'active'"
                @clicked="clickedFilter('user_status_filter')"
            >
                <template v-slot:button>
                    {{userStatusName}}
                </template>

                <div slot="body">
                    <ks-select
                        name="user_status"
                        :items="user_statuses"
                        label-key="status"
                        item-key="value"
                        v-model="user_status"
                    ></ks-select>
                </div>
            </drop-down-filter>

            <drop-down-filter
                ref="expiration_date_filter"
                name="expiration_date"
                :selected="expirationDateFilterSelected"
                @clicked="clickedFilter('expiration_date_filter')"
            >
                <template slot="button">
                    Expiring<template v-if="expirationDateFilterSelected">: {{ expiringText }}</template>
                </template>

                <div slot="body">
                    <form-group class="mb-0">
                        <advanced-date-filter
                            v-model="expiration_date"
                            :within="false"
                            :upcoming="true"
                            :show-title="false"
                        ></advanced-date-filter>
                    </form-group>
                </div>
            </drop-down-filter>

            <drop-down-filter
                ref="state_filter"
                name="state"
                :selected="stateFilterSelected"
                @clicked="clickedFilter('state_filter')"
            >
                <template slot="button">
                    State<template v-if="stateFilterSelected">: {{ state.state_name }}</template>
                </template>

                <div slot="body">
                    <state-dropdown
                        v-model="state"
                        :states="loaded_states"
                    ></state-dropdown>
                </div>
            </drop-down-filter>

            <drop-down-filter
                ref="manager_filter"
                name="manager"
                :selected="managerFilterSelected"
                @clicked="clickedFilter('manager_filter')"
            >
                <template v-slot:button>
                    {{selectManagerLabel}}
                </template>

                <div slot="body">
                    <ks-select
                        name="user_id"
                        label-key="display_name"
                        item-key="user_id"
                        v-model="manager"
                        :single-deselect="true"
                        :paginated="true"
                        @search="searchManagers"
                    ></ks-select>
                </div>
            </drop-down-filter>
        </div>

        <ks-loader-spin v-if="loadingPage" :show="true" size="medium"></ks-loader-spin>

        <div v-else-if="!hasFilters && $userCan('certification_types_manage') && types.length === 0" class="my-4 t-center">
            <router-link :to="{ name: 'admin-certification-types' }">Add a certification type</router-link> to get started.
        </div>

        <template v-else-if="hasCertifications">
            <div class="mt-2 flex align-center jc-between">
                <div class="certifications-count text-sm muted">
                    {{ certificationCountLabel }}
                </div>

                <ks-paginator
                    :pagination="paginator"
                    :routed="false"
                    @input="changePage"
                ></ks-paginator>
            </div>

            <ul class="mt-1 basic-list">
                <li v-if="!groupTypes" style="background-color:#e4e7eb;">
                    <div class="row row-collapse full-width flex align-center text-sm h-6">
                        <div class="sm-5 med-3 lg-4 pl-1">{{ firstColumnText }}</div>
                        <div class="tab-hide-down med-3">Certificate #</div>
                        <div class="sm-3 med-2">Issued</div>
                        <div class="sm-3 med-2">Expires</div>
                        <div class="sm-1"></div>
                    </div>
                </li>

                <template v-if="groupTypes">
                    <template v-for="(type, type_name) in groupedCertifications">
                        <li :key="'certification-type-' + type[0].type.certification_type_id" style="background-color:#e4e7eb;">
                            <div class="row row-collapse full-width flex align-center text-sm bold">
                                <div class="sm-5 med-3 lg-4 pl-1">{{ type_name }}</div>

                                <div class="tab-hide-down med-3">Certificate #</div>
                                <div class="sm-3 med-2">Issued</div>
                                <div class="sm-3 med-2">Expires</div>
                                <div class="sm-1"></div>
                            </div>
                        </li>

                        <li
                            v-for="certification in type"
                            :key="'certification-' + certification.certification_id"
                            class="text-sm"
                            :class="'certification-' + certification.certification_id"
                        >
                            <certifications-list-row
                                :certification="certification"
                                :link-text="certification.user.display_name"
                                @edit="$refs.editCertificationModal.open($event)"
                                @delete="beginDeleteCertification"
                            ></certifications-list-row>
                        </li>
                    </template>
                </template>

                <template v-else>
                    <li
                        v-for="certification in certifications"
                        :key="'certification-' + certification.certification_id"
                        class="text-sm"
                        :class="'certification-' + certification.certification_id"
                    >
                        <certifications-list-row
                            :certification="certification"
                            :link-text="!!certificationTypeId ? certification.user.display_name : certification.type.name"
                            @edit="$refs.editCertificationModal.open($event)"
                            @delete="beginDeleteCertification"
                        ></certifications-list-row>
                    </li>
                </template>
            </ul>
        </template>

        <div v-else class="my-4 t-center">
            No certifications found.
        </div>

        <edit-certification-modal
            ref="editCertificationModal"
            :user-id="userId"
            :certification-type-id="certificationTypeId"
            :admin="admin"
            @saved="loadCertifications"
        ></edit-certification-modal>

        <ks-modal
            v-if="show_delete_certification_modal"
            ref="deleteCertificationModal"
            id="delete-certification-modal"
            title="Delete Certification Type"
            :danger="true"
            @close="closeDeleteCertificationModal(false)"
        >
            <div>Are you sure you want to delete this certification?</div>
            <div class="mt-4 mb-2 px-2 text-sm">
                {{ deleting_certification.user.display_name }} - {{ deleting_certification.type.name }}
                <span v-if="deleting_certification.certificate_number" class="muted">({{ deleting_certification.certificate_number }})</span>
            </div>
            <div slot="footer">
                <vault-button type="delete" :spin="deleting" @click="deleteCertification">Delete</vault-button>
                <button class="button outline" @click.prevent="closeDeleteCertificationModal">Cancel</button>
            </div>
        </ks-modal>

        <simple-export-modal
            ref="export_modal"
            modal-title="Export Certifications"
            :templates="export_templates"
            :filters="exportFilters"
            :auto-export="true"
            export-code="certification"
        >
            <div slot="header">
                Exporting Certifications
            </div>
        </simple-export-modal>
    </div>
</template>

<script>
    // External dependencies
    import { mapActions, mapGetters } from 'vuex';
    import _cloneDeep from 'lodash/cloneDeep';
    import _debounce from 'lodash/debounce';
    import _groupBy from 'lodash/groupBy';

    // Helpers/mixins
    import { arrayWrap } from 'helpers/array.js';
    import { pluralize, snakeToTitle } from 'helpers/strings.js';
    import { formatDateUpcoming } from 'helpers/dates.js';
    import { filterToQueryParam, queryParamToFilter } from 'helpers/filters.js';
    import PageHelper from '@Vault/PageHelper.js';
    import CertificationsMixin from '@Vault/Admin/Certifications/mixins/CertificationsMixin.js';
    import LoadsStatesMixin from '@Vault/Locations/mixins/LoadsStatesMixin.js';

    // Repositories
    import Certifications from '@Vault/Certifications/repositories/CertificationsRepository.js';
    import CertificationTypes from '@Vault/Certifications/repositories/CertificationTypesRepository.js';
    import Users from '@Vault/Users/repositories/UsersRepository.js';
    import Exports from '@Vault/Admin/Exports/repositories/ExportsRepository.js';

    // Components
    import AdvancedDateFilter from 'UI/AdvancedDateFilter.vue';
    import CertificationsListRow from '@Vault/Admin/Certifications/components/CertificationsListRow.vue';
    import DropDownFilter from 'UI/DropDownFilter.vue';
    import EditCertificationModal from '@Vault/Certifications/EditCertificationModal.vue';
    import SavedSearchFilters from '@Vault/SavedSearches/components/SavedSearchFilters.vue';
    import StateDropdown from 'UI/StateDropdown.vue';
    import SimpleExportModal from '@Vault/Admin/Exports/components/SimpleExportModal.vue';

    export default {
        name: 'CertificationsList',

        mixins: [PageHelper, CertificationsMixin, LoadsStatesMixin],

        props: {
            userId: {
                type: [Number, String],
                default: null,
            },

            certificationTypeId: {
                type: [Number, String],
                default: null,
            },

            groupTypes: {
                type: Boolean,
                default: false,
            },

            admin: {
                type: Boolean,
                default: false,
            },
        },

        computed: {
            ...mapGetters('certifications', [
                'certifications',
                'types',
            ]),

            ...mapGetters('search_filters', [
                'getFilter',
            ]),

            type: {
                /** @returns {?CertificationType[]} */
                get() {
                    return this.getFilterValue('certification_type_id') || this.getFilterValue('type') || [];
                },

                set(type) {
                    let filter_value = type.map(({certification_type_id, name}) => ({certification_type_id, name}));
                    this.setFilterValue('certification_type_id', filter_value, this.getTypeLabel(type), 'Certification Type');
                },
            },

            user: {
                get() {
                    /** @returns {?User} */
                    return this.getFilterValue('user_id') || this.getFilterValue('user');
                },

                set(user) {
                    let filter_label = user?.display_name || '';
                    let filter_value = user ? { user_id: user.user_id, display_name: filter_label } : null;
                    this.setFilterValue('user_id', filter_value, filter_label, 'User');
                },
            },

            user_status: {
                get() {
                    if ( this.getFilterValue('user_status') ) {
                        return this.getFilterValue('user_status');
                    }

                    return 'active';
                },

                set(status) {
                    this.setFilterValue('user_status', status);
                },
            },

            expiration_date: {
                get() {
                    let entries = Object.entries({
                            to: this.getFilterValue('expiration_date_to'),
                            from: this.getFilterValue('expiration_date_from'),
                            upcoming: this.getFilterValue('expiration_date_upcoming'),
                        })
                        .filter(([,v]) => ![undefined, null, ''].includes(v));

                    return {
                        value: entries.length > 0 ? Object.fromEntries(entries) : null,
                    };
                },

                set(expiration_date) {
                    this.setFilterValue('expiration_date_to', expiration_date?.value?.to);
                    this.setFilterValue('expiration_date_from', expiration_date?.value?.from);
                    this.setFilterValue('expiration_date_upcoming', expiration_date?.value?.upcoming);
                },
            },

            state: {
                get() {
                    return this.getFilterValue('state');
                },

                set(state) {
                    this.setFilterValue('state', state, state?.state_name);
                },
            },

            manager: {
                get() {
                    return this.getFilterValue('manager_id') || this.getFilterValue('manager');
                },

                set(manager) {
                    let filter_label = manager?.display_name || '';
                    let filter_value = manager ? { manager_id: manager.user_id, display_name: filter_label } : null;
                    this.setFilterValue('manager_id', filter_value, filter_label, 'Manager');
                },
            },

            expiringText() {
                return Object.entries(this.expiration_date?.value || {})
                    .map(([key, value]) => {
                        return {
                            to: `To: ${value}`,
                            from: `From: ${value}`,
                            upcoming: formatDateUpcoming(value),
                        }[key];
                    })
                    .sort(([akey], [bkey]) => {
                        if ( akey > bkey ) {
                            return 1;
                        }
                        if ( akey < bkey ) {
                            return -1;
                        }
                        return 0;
                    })
                    .join(' ');
            },

            loadingPage() {
                return this.loading.certifications || this.loading.user || this.loading.type || this.loading_states;
            },

            hasCertifications() {
                return this.certifications.length > 0
            },

            certificationCountLabel() {
                return pluralize(this.paginator.total_count, 'certification');
            },

            typeIds() {
                if ( !this.type ) {
                    return null;
                }
                return this.type?.map(type => type.certification_type_id).sort().join(',') || this.certificationTypeId;
            },

            typeFilterLabel() {
                return this.typeFilterSelected
                    ? 'Type: ' + this.getTypeLabel(this.type)
                    : 'Certification Type';
            },

            typeFilterSelected() {
                return this.type?.length > 0;
            },

            userFilterSelected() {
                return !!this.user?.display_name;
            },

            expirationDateFilterSelected() {
                return !!this.expiration_date.value
            },

            stateFilterSelected() {
                return !!this.state;
            },

            managerFilterSelected() {
                return !!this.manager;
            },

            selectManagerLabel() {
                if ( this.manager ) {
                    return this.manager.display_name;
                }

                return 'Manager';
            },

            groupedCertifications() {
                return _groupBy(this.certifications, 'type.name');
            },

            firstColumnText() {
                if ( !this.certificationTypeId && !this.groupTypes ) {
                    return 'Type';
                }

                if ( !this.userId ) {
                    return 'User';
                }
            },

            hasFilters() {
                return !!this.term || !!this.type || !!this.user || !!this.expiration_date?.value || !!this.state;
            },

            exportFilters() {
                return {
                    term: this.term,
                    user_id: this.user?.user_id || this.userId,
                    certification_type_id: this.typeIds,
                    expiration_date: this.expiration_date?.value,
                    state: this.state?.state_id,
                    manager_id: this.manager?.manager_id,
                    user_status: this.user_status,
                };
            },

            filterParams() {
                return {
                    page: this.page,
                    ...this.exportFilters,
                };
            },

            userStatusName() {
                if ( this.user_status !== 'active' ) {
                    return snakeToTitle(this.user_status);
                }

                return 'User Status';
            },
        },

        data() {
            return {
                term: null,
                paginator: {},
                deleting: false,
                deleting_certification: /** @type {?Certification} */ null,
                show_delete_certification_modal: false,
                export_templates: [],
                loading: {
                    export_templates: false,
                    certifications: false,
                    user: false,
                    manager: false,
                    type: false,
                },
                user_statuses: [
                    {status: 'Active', value: 'active'},
                    {status: 'Deactivated', value: 'deactivated'},
                    {status: 'Any Status', value: 'all'},
                ],
            };
        },

        mounted() {
            this.replaceLegacyQueryParams();

            this.setNamespace('certifications');

            this.term = this.getQueryParam('term');

            if ( this.admin ) {
                let state_id = this.getQueryParam('state');
                if ( state_id ) {
                    this.state = { state_id, state_name: '' };
                }

                this.loadCertificationType(this.getQueryParam('certification_type_id') || this.certificationTypeId);
                this.loadUser(this.getQueryParam('user_id') || this.userId);
                this.loadManager(this.getQueryParam('manager_id'));
                this.loadStates()
                    .then(() => {
                        this.state = this.loaded_states.find(state => state.state_id == state_id);
                    });
                this.expiration_date = queryParamToFilter(this.getQueryParam('expiration_date'));
            }

            this.loadCertifications();

            this.$nextTick(() => {
                this.loadCertificationTypes();
            });

            this.fetchTemplates();
        },

        beforeDestroy() {
            this.clearFilterNamespace('certifications');
        },

        methods: {
            ...mapActions('certifications', [
                'setCertifications',
            ]),

            ...mapActions('search_filters', [
                'setNamespace',
                'clearFilterNamespace',
                'setFilter',
                'clearFilter',
            ]),

            replaceLegacyQueryParams() {
                // expiring_before=<value> => expiration_date=to:<value>
                let expiring_before = this.getQueryParam('expiring_before');
                if ( expiring_before ) {
                    this.removeQueryParam('expiring_before');
                    this.addQueryParam('expiration_date', `to:${expiring_before}`)
                }

                let type = this.getQueryParam('type');
                if ( type ) {
                    this.removeQueryParam('type');
                    this.addQueryParam('certification_type_id', type)
                }

                let user = this.getQueryParam('user');
                if ( user ) {
                    this.removeQueryParam('user');
                    this.addQueryParam('user_id', user)
                }

                let manager = this.getQueryParam('manager');
                if ( manager ) {
                    this.removeQueryParam('manager');
                    this.addQueryParam('manager_id', manager)
                }
            },

            getFilterValue(filter_key) {
                return this.getFilter('certifications', filter_key)?.filter_value || null;
            },

            /**
             *
             * @param {string} filter_key
             * @param {object|string} filter_value
             * @param {string} filter_label
             * @param {string} filter_name
             */
            setFilterValue(filter_key, filter_value, filter_label = filter_value, filter_name = filter_key) {
                if ( [undefined, null, ''].includes(filter_value) || filter_value.length === 0 ) {
                    this.clearFilter({ namespace: 'certifications', which: filter_key });

                } else {
                    filter_label = snakeToTitle(String(filter_label));
                    filter_name = snakeToTitle(filter_name);

                    this.setFilter({
                        namespace: 'certifications',
                        which: filter_key,
                        value: { filter_key, filter_value, filter_label, filter_name },
                    });
                }
            },

            getTypeLabel(types) {
                return types?.map(type => type.name).sort().join(' OR ');
            },

            loadCertifications() {
                this.loading.certifications = true;

                let params = {
                    with: 'files',
                    ...this.filterParams,
                };

                return Certifications.list(params)
                    .then(({data, paginator}) => {
                        this.setCertifications(data);
                        this.paginator = paginator;
                    })
                    .catch(error => {
                        this.$toast({
                            type: 'error',
                            message: error.message,
                        });
                    })
                    .finally(() => {
                        this.loading.certifications = false;
                        this.$emit('loaded');
                    });
            },

            loadCertificationType(certification_type_id = null) {
                if ( !certification_type_id ) {
                    return;
                }

                this.loading.type = true;
                return this.loadTypeOrTypes(certification_type_id)
                    .then(({data}) => {
                        this.type = arrayWrap(data);
                    })
                    .catch(error => {
                        this.$toast({
                            type: 'error',
                            message: error.message,
                        });
                    })
                    .finally(() => {
                        this.loading.type = false;
                    });
            },

            loadTypeOrTypes(certification_type_id) {
                this.type = String(certification_type_id)
                    .split(',')
                    .map(certification_type_id => ({ certification_type_id }));

                return this.type.length > 1
                    ? CertificationTypes.list({ type: certification_type_id })
                    : CertificationTypes.get(certification_type_id);
            },

            loadUser(user_id = null) {
                if ( !user_id ) {
                    return;
                }

                this.user = { user_id };
                this.loading.user = true;

                return Users.get(user_id)
                    .then(({data}) => {
                        this.user = data;
                    })
                    .catch(error => {
                        this.$toast({
                            type: 'error',
                            message: error.message,
                        });
                    })
                    .finally(() => {
                        this.loading.user = false;
                    });
            },

            loadManager(manager_id) {
                if ( !manager_id ) {
                    return;
                }

                this.manager = { manager_id };
                this.loading.manager = true;

                return Users.get(manager_id)
                    .then(({data}) => {
                        this.manager = data;
                    })
                    .catch(error => {
                        this.$toast({
                            type: 'error',
                            message: error.message,
                        });
                    })
                    .finally(() => {
                        this.loading.manager = false;
                    });
            },

            searchManagers({term, page, callback}) {
                callback(
                    Users.endpointUri({
                        has_team: true,
                        search_name: term,
                        page
                    })
                );
            },

            beginDeleteCertification(certification) {
                this.deleting_certification = certification;
                this.show_delete_certification_modal = true;

                this.$nextTick(() => {
                    this.$refs.deleteCertificationModal.open();
                });
            },

            closeDeleteCertificationModal(close_modal = true) {
                if ( close_modal ) {
                    this.$refs.deleteCertificationModal.close();
                }

                this.show_delete_certification_modal = false;
                this.deleting_certification = null;
            },

            deleteCertification() {
                this.deleting = true;

                Certifications.delete(this.deleting_certification.certification_id)
                    .then(() => {
                        this.$toast({
                            type: 'success',
                            message: 'Deleted certification',
                        });

                        this.setCertifications(this.certifications.filter(({certification_id}) => certification_id !== this.deleting_certification.certification_id));
                    })
                    .catch(() => {
                        this.$toast({
                            type: 'error',
                            message: 'Unable to delete certification',
                        });
                    })
                    .finally(() => {
                        this.deleting = false;
                        this.closeDeleteCertificationModal();
                    });
            },

            changePage(page) {
                if ( page === 1 ) {
                    this.removeQueryParam('page');
                } else {
                    this.addQueryParam('page', page);
                }
            },

            updateTerm(term) {
                this.term = term;

                this.updateQueryParam('term', term)
            },

            searchCertificationTypes({term, callback, page}) {
                callback(CertificationTypes.endpointUri({ term, page }));
            },

            searchUsers({term, callback, page}) {
                callback(Users.endpointUri({ search_name: term, page, user_status: 'all' }));
            },

            clickedFilter(clicked) {
                [
                    'certification_type_filter',
                    'expiration_date_filter',
                    'user_filter',
                    'state_filter',
                    'manager_filter',
                    'user_status_filter',
                ].forEach(filter => {
                    if ( filter !== clicked ) {
                        this.$refs[filter].closeTooltip();
                    }
                });

                if ( clicked !== 'saved_searches' ) {
                    this.$refs.saved_search_filters.menu_open = false;
                }
            },

            applySavedSearch(value) {
                value = _cloneDeep(value);

                // replace legacy filters
                if ( 'expiring_before' in value ) {
                    value.expiration_date_to = {
                        filter_key: 'expiration_date_to',
                        filter_label: value.expiring_before.filter_label,
                        filter_name: 'Expiration Date To',
                        filter_value: value.expiring_before.filter_value,
                    }
                    delete value.expiring_before;
                }

                this.setFilter({
                    namespace: 'certifications',
                    which: value.filter_key,
                    value,
                });
            },

            fetchTemplates() {
                this.loading.export_templates = false;

                Exports.get('templates', {
                        'export_code': 'certification',
                    })
                    .then(({data}) => {
                        this.export_templates = data;
                    })
                    .catch(() => {
                        this.$toast({
                            message: 'Error fetching export templates.',
                            type: 'error',
                        });
                    })
                    .finally(() => {
                        this.loading.export_templates = false;
                    });
            },

            exportCertifications() {
                this.$refs.export_modal.open();
            },

            updatedFilters: _debounce(function () {
                this.loadCertifications();
            }, 300),
        },

        watch: {
            filterParams() {
                this.updatedFilters();
            },

            userId(user_id) {
                this.loadUser(user_id);
            },

            certificationTypeId(certification_type_id) {
                this.loadCertificationType(certification_type_id)
            },

            expiration_date(expiration_date) {
                if ( this.admin ) {
                    this.updateQueryParam('expiration_date', filterToQueryParam(expiration_date));
                }
            },

            typeIds(ids) {
                if ( this.admin ) {
                    this.updateQueryParam('certification_type_id', ids);
                }
            },

            user(user) {
                if ( this.admin ) {
                    this.updateQueryParam('user_id', user?.user_id);
                }
            },

            state(state) {
                if ( this.admin ) {
                    this.updateQueryParam('state', state?.state_id);
                }
            },

            manager(manager) {
                if ( this.admin ) {
                    this.updateQueryParam('manager_id', manager?.manager_id);
                }
            }
        },

        components: {
            AdvancedDateFilter,
            StateDropdown,
            CertificationsListRow,
            DropDownFilter,
            EditCertificationModal,
            SavedSearchFilters,
            SimpleExportModal,
        },
    }
</script>
