<template>
    <div class="row">
        <page-side-nav :links="links" class="col tab-full med-2"></page-side-nav>

        <div class="col tab-full med-10">
            <router-view></router-view>
        </div>
    </div>
</template>

<script>
    // External Dependencies

    // Internal Dependencies
    import PageSideNav from '@Vault/Navigation/PageSideNav.vue';

    export default {
        name: 'Billing',

        data() {
            return {
                links: [
                    { title: 'Price Profiles', route: 'admin-price-profiles' },
                    { title: 'List Pricing', route: 'admin-list-pricing' },
                    { title: 'Payment Terms', route: 'admin-billing-terms' },
                    { title: 'Tax Categories', route: 'admin-tax-categories' },
                    { title: 'Chart of Accounts', route: 'admin-chart-accounts' },
                ],
            };
        },

        components: {
            PageSideNav,
        },
    }
</script>
