<template>
    <div>
        <ul class="basic-list striped">
            <li class="list-heading">
                <div class="row row-collapse">
                    <div class="sm-3">Quantity</div>
                    <div :class="{'sm-6': !readOnly, 'sm-9': readOnly}">Item</div>
                    <div class="sm-3 t-right" v-if="!readOnly">Actions</div>
                </div>
            </li>
            <li class="collapse" v-for="inv_item in inventory">
                <div class="row row-collapse">
                    <div class="sm-3 pl-4" style="line-height: 2.5rem;">
                        {{inv_item.quantity}}
                    </div>
                    <div :class="{'sm-6': !readOnly, 'sm-9': readOnly}">
                        <router-link v-if="showTransactionsLink" :to="itemTransactionRoute(inv_item.item)">
                            <product-dropdown-slot :item="inv_item.item" :full-description="true" :image-icon="true"></product-dropdown-slot>
                        </router-link>

                        <product-dropdown-slot v-else :item="inv_item.item" :full-description="true" :image-icon="true"></product-dropdown-slot>
                    </div>
                    <div class="sm-3 t-right" v-if="!readOnly">
                        <ks-action-menu>
                            <a href="#" @click.prevent="$emit('receive-inventory', inv_item.item)">Receive</a>
                            <a href="#" @click.prevent="$emit('use-inventory', inv_item.item)">Use</a>
                            <a href="#" @click.prevent="$emit('transfer-inventory', inv_item.item)">Transfer</a>
                            <a href="#" @click.prevent="$emit('adjust-inventory', inv_item.item)">Adjust</a>
                            <router-link class="separator" :to="itemTransactionRoute(inv_item.item)">
                                Transactions
                            </router-link>
                        </ks-action-menu>
                    </div>
                </div>
            </li>
        </ul>
    </div>
</template>

<script>
    import ProductDropdownSlot from '@Vault/Admin/WorkOrder/components/ServiceItems/ProductDropdownSlot.vue';

    export default {
        name: 'InventoryShowList',

        props: {
            inventory: {
                type: Array,
                required: true,
            },
            referenceType: {
                type: String,
                required: true,
            },
            referenceId: {
                type: [Number, String],
                required: true,
            },
            readOnly: {
                type: Boolean,
                default: false
            },
            showTransactionsLink: {
                type: Boolean,
                default: true,
            },
        },

        methods: {
            itemTransactionRoute(item) {
                return {
                    name: 'inventory-transactions',
                    params: {
                        reference_type: this.referenceType,
                        reference_id: this.referenceId,
                        item_id: item.service_item_id
                    }
                };
            },
        },

        components: {
            ProductDropdownSlot,
        }
    };
</script>
