<template>
    <div :class="[{ 'alert': alert }, error ? 'error' : 'warning']" :title="title">
        <span class="bold" :class="{ 'text-red-400': error && !alert }">Blocked:</span>
        {{ dayNames }}
    </div>
</template>


<script>

    import {defaultLocale} from 'kickstart-ui/lib/dates.js';

    export default {
        name: 'ScheduleWindowShow',

        props: {
            window: {
                type: Object,
                required: true,
            },

            alert: {
                type: Boolean,
                default: false,
            },

            error: {
                type: Boolean,
                default: false,
            },
        },

        computed: {
            title() {
                if ( !this.alert ) {
                    return '';
                }

                return this.error
                    ? 'Visit is scheduled on a day with scheduling restrictions'
                    : 'Visit is not scheduled on a day with scheduling restrictions'
            },

            dayNames() {
                return this.window.days.sort()
                    .map((day) => {
                        return defaultLocale.days.abbreviations[day]
                    })
                    .join(', ');
            }
        },
    }
</script>
