import { Mention as TipTapMention } from 'tiptap-extensions';
import { replaceText } from 'tiptap-commands';
import { Fragment } from 'prosemirror-model';
import SuggestionsPlugin from './Suggestions.js';

export const REGEX_MENTION = new RegExp(/\{\{(\w+):(\d+)(?:\|([^\s@}]+))?\}\}/);
export const REGEX_MENTION_ALL = new RegExp(REGEX_MENTION, 'g');

export const matcher = {
    char: '@',
    allowSpaces: true,
    startOfLine: false,
    allowMatchWithin: true,
    /** simplified version of REGEX_MENTION without capture groups */
    acceptedMatchRegexWithoutChar: "\\w+:\\d+\\|[^\\s@}]+",
}

/**
 * This extension is for editing WorkFossa Mentions encoded into a block of
 * text, e.g. @user:1|John_Smith. For general purpose mentions in TipTap,
 * use the base Mention class from 'tiptap-extensions'.
 */
export default class Mention extends TipTapMention {
    get defaultOptions() {
        return {
            matcher,
            mentionClass: 'mention',
            suggestionClass: 'mention-suggestion'
        };
    }

    get schema() {
        return {
            attrs: {
                type: {},
                id: {},
                label: {},
            },
            group: 'inline',
            inline: true,
            content: 'text*',
            selectable: false,
            atom: true,
            toDOM: node => [
                'span',
                {
                    class: this.options.mentionClass,
                    'data-mention-type': node.attrs.type,
                    'data-mention-id': node.attrs.id,
                    'data-mention-label': node.attrs.label,
                },
                node.attrs.label,
            ],
            parseDOM: [
                {
                    tag: 'span.mention',
                    getAttrs: this.domToAttrs,
                    getContent: (dom, schema) => this.createFragment(schema, this.domToAttrs(dom)),
                },
            ],
        }
    }

    createFragment(schema, attrs) {
        return Fragment.fromJSON(schema, [{
            type: 'text',
            text: this.attrsToText(attrs),
        }])
    }

    domToAttrs(dom) {
        return {
            type: dom.getAttribute('data-mention-type'),
            id: dom.getAttribute('data-mention-id'),
            label: dom.getAttribute('data-mention-label'),
        };
    }

    attrsToText({type, id, label}) {
        return `{{${this.options.matcher.char}${type}:${id}|${label.replaceAll(' ', '_')}}}`;
    }

    insertMention(range, attrs, schema) {
        return replaceText(range, schema.nodes[this.name], attrs, this.createFragment(schema, attrs));
    }

    get plugins() {
        return [
            SuggestionsPlugin({
                ...this.options,
                command: ({range, attrs, schema}) => this.insertMention(range, attrs, schema),
            }),
        ];
    }
}
