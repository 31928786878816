<template>
    <div class="flex column align-start signature-pad-field">
        <canvas
            ref="signPad"
            :width="width"
            :height="height"
            :class="{'signature-show': !allowCapture}"
        ></canvas>

        <div v-if="!capturing" class="signature-pad-buttons">
            <button class="button tiny outline" @click.prevent="capturing = true">Capture</button>
        </div>
        <div v-if="capturing" class="signature-pad-buttons">
            <button class="button tiny outline" @click.prevent="saveSignature" v-if="capturing">Done</button>
            <button class="button tiny danger outline" @click.prevent="clear" v-if="capturing">Clear</button>
        </div>
    </div>
</template>

<script>
    // External Dependencies
    import SignaturePad from 'signature_pad';

    // Internal Dependencies

    export default {
        name: 'SignaturePad',

        props: {
            value: {},

            width: {
                type: String,
                default: '350px',
            },

            height: {
                type: String,
                default: '175px',
            },

            readOnly: {
                default: false,
            },
        },

        computed: {
            allowCapture() {
                if ( this.readOnly ) {
                    return false;
                }

                return this.capturing;
            },
        },

        data() {
            return {
                signature_pad: null,
                capturing: false,
            }
        },

        mounted() {
            this.initSignPad();
        },

        methods: {
            initSignPad() {
                this.$nextTick(() => {
                    this.$set(this, 'signature_pad', new SignaturePad(this.$refs.signPad));

                    if ( this.value ) {
                        this.setSignatureValue();
                    }

                    if ( !this.allowCapture ) {
                        this.disableSignPad();
                    }
                });
            },

            setSignatureValue() {
                this.signature_pad.fromDataURL(this.value, {ratio: 1});
            },

            enableSignPad() {
                if ( this.signature_pad !== null ) {
                    return this.signature_pad.on();
                }
            },

            disableSignPad() {
                this.signature_pad.off();
                this.capturing = false;
            },

            saveSignature() {
                this.$emit('input', this.signature_pad.toDataURL());

                this.$nextTick(() => {
                    this.disableSignPad();
                });
            },

            clear() {
                if ( this.signature_pad != null ) {
                    this.signature_pad.clear();
                    this.$emit('input', '');
                }
            },
        },

        watch: {
            value() {
                if ( this.value && this.signature_pad ) {
                    this.setSignatureValue();
                }
            },

            allowCapture() {
                if ( this.allowCapture ) {
                    this.enableSignPad();
                } else if ( this.signature_pad != null ) {
                    this.disableSignPad();
                }
            },
        }
    }
</script>