import _forEach from 'lodash/forEach';

/**
 * Converts php style array keys into json objects and appends to the given object
 * ex: [], '[][x]', 1 => [{x: 1}]
 *
 * @param obj
 * @param keys
 * @param value
 * @returns {*[]}
 */
export function convertStringKeysToObjectValue(obj, keys, value) {
    let key = keys.splice(0, 1)[0];
    let index = key.replace(/(\[|\])/gm, '');

    if ( keys.length ) {
        if ( index ) {
            obj[index] = convertStringKeysToObjectValue(obj[index] || {}, keys, value);
        } else {
            obj = [...(obj || []), convertStringKeysToObjectValue(keys[0].length === 2 ? [] : {}, keys, value)]
        }
    } else {
        // if the index is empty then it must equal ('[]') and therefore is assumed to be an array
        if ( index === '' ) {
            if ( Array.isArray(obj) ) {
                obj.push(value);
            } else {
                obj = [value];
            }
        } else {
            if ( index in obj && Array.isArray(obj[index]) ) {
                obj[index].push(value);
            } else {
                obj[index] = value;
            }
        }
    }

    return obj;
}

/**
 * Converts objects with php style keys and converts them to full JSON objects
 * ex: {'fields[1]': 1} => {fields: {1: 1})}
 *
 * @param obj
 * @returns {{}}
 */
export function convertObjectToQueryObject(obj) {
    let query = {};

    for ( let key in obj ) {
        let value = obj[key];

        // Checks if the key contains a nested property using the php notation ('field[]')
        if ( /\[.*\]/.test(key) ) {
            let base_key = key.replace(/\[.*\]/g, '');
            let keys = [...key.matchAll(/\[.*?\]/g)].map(v => v[0]);

            query[base_key] = convertStringKeysToObjectValue(query[base_key] || (keys[0].length === 2 ? [] : {}), keys, value);
        } else {
            query[key] = obj[key];
        }
    }

    return query;
}

/**
 * Perform a deep search of an object for a given key.
 * Returns a flat array of objects within the object containing that key.
 *
 * Adapted from
 * @see https://stackoverflow.com/a/15643385/4468423
 *
 * @param {object} obj
 * @param {string} key
 * @returns {[]}
 */
export function findDeep(obj, key) {
    if ( !!obj && key in obj ) {
        return [obj];
    }

    let results = [];

    _forEach(obj, (value) => {
        if ( typeof value === 'object' && (value = findDeep(value, key)).length > 0 ) {
            results.push.apply(results, value);
        }
    });

    return results;
}

/**
 *
 * @param obj
 * @param filter
 * @returns {{[p: string]: *}}
 */
export function filterObject(obj, filter = null) {
    filter = filter ? filter : ([, val]) => {
        return !!val;
    };

    return Object.fromEntries(Object.entries(obj).filter(filter))
}

/**
 *
 * @param request_obj
 * @param parent_key
 * @param filter
 * @returns {{[p: string]: any}}
 */
export const flattenRequestParameters = (request_obj, parent_key, filter = null) => {
    return Object.fromEntries(
        Object.entries(request_obj).map(([key, value]) => {
            if ( filter && !filter(key, value) ) {
                return null;
            }
            return [`${parent_key}[${key}]`, value]
        }).filter(v => v)
    );
};

/**
 * @typedef Paginator
 * @type {{next_page: null, total_count: number, limit: number, total_pages: number, previous_page: null, current_page: number}}
 */
export const blank_paginator = {
    total_count: 0,
    total_pages: 0,
    current_page: 1,
    limit: 50,
    next_page: null,
    previous_page: null
};