<template>
    <div class="row admin-nav">
        <router-link :to="{name: 'admin-data-integrations-quick-books'}" tag="div" class="card col sm-6 med-4">
            <a>
                <img src="/images/thirdparty/quickbooks/quickbooks_logo_horz.png" class="img-responsive" />
                <span class="description">
                    Integrate with QuickBooks
                </span>
            </a>
        </router-link>
    </div>
</template>

<script>
    import BreadCrumbs from 'BreadCrumbs';

    export default {
        name: 'DataIntegrationList',

        mixins: [BreadCrumbs],

        beforeMount() {
            this.pushBreadcrumb({
                title: 'Integrations List',
                to: {
                    name: 'admin-data-integrations-list'
                }
            });
        },
    }
</script>
