<template>
    <div>
        <transition name="fade">
            <div
                v-show="is_expanded"
                ref="expanded"
                class="ks-tooltip text-sm shadow-lg p-3"
                :style="expandedStyle"
            >
                <span style="white-space: pre-line">{{ text }}</span>
                <a href="#" class="block t-right text-xs" @click.prevent="is_expanded=false">Close</a>
            </div>
        </transition>
        <div @click.prevent="expand">
            <span v-if="displayText" class="text-sm" :style="style">{{ displayText }}</span>
            <span v-else class="super-muted text-sm">No description</span>
            <a v-show="(text || '').length > maxLength" href="#" class="ml-1 text-xs" @click.prevent="expand">more...</a>
        </div>
    </div>
</template>

<script>
    import Popper from 'popper.js';

    export default {
        name: 'TextExcerpt',

        props: {

            text: {
                default: ''
            },

            maxLength: {
                default: 255,
            }
        },

        computed: {

            displayText() {
                if ( !this.text ) {
                    return '';
                }

                if ( this.text.length <= Number(this.maxLength) ) {
                    return this.text;
                }

                return this.text.slice(0, Number(this.maxLength) - 1).split(' ').slice(0, -2).join(' ');
            },

            style() {
                return {
                    overflow: 'hidden',
                    "text-overflow": 'ellipsis',
                    visibility: this.is_expanded ? 'hidden' : null
                };
            },

            expandedStyle() {
                return {
                    width: this.width + 'px',
                    "z-index": 9,
                };
            },
        },

        data() {
            return {
                is_expanded: false,
                width: null
            }
        },

        beforeDestroy() {
            if ( typeof this.clickoutListener == 'function'  ) {
                document.getElementsByTagName('body')[0].removeEventListener('click', this.clickoutListener);
                document.getElementsByTagName('body')[0].removeEventListener('touchend', this.clickoutListener);
            }
        },

        methods: {
            expand() {
                if ( !this.popover ) {
                    this.createPopover();
                }
                this.is_expanded = true;
            },
            setPopoverWidth() {
                this.width = this?.$el?.getBoundingClientRect()?.width;
            },
            createPopover() {
                this.popover = new Popper(this.$el, this.$refs.expanded, {
                    placement: 'top-start',
                    removeOnDestroy: true,
                    modifiers: {
                        flip: { enabled: false },
                        inner: { enabled: true },
                        hide: { enabled: false },
                        setPopoverWidth: {
                            enabled: true,
                            order: 1000,
                            fn: (data) => {
                                this.setPopoverWidth();
                                return data;
                            }
                        }
                    }
                });

                // setup listeners to hide the popover when clicking outside
                this.clickoutListener = (e) => {
                    if ( this.is_expanded && !this.$el.contains(e.target) ) {
                        this.is_expanded = false;
                    }
                }

                let body = document.getElementsByTagName('body')[0];
                body.addEventListener('click', this.clickoutListener);
                body.addEventListener('touchend', this.clickoutListener);
            }
        }
    }
</script>
