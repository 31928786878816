<template>
    <div class="row row-collapse full-width flex align-center">
        <div class="sm-5 med-3 lg-4 pl-1">
            <a v-if="$userCan('certifications_manage')" href="#" @click="$emit('edit', certification)">{{ linkText }}</a>
            <span v-else>{{ linkText }}</span>
        </div>
        <div class="tab-hide-down med-3">{{ certification.certificate_number }}</div>
        <div class="sm-3 med-2">{{ certification.issue_date | date }}</div>
        <div class="sm-3 med-2">{{ certification.expiration_date | date }}</div>
        <div class="sm-1 text-right">
            <div class="actions">
                <slot name="actions">
                    <a v-if="certification.file" :href="certification.file.url" target="_blank" title="View Certificate">
                        <vault-icon :icon="icons.view"></vault-icon>
                    </a>
                    <a v-if="allowDelete" href="#" class="danger delete" title="Delete" @click="$emit('delete', certification)">
                        <vault-icon :icon="icons.delete"></vault-icon>
                    </a>
                </slot>
            </div>
        </div>
    </div>
</template>

<script>
    import { faFileCertificate } from '@fortawesome/pro-regular-svg-icons/faFileCertificate';
    import { faTrash } from '@fortawesome/pro-regular-svg-icons/faTrash';

    export default {
        name: 'CertificationsListRow',

        props: {
            /** @type {Certification} */
            certification: {
                type: Object,
                required: true,
            },

            linkText: {
                type: String,
                required: true,
            }
        },

        computed: {
            allowDelete() {
                return this.$route.name === 'admin-certifications' && this.$userCan('certifications_delete');
            },
        },

        data() {
            return {
                icons: {
                    view: faFileCertificate,
                    delete: faTrash,
                },
            };
        },
    }
</script>
