const AdminPriceProfiles = () => import('./components/AdminPriceProfiles.vue');
const AdminPriceProfilesList = () => import('./components/AdminPriceProfilesList.vue');
const AdminInvoices = () => import('./components/AdminInvoices.vue');
const PriceProfileShow = () => import('./components/PriceProfileShow.vue');
const AdminBillingTerms = () => import('./components/BillingTerms/AdminBillingTerms.vue');
const AdminBillingTermsList = () => import('./components/BillingTerms/AdminBillingTermsList.vue');
const AdminTaxes = () => import('./components/Taxes/AdminTaxes.vue');
const AdminTaxCategories = () => import('./components/Taxes/AdminTaxCategories.vue');
const AdminListPricing = () => import('@Vault/Admin/Billing/components/AdminListPricing.vue');
const AdminChartOfAccounts = () => import('@Vault/Admin/Billing/components/Accounting/AdminAccounting.vue');

export default [
    {
        path: 'price-profiles',
        component: AdminPriceProfiles,
        meta: {
            perms: ['admin_pricing'],
        },
        children: [
            {
                path: '',
                name: 'admin-price-profiles',
                component: AdminPriceProfilesList,
                props: true,
            },
            {
                path: ':price_profile_id',
                component: PriceProfileShow,
                name: 'admin-price-profiles-show',
                props: true,
            },
        ]
    },
    {
        path: 'taxes',
        component: AdminTaxes,
        meta: {
            perms: ['admin_pricing'],
        },
        children: [
            {
                path: '',
                name: 'admin-tax-categories',
                component: AdminTaxCategories,
                props: true,
            }
        ]
    },
    {
        path: 'list-pricing',
        name: 'admin-list-pricing',
        component: AdminListPricing,
        meta: {
            perms: ['admin_list_pricing'],
        },
        props: true,
    },
    {
        path: 'invoices',
        component: AdminInvoices,
        name: 'admin-invoices',
        props: true,
        meta: {
            perms: ['admin_invoices'],
        },
    },
    {
        path: 'terms',
        component: AdminBillingTerms,
        meta: {
            perms: ['admin_terms'],
        },
        children: [
            {
                path: '',
                component: AdminBillingTermsList,
                name: 'admin-billing-terms',
                props: true,
            }
        ],
    },
    {
        path: 'chart-accounts',
        component: AdminChartOfAccounts,
        name: 'admin-chart-accounts',
        meta: {
            perms: ['admin_chart_accounts'],
        },
    }
];
