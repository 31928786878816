import store from '../store';
import customer_store from '../Customer/store';
import { arrayWrap } from './array.js';

/**
 * Checks for permissions in the store
 *
 * @param permissions
 * @return {boolean}
 */
export function userCan(...permissions) {
    let user_permissions = (window.customer ? customer_store : store).getters.permissions;

    // If a single argument is passed and it's an array that we can assume that is out permissions array
    if ( arguments.length == 1 && Array.isArray(arguments[0]) ) {
        permissions = arguments[0];
    }

    // Loop through the permissions provided and check if they exist in the users permissions
    // Return true on the first match
    for ( var i in permissions ) {
        if ( permissions[i] in user_permissions ) {
            return true;
        }
    }

    // Return false if no permissions were found
    return false;
}

/**
 *
 * @param {...string} groups
 * @returns {boolean} whether any user permission starts with any of the given groups
 */
export function userCanGroup(...groups) {
    let user_permissions = (window.customer ? customer_store : store).getters.permissions;

    if ( arguments.length === 1 && Array.isArray(arguments[0]) ) {
        groups = arguments[0];
    }

    // match any of the given groups at the start of the string
    const regex = new RegExp(`^(?:${groups.join('|')}).*`);

    // Return true if any user permission starts with any of the given groups
    return Object.keys(user_permissions).some(permission => permission.match(regex));
}

/**
 *
 * @param route
 * @returns {boolean}
 */
export function routeIsAuthorized(route) {
    if ( route.name === 'not-authorized' ) {
        return true;
    }

    const user_type = (window.customer ? customer_store : store).state.user_type;

    // Iterate over each ancestor node in the route tree and check its required permissions
    // For a route to be authorized, none of the ancestors should prohibit access
    return route.matched?.every(match => {
        if ( Object.keys(match.meta || {}).length === 0 ) {
            return true;
        }

        // Prohibit if route match has meta.perm_group and the user does not have a permission prefixed by that group
        if ( match.meta.perm_group && !userCanGroup(match.meta.perm_group) ) {
            return false;
        }

        // Prohibit if route match specifies a permission not explicitly granted to the user
        if ( match.meta.perms && !userCan(match.meta.perms) ) {
            return false;
        }

        // Prohibit if route match specifies a user type (role) not belonging to the user
        if ( match.meta.user_types && !arrayWrap(match.meta.user_types).includes(user_type) ) {
            return false;
        }

        // Otherwise authorize
        return true;
    });
}
