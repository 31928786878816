
const AccuMeasureBeforeAndAfterReport = () => import('./components/reports/AccuMeasureBeforeAndAfterReport.vue');
const AccuMeasureImprovementReport = () => import('./components/reports/AccuMeasureImprovementReport.vue');
const AdminReportsList = () => import('./components/AdminReportsList.vue');
const LaborHoursReport = () => import('./components/reports/LaborHoursReport.vue');
const ComplianceScheduleReport = () => import('./components/reports/ComplianceScheduleReport.vue');

export default [
    {
        path: '',
        name: 'admin-reports',
        component: AdminReportsList,
    },
    {
        path: 'accumeasure/before-and-after',
        component: AccuMeasureBeforeAndAfterReport,
        name: 'accumeasure-before-after',
    },
    {
        path: 'accumeasure/improvement',
        component: AccuMeasureImprovementReport,
        name: 'accumeasure-improvement',
    },
    {
        path: 'compliance/schedule',
        component: ComplianceScheduleReport,
        name: 'compliance-schedule-report'
    },
    {
        path: 'labor/hours',
        component: LaborHoursReport,
        name: 'report-labor-hours',
    },
];
