<template>
    <div class="row">
        <page-side-nav :links="links" class="col tab-full med-2"></page-side-nav>

        <div class="col tab-full med-10">
            <router-view></router-view>
        </div>
    </div>
</template>

<script>
    // External Dependencies

    // Internal Dependencies
    import PageSideNav from '@Vault/Navigation/PageSideNav.vue';

    export default {
        name: 'AdminCustomersPanel',

        data() {
            return {
                links: [
                    { title: 'Customer Groups', route: 'admin-customer-groups' },
                    { title: 'Locked Contacts', route: 'admin-locked-contacts' },
                    { title: 'Notifications', route: 'admin-customer-notifications' },
                    { title: 'Notification Templates', route: 'admin-notification-templates' },
                ],
            };
        },

        components: {
            PageSideNav,
        },
    }
</script>