<template>
    <div id="certification-types-list">
        <div class="row row-collapse">
            <div class="sm-6">
                <search-bar
                    v-model="term"
                    placeholder="Search Certification Types..."
                    :delay="300"
                    @search="updateTerm($event)"
                ></search-bar>
            </div>

            <div class="sm-6 t-right">
                <vault-button type="add" @click="$refs.editCertificationTypeModal.open()">
                    Add Certification Type
                </vault-button>
            </div>
        </div>

        <ks-loader-spin v-if="loading" :show="true" size="medium"></ks-loader-spin>

        <div v-else-if="!hasFilters && types.length === 0" class="my-4 t-center">
            <a href="#" @click="$refs.editCertificationTypeModal.open()">Add a certification type</a> to get started.
        </div>

        <template v-else-if="hasCertificationTypes">
            <div class="mt-2 flex align-center jc-between">
                <div class="text-sm muted">
                    {{ typeCountLabel }}
                </div>

                <ks-paginator
                    :pagination="typesPaginator"
                    :routed="false"
                    @input="changePage"
                ></ks-paginator>
            </div>

            <ul class="mt-1 basic-list" :class="{ 'disabled': loading }">
                <li class="text-sm" style="background-color:#e4e7eb;">
                    <div class="row row-collapse full-width flex align-center bold mr-24">
                        <div class="sm-8 pl-1">Type</div>
                        <div class="sm-2 text-center ellipsis">Certified</div>
                        <div class="sm-2 text-center ellipsis">Monitors</div>
                    </div>
                </li>

                <li
                    v-for="type in types"
                    :key="type.certification_type_id"
                    class="text-sm"
                    :class="'certification-type-' + type.certification_type_id"
                >
                    <div class="row row-collapse full-width flex align-center text-sm mr-24 min-h-6">
                        <div class="sm-8 pl-1">
                            <a href="#" :title="'Edit ' + type.name" class="edit" @click="$refs.editCertificationTypeModal.open(type)">
                                {{ type.name }}
                                <span v-if="type.state">({{ type.state.state }})</span>
                            </a>
                            <router-link
                                :to="{ name: 'admin-certifications', query: { type: type.certification_type_id } }"
                                title="View certifications"
                            >
                                <vault-icon :icon="icons.file_search" class="ml-1"></vault-icon>
                            </router-link>
                        </div>

                        <div class="sm-2 text-center">
                            {{ type.certifications_count }}
                        </div>

                        <div class="sm-2 text-center">
                            {{ type.monitors_count }}
                        </div>
                    </div>

                    <div class="actions">
                        <a href="#" :title="'Edit ' + type.name" class="edit" @click="$refs.editCertificationTypeModal.open(type)">
                            <vault-icon :icon="icons.edit"></vault-icon>
                        </a>
                        <a
                            href="#"
                            class="danger delete"
                            :class="{ 'disabled': type.certifications_count > 0 }"
                            title="Delete"
                            @click.prevent="beginDeleteCertificationType(type)"
                        >
                            <vault-icon :icon="icons.delete"></vault-icon>
                        </a>
                    </div>
                </li>
            </ul>
        </template>

        <div v-else class="my-4 t-center">
            No certification types found.
        </div>

        <edit-certification-type-modal
            ref="editCertificationTypeModal"
            @saved="loadCertificationTypes"
        ></edit-certification-type-modal>

        <ks-modal
            v-if="show_delete_certification_type_modal"
            ref="deleteCertificationTypeModal"
            id="delete-certification-type-modal"
            title="Delete Certification Type"
            :danger="true"
            @close="closeDeleteCertificationTypeModal(false)"
        >
            <div>Are you sure you want to delete {{ deleting_type.name }}?</div>
            <div slot="footer">
                <vault-button type="delete" :spin="deleting" @click="deleteCertificationType">Delete</vault-button>
                <button class="button outline" @click.prevent="closeDeleteCertificationTypeModal">Cancel</button>
            </div>
        </ks-modal>
    </div>
</template>

<style>
    .pagination > ul {
        padding: 0 !important;
    }
    .min-h-6 {
        min-height: 1.5rem;
    }
</style>

<script>
    import { faEdit } from '@fortawesome/pro-solid-svg-icons/faEdit';
    import { faTrash } from '@fortawesome/pro-regular-svg-icons/faTrash';
    import { faFileCertificate } from '@fortawesome/pro-regular-svg-icons/faFileCertificate';
    import { pluralize } from 'helpers/strings';
    import PageHelper from '@Vault/PageHelper.js';
    import CertificationsMixin from '@Vault/Admin/Certifications/mixins/CertificationsMixin.js';
    import CertificationTypes from '@Vault/Certifications/repositories/CertificationTypesRepository.js';
    import EditCertificationTypeModal from '@Vault/Admin/Certifications/components/EditCertificationTypeModal.vue';

    export default {
        name: 'CertificationTypesList',

        mixins: [PageHelper, CertificationsMixin],

        computed: {
            loading() {
                return this.loading_types;
            },

            hasCertificationTypes() {
                return this.types.length > 0;
            },

            typeCountLabel() {
                return pluralize(this.types.length, 'certification type');
            },

            filters() {
                return !!this.term
                    ? { term: this.term }
                    : {};
            },

            hasFilters() {
                return Object.keys(this.filters).length > 0;
            },
        },

        data() {
            return {
                term: null,
                deleting: false,
                deleting_type: /** @type {?CertificationType} */ null,
                show_delete_certification_type_modal: false,
                icons: {
                    edit: faEdit,
                    delete: faTrash,
                    file_search: faFileCertificate,
                },
            };
        },

        mounted() {
            this.term = this.getQueryParam('term');

            if ( this.types.length === 0 ) {
                this.loadCertificationTypes(this.filters)
                    .then(() => {
                        if ( this.types.length === 0 && !this.hasFilters ) {
                            this.$refs.editCertificationTypeModal.open();
                        }
                    })
            }
        },

        methods: {
            changePage(page) {
                if ( page === 1 ) {
                    this.removeQueryParam('page');
                } else {
                    this.addQueryParam('page', page);
                }

                this.loadCertificationTypes(this.filters);
            },

            beginDeleteCertificationType(type) {
                this.deleting_type = type;
                this.show_delete_certification_type_modal = true;

                this.$nextTick(() => {
                    this.$refs.deleteCertificationTypeModal.open();
                });
            },

            closeDeleteCertificationTypeModal(close_modal = true) {
                if ( close_modal ) {
                    this.$refs.deleteCertificationTypeModal.close();
                }

                this.show_delete_certification_type_modal = false;
                this.deleting_type = null;
            },

            deleteCertificationType() {
                this.deleting = true;

                CertificationTypes.delete(this.deleting_type.certification_type_id)
                    .then(() => {
                        this.$toast({
                            type: 'success',
                            message: 'Deleted ' + this.deleting_type.name,
                        });

                        this.loadCertificationTypes(this.filters);
                    })
                    .catch(() => {
                        this.$toast({
                            type: 'error',
                            message: 'Unable to delete ' + this.deleting_type.name,
                        });
                    })
                    .finally(() => {
                        this.deleting = false;
                        this.closeDeleteCertificationTypeModal();
                    });
            },

            updateTerm(term) {
                if ( term === undefined || term === null || term == '' ) {
                    this.removeQueryParam('term');
                    this.term = null;
                } else {
                    this.addQueryParam('term', term);
                    this.term = term;
                }

                this.loadCertificationTypes(this.filters);
            },
        },

        components: {
            EditCertificationTypeModal,
        },
    }
</script>
