<script>
    /**
     * This component can draw both Pie and Doughnut charts. Use 'chart-type="pie"' prop to draw a pie chart.
     */

    // External Dependencies
    import 'chartjs-plugin-doughnutlabel';

    // Internal Dependencies
    import BaseGraph from 'UI/Graphs/BaseGraph.vue';

    export default {
        name: 'DonutGraph',

        extends: BaseGraph,

        props: {
            chartType: {
                default: 'doughnut',
            }
        },
    }
</script>
