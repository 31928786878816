import {pad_left} from 'kickstart-ui/lib/strings';

export default {
    methods: {
        getDateNow() {
            return this.localDateFormatted(new Date);
        },

        localDateFormatted(date) {
            let year = date.getFullYear();
            let month = pad_left(date.getMonth()+1, '0', 2);
            let day = pad_left(date.getDate(), '0', 2);

            return `${year}-${month}-${day}`;
        }
    }
}