<template>
    <div>
        <page-header>
            <page-breadcrumb></page-breadcrumb>
        </page-header>

        <div>
            <router-view></router-view>
        </div>
    </div>
</template>

<script>
    // External Dependencies

    // Internal Dependencies
    import BreadCrumbs from 'BreadCrumbs';

    export default {
        name: 'CustomerForms',

        mixins: [BreadCrumbs],

        beforeMount() {
            this.resetBreadcrumb({
                title: 'Forms',
                to: {
                    name: 'forms'
                }
            });
        },
    }
</script>