import {faTruck, faWarehouse, faExchange} from '@fortawesome/pro-regular-svg-icons';

export default {

    data() {
        return {
            reference_icons: {
                fleet: faTruck,
                location: faWarehouse,
                transfer: faExchange,
            }
        }
    },

    methods: {
        getEquipmentReferenceTitle(reference) {
            switch ( this.getTypeOfEquipmentReference(reference) ) {
                case 'fleet':
                    return `#${reference.fleet_number} - ${reference?.year || ''} ${reference.make?.make || ''} ${reference.model?.model || ''} ${reference.state?.state || ''}`
                case 'location':
                    return `#${reference.location_number} - ${reference.address1} ${reference.city} ${reference.state}`
                default:
                    console.error('Invalid Equipment Reference');
            }
        },

        getTypeOfEquipmentReference(reference) {
            if ( !reference ) {
                return null;
            }

            return reference.fleet_id ? 'fleet' : 'location';
        },

        equipmentReferenceRoute(reference) {
            switch ( this.getTypeOfEquipmentReference(reference) ) {
                case 'fleet':
                    return {
                        name: 'fleet-show',
                        params: {fleet_id: reference?.fleet_id}
                    };
                case 'location':
                    return {
                        name: 'reference-inventory',
                        params: {
                            reference_type: 'location',
                            reference_id: reference?.location_id
                        }
                    };
                default:
                    console.error('Invalid Equipment Reference');
            }
        }
    }
}