<template>
    <div>
        <div :class="titleClasses">
            {{ formattedLabel }}
        </div>

        <div v-if="isSignatureField" class="row row-collapse">
            <img :src="value.value" class="sm-12">
        </div>

        <div v-else :class="{ ...textSizeClass, ...valueClass }">
            {{ formattedValue }}
        </div>
    </div>
</template>

<script>
    import { displayValue } from 'helpers/custom-fields';

    export default {
        name: 'CustomFieldsViewValue',

        props: {
            value: {
                required: true,
            },

            uppercaseTitles: {
                type: Boolean,
                default: true,
            },

            textSize: {
                type: String,
                default: 'xs',
                validator: (value) => ['nano', 'tiny', 'xs', 'sm', 'small', 'normal'].includes(value),
            },

            valueClass: {
                type: Object,
                default: () => ({ 'mt-1': true }),
            },

            mutedTitles: {
                type: Boolean,
                default: true,
            },
        },

        computed: {
            isSignatureField() {
                return this.value.field?.field_type === 'signature' || false;
            },

            titleClasses() {
                return {
                    'muted': this.mutedTitles,
                    'uppercase': this.uppercaseTitles,
                    ...this.textSizeClass,
                };
            },

            textSizeClass() {
                return {
                    'text-xs': ['nano', 'tiny', 'xs'].includes(this.textSize),
                    'text-sm': ['sm', 'small'].includes(this.textSize),
                    'text-normal': this.textSize === 'normal',
                };
            },

            formattedValue() {
                return Array.isArray(this.value)
                    ? this.value.map((v) => displayValue(v.field, v.value))
                        .filter(v => v)
                        .join(', ')
                    : displayValue(this.value.field, this.value.value);
            },

            formattedLabel() {
                return Array.isArray(this.value)
                    ? this.value[0].field.label
                    : this.value.field.label;
            },
        }
    }
</script>
