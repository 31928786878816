<template>
    <div class="sm-12">
        <ks-loader-spin :show="loading" height="250px" size="large"></ks-loader-spin>

        <div class="row row-collapse search-header" v-show="!loading">
            <div class="sm-half">
                <div class="row row-collapse flex">
                    <div class="sm-10">
                        <search-bar placeholder="Search Schedules..." v-model="term" @search="getSchedules" :delay="250">
                            <ks-loader-line :show="searching"></ks-loader-line>
                        </search-bar>
                    </div>
                </div>
            </div>
        </div>

        <div v-show="!loading">
            <ul class="basic-list striped no-border">
                <li class="list-heading">
                    <div class="tab-5">Name</div>
                    <div class="tab-3">Customer</div>
                    <div class="tab-2">Start Date</div>
                    <div class="tab-2"># of Work</div>
                </li>
                <li v-for="schedule in schedules">
                    <div class="tab-5">
                        <router-link
                            :to="{name: 'customer-work-schedule-view', params: {work_schedule_id: schedule.work_schedule_id}}"
                        >
                            {{schedule.work_schedule_name}}
                        </router-link>
                        <div class="muted text-tiny" style="padding-left:0.5rem;padding-top:0.25rem;">
                            Executed on <em>{{schedule.executed_on}}</em> by <em>{{schedule.creator.display_name}}</em>
                        </div>
                    </div>
                    <div class="tab-3">{{schedule.customer_name}}</div>
                    <div class="tab-2">{{schedule.start_date | date}}</div>
                    <div class="tab-2">{{schedule.work_count || 0}}</div>
                </li>
            </ul>

            <ks-paginator
                :pagination="paginator"
                :routed="true"
            ></ks-paginator>
        </div>
    </div>
</template>

<script>
    // External Dependencies

    // Internal Dependencies
    import PageHelper from '@Vault/PageHelper';
    import Vault from 'helpers/vault.js';

    export default {
        name: 'CustomerExecutedSchedules',

        mixins: [PageHelper],

        data() {
            return {
                schedules: [],
                paginator: {},
                searching: false,
                loading: true,

                term: null,
            }
        },

        beforeMount() {
            this.getSchedules();
        },

        methods: {
            getSchedules() {
                this.searching = true;
                Vault.get('work-schedule', {with: 'customer,workCount,creator', page: this.page, term: this.term})
                    .then(({data, paginator}) => {
                        this.$set(this, 'schedules', data);
                        this.$set(this, 'paginator', paginator);

                        this.$nextTick(() => {
                            this.$set(this, 'loading', false);
                            this.$set(this, 'searching', false);
                        });
                    });
            }
        },

        watch: {
            page() {
                this.$set(this, 'searching', true);

                this.getSchedules();
            },
        },
    }
</script>
