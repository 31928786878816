<template>
    <div class="progress-bar flex align-center"
         :class="{'completed': status == 'complete', 'failed': status == 'failed', 'pending': status == 'pending'}"
         :style="{height: height}"
    >
        <div class="progress-bar-text">
            <slot name="text"></slot>
        </div>

        <div class="progress-bar-inner" :class="{progress: status == 'progress'}" :style="{width: progress + '%'}"></div>
    </div>
</template>


<script>
    export default {
        name: 'ProgressBar',

        props: {
            // The current progress value.
            value: {
                required: true,
                type: Number
            },

            // The max progress value allowed, cannot be zero.
            max: {
                type: Number,
                default: 100,

                // We divide by this number, so it can't be zero.
                validator: function(max) {
                    return max > 0;
                }
            },

            status: {
                type: String,
                validator: function(status) {
                    return ["complete", "failed", "progress", "pending", "warning"].indexOf(status) !== -1;
                }
            },

            height: {
                type: String,
                default: '20px',
            },
        },

        computed: {
            // Return a normalized progress number, based on the max value.
            progress() {
                return Math.min(this.value, this.max);
            }
        }
    }
</script>
