<template>
    <div>
        <page-header>
            <page-breadcrumb></page-breadcrumb>
        </page-header>

        <div class="row">
            <router-view></router-view>
        </div>
    </div>
</template>

<script>
    // External Dependencies

    // Internal Dependencies
    import BreadCrumbs from 'BreadCrumbs';

    export default {
        name: 'WorkOrder',

        mixins: [BreadCrumbs],

        beforeMount() {
            this.resetBreadcrumb({
                title: 'Work',
                to: {
                    name: 'work-order-list'
                }
            });
        }
    }
</script>
