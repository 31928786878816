<template>
    <div class="row row-collapse">
        <div class="sm-12 mb-1 flex jc-between">
            <div class="label">
                <template v-if="showTitle">
                    {{ filterTitle }}
                </template>
                <template v-if="types.length > 1">
                    <a
                        v-for="(filter_type, index) in types"
                        href="#"
                        :class="{ 'bold': filter_type.value === type, 'ml-2': showTitle || index > 0 }"
                        @click.prevent="type = filter_type.value"
                    >
                        {{ filter_type.label }}
                    </a>
                </template>
            </div>
            <a v-if="value !== undefined" href="#" class="label" @click.prevent="reset">Reset</a>
        </div>

        <template v-if="type === 'range'">
            <div class="sm-6 mb-2">
                <div class="label">From</div>
                <ks-datepicker
                    v-model="dateFrom"
                    :year-picker="true"
                    :month-picker="true"
                    :max-date="dateTo"
                    @input-error="$toast({type: 'error', message: 'Invalid date provided'})"
                ></ks-datepicker>
            </div>
            <div class="sm-6">
                <div class="label">To</div>
                <ks-datepicker
                    v-model="dateTo"
                    :year-picker="true"
                    :month-picker="true"
                    :min-date="dateFrom"
                    @input-error="$toast({type: 'error', message: 'Invalid date provided'})"
                ></ks-datepicker>
            </div>
        </template>

        <template v-else-if="type === 'within'">
            <div class="sm-6">
                <div class="label">From (days ago)</div>
                <input v-model="dateWithinFrom" type="text" :class="{ 'error': withinError }">
            </div>
            <div class="sm-6">
                <div class="label">To (days ago)</div>
                <input v-model="dateWithinTo" type="text" :class="{ 'error': withinError }">
            </div>
            <div class="sm-12 text-xs mb-2" :class="withinError ? 'text-red-600' : 'muted-dark'">
                {{ withinMessage }}
            </div>
        </template>

        <template v-else-if="type === 'upcoming'">
            <div class="sm-6">
                <div class="label">From (days ahead)</div>
                <input v-model="dateUpcomingFrom" type="text" :class="{ 'error': upcomingError }">
            </div>
            <div class="sm-6">
                <div class="label">To (days ahead)</div>
                <input v-model="dateUpcomingTo" type="text" :class="{ 'error': upcomingError }">
            </div>
            <div class="sm-12 text-xs mb-2" :class="upcomingError ? 'text-red-600' : 'muted-dark'">
                {{ upcomingMessage }}
            </div>
        </template>

<!--        <div v-else-if="type === 'period'" class="sm-12 col">-->
<!--            <ks-select-->
<!--                v-model="period"-->
<!--                name="period"-->
<!--                :items="periods"-->
<!--                item-key="value"-->
<!--                label-key="label"-->
<!--                class="text-sm"-->
<!--            ></ks-select>-->
<!--        </div>-->
    </div>
</template>

<script>
    /**
     * If used within a SearchFilters slot/filter group, v-model is not required and inputs will directly update the
     * filters store.  When used with v-model, updates will be emitted instead.
     */

    import { formatDate } from 'kickstart-ui/lib/dates.js';

    import { snakeToTitle } from 'helpers/strings.js';
    import FilterMixin from '@Vault/Filters/mixins/FilterMixin.js';
    import HandlesDatesMixin from '@Vault/Support/HandlesDatesMixin.js';

    export default {
        name: 'AdvancedDateFilter',

        mixins: [
            FilterMixin,
            HandlesDatesMixin,
        ],

        props: {
            value: {
                default: undefined,
            },

            name: {
                type: String,
                default: '',
            },

            range: {
                type: Boolean,
                default: true,
            },

            within: {
                type: Boolean,
                default: true,
            },

            upcoming: {
                type: Boolean,
                default: false,
            },

            showTitle: {
                type: Boolean,
                default: true,
            },
        },

        computed: {
            types() {
                return [
                        { value: 'range', label: 'Range' },
                        { value: 'within', label: 'Within' },
                        { value: 'upcoming', label: 'Upcoming' },
                        // { value: 'period', label: 'Period' },
                    ]
                    .reduce((types, type) => {
                        if ( this[type.value] ) {
                            types.push(type);
                        }
                        return types;
                }, []);
            },

            filterTitle() {
                return snakeToTitle(this.name);
            },

            filter() {
                return this.value === undefined
                    ? this.getFilter(this.name)?.value || {}
                    : this.value?.value || {};
            },

            dateFrom: {
                get() {
                    return this.filter.from || null;
                },

                set(from) {
                    from = String(from).trim();
                    if ( from !== '' ) {
                        this.update({ from, to: this.dateTo });
                    } else if ( this.filter.to ) {
                        this.update({ from: null, to: this.dateTo });
                    } else {
                        this.update(null);
                    }
                },
            },

            dateTo: {
                get() {
                    return this.filter.to || null;
                },

                set(to) {
                    to = String(to).trim();
                    if ( to !== '' ) {
                        this.update({ from: this.dateFrom, to });
                    } else if ( this.filter.from ) {
                        this.update({ from: this.dateFrom, to: null });
                    } else {
                        this.update(null);
                    }
                },
            },

            dateWithinFrom: {
                get() {
                    if ( !this.filter.within ) {
                        return '';
                    }

                    const [from, to] = this.filter.within.split(',');
                    return from;
                },

                set(from) {
                    // strip out non-numeric input
                    from = String(from).replace(/\D/g,'');
                    const to = this.dateWithinTo;
                    this.update({ within: `${from},${to}` });
                },
            },

            dateWithinTo: {
                get() {
                    if ( !this.filter.within ) {
                        return '';
                    }

                    const [from, to] = this.filter.within.split(',');
                    return to || '';
                },

                set(to) {
                    // strip out non-numeric input
                    to = String(to).replace(/\D/g,'');
                    const from = this.dateWithinFrom;
                    this.update({ within: `${from},${to}` });
                },
            },

            withinError() {
                return !!this.dateWithinFrom && !!this.dateWithinTo && Number(this.dateWithinFrom) < Number(this.dateWithinTo);
            },

            withinMessage() {
                if ( !this.filter.within ) {
                    return '';
                }

                const today = new Date();
                const year = today.getFullYear();
                const month = today.getMonth();
                const day = today.getDate();
                const from = this.dateWithinFrom ? formatDate(this.localDateFormatted(new Date(year, month, day - Number(this.dateWithinFrom))), 'm/d/Y', 'Y-m-d') : null;
                const to = this.dateWithinTo ? formatDate(this.localDateFormatted(new Date(year, month, day - Number(this.dateWithinTo))), 'm/d/Y', 'Y-m-d') : null;

                if ( from && to ) {
                    return this.withinError
                        ? 'From should be greater than To'
                        : `Between ${from} - ${to}`;

                } else if ( from ) {
                    return `${from} or later`;

                } else if ( to ) {
                    return `${to} or earlier`;

                } else {
                    return '';
                }
            },

            dateUpcomingFrom: {
                get() {
                    if ( !this.filter.upcoming ) {
                        return '';
                    }

                    // parse legacy (single-value) upcoming values
                    const upcoming = this.filter.upcoming.split(',');
                    return upcoming.length === 1
                        ? ''
                        : upcoming[0];
                },

                set(from) {
                    from = String(from).trim();
                    if ( from === '-' ) {
                        return;
                    }
                    this.update({ upcoming: `${this.stripNonNumericInput(from)},${this.dateUpcomingTo}` });
                },
            },

            dateUpcomingTo: {
                get() {
                    if ( !this.filter.upcoming ) {
                        return '';
                    }

                    // parse legacy (single-value) upcoming values
                    const upcoming = this.filter.upcoming.split(',');
                    return upcoming.length === 1
                        ? upcoming[0]
                        : upcoming[1] || '';
                },

                set(to) {
                    to = String(to).trim();
                    if ( to === '-' ) {
                        return;
                    }
                    this.update({ upcoming: `${this.dateUpcomingFrom},${this.stripNonNumericInput(to)}` });
                },
            },

            upcomingError() {
                return !!this.dateUpcomingFrom && !!this.dateUpcomingTo && Number(this.dateUpcomingFrom) > Number(this.dateUpcomingTo);
            },

            upcomingMessage() {
                if ( !this.filter.upcoming ) {
                    return '';
                }

                const today = new Date();
                const year = today.getFullYear();
                const month = today.getMonth();
                const day = today.getDate();
                const from = !['', '-'].includes(this.dateUpcomingFrom)
                    ? formatDate(this.localDateFormatted(new Date(year, month, day + Number(this.dateUpcomingFrom))), 'm/d/Y', 'Y-m-d')
                    : null;
                const to = !['', '-'].includes(this.dateUpcomingTo)
                    ? formatDate(this.localDateFormatted(new Date(year, month, day + Number(this.dateUpcomingTo))), 'm/d/Y', 'Y-m-d')
                    : null;

                if ( from && to ) {
                    return this.upcomingError
                        ? 'From should be less than To'
                        : `Between ${from} - ${to}`;

                } else if ( from ) {
                    return `${from} or later`;

                } else if ( to ) {
                    return `${to} or earlier`;

                } else {
                    return '';
                }
            },
        },

        data() {
            return {
                type: null,

                // TODO: handle translation of periods in AbstractModelFilter::translateDateObjects()
                // period: null,
                // periods: [
                //     { value: 'current_week', label: 'Current Week' },
                //     { value: 'current_month', label: 'Current Month' },
                //     { value: 'current_quarter', label: 'Current Quarter' },
                //     { value: 'current_year', label: 'Current Year' },
                //     { value: 'last_week', label: 'Last Week' },
                //     { value: 'last_month', label: 'Last Month' },
                //     { value: 'last_quarter', label: 'Last Quarter' },
                //     { value: 'last_year', label: 'Last Year' },
                // ],
            };
        },

        mounted() {
            this.type = this.setType();
        },

        methods: {
            stripNonNumericInput(text) {
                return text.replace(/[^\d\-]/g, '');
            },

            setType() {
                if ( this.filter.within ) {
                    return 'within';
                } else if ( this.filter.upcoming ) {
                    return 'upcoming';
                } else {
                    return 'range';
                }
            },

            reset() {
                this.update({});
            },

            update(value) {
                value = this.formatValue(value);

                // if using with v-model, emit the value only
                if ( this.value !== undefined ) {
                    this.$emit('input', value);
                    return;
                }

                // otherwise update filters store
                if ( value === null ) {
                    this.removeFilter(this.name);
                    return;
                }

                this.addFilter(this.name, value);
            },

            formatValue(value) {
                if ( value === null ) {
                    return value;
                }

                // remove empty values
                let entries = Object.entries(value).filter(([,v]) => ![null, undefined, ''].includes(v));

                return entries.length === 0
                    ? { value: {} }
                    : { value: Object.fromEntries(entries) };
            }
        },
    }
</script>
