<template>
    <div class="inline-flex flex-wrap">
        <span v-if="!!term" class="ks-badge mt-1" :class="{ 'mr-1': filtersCount > 0, 'muted': muted, 'info': !muted }">
            <strong>Term</strong>:
            {{term}}
            <a v-if="!readOnly" href="#" @click.prevent="$emit('clear-term')">
                <vault-icon :icon="icon_times"></vault-icon>
            </a>
        </span>
        <span
            v-for="(filter, key, index) in expandedFilters"
            :key="'active-filter-' + index"
            class="ks-badge mt-1 text-sm"
            :class="{ 'mr-1': !!term || (index < filtersCount - 1), 'muted': muted, 'info': !muted }"
            style="text-overflow:ellipsis;overflow:hidden;"
        >
            <template v-if="filter.filter_name">
                <strong>{{filter.filter_name}}</strong><template v-if="filter.filter_label">:</template>
            </template>
            <template v-else>
                <strong>{{key | snakeToTitle }}</strong>:
            </template>
            {{filter.filter_label}}
            <a v-if="!readOnly" href="#" @click.prevent="removeFilter(filter)">
                <vault-icon :icon="icon_times"></vault-icon>
            </a>
        </span>
    </div>
</template>

<script>
    import {faTimes} from '@fortawesome/pro-light-svg-icons/faTimes';
    import { snakeToTitle } from 'helpers/strings.js';
    import { formatDateWithin, formatDateUpcoming } from 'helpers/dates.js';

    export default {
        name: 'SearchFiltersDisplay',

        props: {
            filters: {
                type: Object,
                required: true,
            },

            readOnly: {
                type: Boolean,
                default: false,
            },

            term: {
                type: String,
                default: '',
            },

            muted: {
                type: Boolean,
                default: false,
            },
        },

        computed: {
            filtersCount() {
                return Object.keys(this.expandedFilters).length;
            },

            expandedFilters() {
                return Object.entries(this.filters)
                    .reduce((filters, [key, value]) => {
                        let filter_key = value.filter_key;
                        let filter_label = value.filter_label;
                        let filter_name = value.filter_name;
                        let filter_value = value.value;

                        if ( filter_value ) {
                            // filter object - expand subkeys into legacy filters
                            for ( let sub_key in filter_value ) {

                                // handle date 'within'
                                if ( this.checkDateWithin(key, sub_key) ) {
                                    filters[`${key}_${sub_key}`] = {
                                        filter_key: filter_value[sub_key],
                                        filter_label: formatDateWithin(filter_value[sub_key]),
                                        filter_name: snakeToTitle(key),
                                        key,
                                        sub_key,
                                    };
                                    continue;
                                }

                                // handle date 'within'
                                if ( this.checkDateUpcoming(key, sub_key) ) {
                                    filters[`${key}_${sub_key}`] = {
                                        filter_key: filter_value[sub_key],
                                        filter_label: formatDateUpcoming(filter_value[sub_key]),
                                        filter_name: snakeToTitle(key),
                                        key,
                                        sub_key,
                                    };
                                    continue;
                                }

                                filters[`${key}_${sub_key}`] = {
                                    filter_key: filter_value[sub_key],
                                    filter_label: filter_value[sub_key],
                                    filter_name: snakeToTitle(key) + ': ' + snakeToTitle(sub_key),
                                    key,
                                    sub_key,
                                };
                            }
                        } else if ( ['', undefined, null].includes(filter_label) ) {
                            // no filter label = only show the key
                            filters[key] = {
                                filter_name: filter_name || snakeToTitle(key),
                                key,
                            };

                        } else {
                            // legacy filter
                            filters[key] = {
                                filter_key: filter_key || key,
                                filter_label: filter_label || filter_value || value,
                                filter_name: filter_name || snakeToTitle(key),
                                key,
                            };
                        }

                        return filters;
                    }, {});
            },
        },

        data() {
            return {
                icon_times: faTimes,
            };
        },

        methods: {
            removeFilter(filter) {
                this.$emit('clear', filter, filter.key, filter.sub_key);
            },

            checkDateWithin(key, sub_key) {
                return key.slice(-4) === 'date' && sub_key === 'within';
            },

            checkDateUpcoming(key, sub_key) {
                return key.slice(-4) === 'date' && sub_key === 'upcoming';
            },
        },
    }
</script>
