<template>
    <ks-modal
        v-if="showModal"
        ref="modal"
        :close-on-escape="false"
        :close-on-mask-click="false"
        :show-close="false"
        title="Impersonating Warning"
        max-width="500px"
    >
        An impersonation session has been started in another tab. Refresh to continue.

        <div slot="footer">
            <button class="button" @click.prevent="refreshWindow">Refresh</button>
            <button class="button outline" @click.prevent="stopImpersonating">Stop Impersonating</button>
        </div>
    </ks-modal>
</template>

<script>
    // External Dependencies
    import api from 'kickstart-ui/lib/api';
    import {mapGetters} from 'vuex';

    // Internal Dependencies

    export default {
        name: 'ImpersonateWarningModal',

        computed: {
            ...mapGetters([
                'is_impersonating',
            ]),
        },

        data() {
            return {
                showModal: false,
            };
        },

        methods: {
            open() {
                this.showModal = true;

                this.$nextTick(() => {
                    this.$refs.modal.open();
                });
            },

            close() {
                this.showModal = false;
            },

            refreshWindow() {
                const route = this.$router.resolve({name: 'dashboard'});

                location.href = route.href;
            },

            stopImpersonating() {
                api.delete('/impersonate')
                    .then(() => {
                        window.location.reload();
                    });
            },
        },
    }
</script>