<template>
    <ks-autocomplete
        ref="autocomplete"
        v-model="selected_items"
        :items="unselectedItems"
        :placeholder="placeholder"
        :selection-key="selectionKey"
        :multiple="true"
        :min-search="0"
    ></ks-autocomplete>
</template>

<script>
    export default {
        name: 'MultiSelectField',

        props: {
            value: {
                type: Array,
                default: () => [],
            },

            items: {
                type: Array,
                required: true,
            },

            placeholder: {
                type: String,
                required: false,
            },

            selectionKey: {
                type: String,
                default: 'label',
            },

            itemKey: {
                type: String,
                default: 'value',
            },
        },

        computed: {
            selectedValues() {
                return this.selected_items.map(item => item[this.itemKey]);
            },

            unselectedItems() {
                return this.items.filter(item => !this.selectedValues.includes(item[this.itemKey]));
            },
        },

        data() {
            return {
                selected_items: [],
            };
        },

        mounted() {
            this.initializeItems();
        },

        methods: {
            initializeItems() {
                this.selected_items = this.items.filter(item => this.value.find(v => v == item[this.itemKey]));
            },
        },

        watch: {
            selectedValues(values) {
                this.$emit('input', values);
            },

            unselectedItems() {
                this.$nextTick(() => {
                    this.$refs.autocomplete.startSearch();
                });
            },
        }
    }
</script>
