import {Extension} from 'tiptap';

const ALLOWED_NODE_TYPES = [
    'paragraph',
    'heading'
];

const setTextAlign = (tr, align) => {
    const {selection, doc} = tr;

    if ( !selection || !doc ) {
        return tr;
    }

    const {from, to} = selection;
    const tasks = [];

    doc.nodesBetween(from, to, (node, pos) => {
        const nodeType = node.type;

        if ( ALLOWED_NODE_TYPES.includes(nodeType.name) ) {
            const textAlign = node.attrs.textAlign || null;
            if ( textAlign !== align ) {
                tasks.push({node, pos, nodeType});

                return true;
            }
        }

        return true;
    })

    if ( tasks.length === 0 ) {
        return tr;
    }

    tasks.forEach(task => {
        const {node, pos, nodeType} = task;
        let {attrs} = node;

        attrs = {
            ...attrs,
            textAlign: align
        }

        tr = tr.setNodeMarkup(pos, nodeType, attrs, node.marks);
    })

    return tr;
}


export default class TextAlign extends Extension {
    get name() {
        return 'text_align';
    }

    commands() {
        return align => (state, dispatch) => {
            const {selection} = state;
            const tr = setTextAlign(
                state.tr.setSelection(selection),
                align === 'left' ? null : align
            );

            if ( tr.docChanged ) {
                dispatch && dispatch(tr);

                return true;
            }

            return false;
        }
    }
}