<template>
    <div class="row">
        <page-side-nav :links="links" class="col tab-full med-2"></page-side-nav>

        <div class="col tab-full med-10">
            <router-view></router-view>
        </div>
    </div>
</template>

<script>
    import PageSideNav from '@Vault/Navigation/PageSideNav.vue';

    export default {
        name: 'UserManagement',

        computed: {
            links() {
                return [
                    { title: 'Users', route: 'admin-users' },
                    { title: 'Roles/Groups', route: 'admin-roles' },
                    { title: 'Certifications', route: 'admin-certifications', active: this.isAdminCertificationLink },
                    { title: 'Saved Searches', route: 'admin-saved-searches' },
                    { title: 'Tasks Setup', route: 'admin-tasks' },
                    { title: 'Timesheets', route: 'admin-timesheets' },
                ];
            },

            isAdminCertificationLink() {
                return ['admin-certifications', 'admin-certification-types'].includes(this.$route.name);
            },
        },

        components: {
            PageSideNav,
        },
    }
</script>
