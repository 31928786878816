<template>
    <router-view></router-view>
</template>

<script>
    // External Dependencies

    // Internal Dependencies
    import Breadcrumbs from 'BreadCrumbs';

    export default {
        name: 'CustomerSchedules',

        mixins: [Breadcrumbs],

        beforeMount() {
            this.pushBreadcrumb({
                title: 'Schedules',
                to: {
                    name: 'customer-work-schedules'
                }
            });
        },
    }
</script>