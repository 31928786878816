
const WorkOrder = () => import('./components/WorkOrder.vue');
const WorkOrderDashboard = () => import('./components/WorkOrderDashboard.vue');
const WorkOrderList = () => import('./components/WorkOrderList.vue');
const WorkOrderForm = () => import('./components/WorkOrderForm.vue');
const WorkOrderShow = () => import('./components/WorkOrderShow.vue');
const WorkShow = () => import('./components/Show/WorkShow.vue');
const WorkVisitShowRoot = () => import('./components/Show/WorkVisitShowRoot.vue');
const WorkVisitList = () => import('./components/WorkVisitList.vue');
const WorkVisitShow = () => import('./components/Show/WorkVisitShow.vue');
const WorkVisitVerification = () => import('./components/WorkVisitVerification.vue');

// Schedule
const WorkOrderSchedule = () => import('./components/WorkOrderSchedule.vue');
const WorkOrderScheduleCalendar = () => import('./components/Schedule/WorkOrderScheduleCalendar.vue');
const WorkOrderScheduleList = () => import('./components/Schedule/WorkOrderScheduleList.vue');
const WorkOrderWeek = () => import('./components/WorkOrderWeek.vue');
const WorkScheduleCrew = () => import('./components/WorkScheduleCrew.vue');
const WorkOrderPreviewPopup = () => import('@Vault/WorkOrder/components/Schedule/WorkOrderPreviewPopup.vue');

// Map
const WorkScheduleMap = () => import('./components/Schedule/WorkScheduleMap.vue');

// Forms
const WorkVisitFormEntry = () => import ('./components/WorkVisitFormEntry.vue');

import work_quotes from '../WorkQuotes/work_quotes.routes';

export default [
    {
        path: '/work/:work_number(\\d+)/visits/:work_visit_number(\\d+)/preview',
        component: WorkOrderPreviewPopup,
        name: 'work-order-preview',
        props: true,
        meta: {
            popup: true,
        },
        perms: ['work_view'],
    },
    {
        path: '/work',
        component: WorkOrder,
        meta: {
            group: 'main',
            icon: 'IconClipboard',
            title: 'Work',
            perms: ['work_view'],
        },
        children: [
            {
                path: '',
                name: 'work',
                component: WorkOrderDashboard,
                props: {
                    filterNamespace: 'work_dashboard',
                },
                meta: {
                    title: 'Work',
                }
            },
            {
                path: 'list',
                name: 'work-order-list',
                component: WorkOrderList,
                meta: {
                    group: 'main',
                    title: 'List',
                }
            },
            {
                path: 'visits',
                name: 'work-visits-list',
                component: WorkVisitList,
                meta: {
                    group: 'main',
                    title: 'Visits',
                }
            },
            {
                path: 'create',
                name: 'work-create',
                component: WorkOrderForm,
                meta: {
                    perms: ['work_create']
                }
            },
            {
                path: ':work_number(\\d+)',
                component: WorkOrderShow,
                props: true,
                children: [
                    {
                        path: '',
                        name: 'work-show',
                        component: WorkShow,
                        props: true,
                    },
                    {
                        path: 'visits/:work_visit_number(\\d+)',
                        component: WorkVisitShowRoot,
                        props: true,
                        children: [
                            {
                                path: '',
                                name: 'work-visit-show',
                                component: WorkVisitShow,
                                props: true,
                            },
                            {
                                path: 'form-entries/:form_entry_id(\\d+)',
                                component: WorkVisitFormEntry,
                                name: 'work-visit-form-entry',
                                props: true,
                                meta: {
                                    perms: ['work_manage_forms'],
                                },
                            },
                            {
                                path: 'form-entries/:form_entry_id(\\d+)/sections/:section_id',
                                component: WorkVisitFormEntry,
                                name: 'work-visit-form-entry-section',
                                props: true
                            },
                            {
                                path: 'verification',
                                name: 'work-visit-verification',
                                component: WorkVisitVerification,
                                props: true,
                            },
                        ]
                    },
                ]
            },
            ...work_quotes
        ]
    },
    {
        path: '/schedule',
        component: WorkOrderSchedule,
        meta: {
            group: 'main',
            title: 'Schedule',
            icon: 'IconCalendar',
            perms: ['work_view'],
        },
        children: [
            {
                path: '',
                name: 'schedule-calendar',
                component: WorkOrderScheduleCalendar,
                props: true,
                meta: {
                    group: 'main',
                    title: 'Calendar',
                    exact: true
                },
            },
            {
                path: 'week/:date?',
                name: 'schedule-week',
                component: WorkOrderWeek,
                props: true,
                meta: {
                    group: 'main',
                    title: 'Week',
                }
            },
            {
                path: 'list/:date?',
                name: 'schedule-list',
                component: WorkOrderScheduleList,
                props: true,
                meta: {
                    group: 'main',
                    title: 'Focused',
                },
            },
            {
                path: 'map/date/:date?',
                name: 'schedule-map-date',
                component: WorkScheduleMap,
                props: true,
                meta: {
                    group: 'main',
                    title: 'Map',
                    mode: 'date',
                },
            },
            {
                path: 'map/visits/:visits_key?',
                name: 'schedule-map-visits',
                component: WorkScheduleMap,
                props: true,
                meta: {
                    title: 'Map',
                    mode: 'visits',
                },
            },
            {
                path: 'crew/:date?',
                name: 'schedule-crew',
                component: WorkScheduleCrew,
                props: true,
            },
        ],
    },
]
