const TimesheetWeek = () => import('@Vault/Timesheets/TimesheetWeek.vue');
const Timesheets = () => import ('../../Vault/Timesheets/Timesheets.vue');
const TimesheetsBase = () => import('@Vault/Timesheets/TimesheetsBase.vue');

export default [
    {
        path: '/timesheets',
        component: TimesheetsBase,
        children: [
            {
                path: '/timesheets',
                component: Timesheets,
                name: 'timesheets',
                meta: {
                    title: 'Timesheets',
                    user_types: ['admin', 'account_admin', 'field'],
                },
            },
            {
                path: '/timesheets/:date',
                name: 'timesheet-edit',
                component: TimesheetWeek,
                props: true,
                meta: {
                    title: 'Edit Timesheet',
                    user_types: ['admin', 'account_admin', 'field'],
                },
            },
        ],
    },
]
