<template>
    <div>
        Invoices
    </div>
</template>


<script>
    import BreadCrumbs from '../../../BreadCrumbs';

    export default {
        name: 'AdminInvoices',

        mixins: [BreadCrumbs],

        props: {},

        computed: {},

        data() {
            return {};
        },

        beforeMount() {
            this.pushBreadcrumb({
                title: 'Billing: Invoices',
                to: {
                    name: 'admin-invoices'
                }
            })
        },

        mounted() {

        },

        methods: {},

        watch: {},
    }
</script>