<template>
    <ks-action-menu>
        <a href="#"
           class="p-2 list-item"
           @click.prevent="$emit('edit')"
        >
            Edit
        </a>
        <a v-if="showFormHistory" href="#" class="p-2 list-item" @click.prevent="$emit('form-history')">
            Form History
        </a>
        <slot></slot>
        <a v-if="showDelete" href="#"  @click.prevent="$emit('delete')" class="separator danger p-2">
            Delete
        </a>
    </ks-action-menu>
</template>

<script>
    export default {
        name: 'EquipmentActions',

        props: {
            equipment: {
                type: Object,
                required: true,
            },
            showDelete: {
                type: Boolean,
                default: true,
            },
            showFormHistory: {
                type: Boolean,
                default: false
            }
        }
    };
</script>
