import Repository from '@Vault/Repository.js';

export class CertificationsRepository extends Repository {
    constructor() {
        super();
        this.resource = 'certifications';
    }
}

const Certifications = new CertificationsRepository();

export default Certifications;

/**
 * @typedef Certification
 * @type {object}
 * @property {number} certification_id
 * @property {number} certification_type_id
 * @property {number} user_id
 * @property {CertificationType} type
 * @property {User} user
 * @property {?object} file
 * @property {?string} certificate_number
 * @property {?string} issue_date
 * @property {?string} expiration_date
 * @property {?string} comments
 * @property {?string} created_at
 * @property {?string} updated_at
 */
