import Repository from '@Vault/Repository.js';

export class CertificationTypesRepository extends Repository {
    constructor() {
        super();
        this.resource = 'certifications/types';
    }
}

const CertificationTypes = new CertificationTypesRepository();

export default CertificationTypes;

/**
 * @typedef CertificationType
 * @type {object}
 * @property {string} name
 * @property {number} certification_type_id
 * @property {?string} certification_authority
 * @property {?number} state_id
 * @property {?State[]} state
 * @property {?string} notify_at
 * @property {?Certification[]} certifications
 * @property {?number} certifications_count
 * @property {?number} monitors_count
 * @property {?string} created_at
 * @property {?string} updated_at
 */
