const List = () => import('./components/ImportTemplateIndex.vue');
const Create = () => import('./components/ImportTemplateCreate.vue');
const Edit = () => import('./components/ImportTemplateEdit.vue');

export default [
    {
        path: 'list',
        name: 'admin-imports-list',
        component: List
    }, {
        path: 'create',
        name: 'admin-imports-create',
        component: Create
    }, {
        path: 'edit/:import_template_id',
        props: true,
        name: 'admin-imports-edit',
        component: Edit
    }
]
