<template>
    <div class="row">
        <div class="col sm-12">
            <system-users></system-users>
        </div>
    </div>
</template>

<script>
    // External Dependencies

    // Internal Dependencies
    import SystemUsers from './SystemUsers.vue';

    export default {
        name: 'SystemAdmin',

        components: {
            SystemUsers,
        }
    }
</script>