import Countries from '@Vault/Locations/CountryRepository.js';

export default {
    data() {
        return {
            loading_states: false,
            loaded_states: [],
        };
    },

    methods: {
        loadStates() {
            this.loading_states = true;

            return Countries.list({ with: 'states' })
                .then(({data}) => {
                    this.loaded_states = data.map((country) => country.states.map((state) => ({
                            ...state,
                            country_name: country.country_name,
                        })))
                        .flat(1)
                })
                .catch(() => {
                    this.$toast({
                        type: 'error',
                        message: 'Failed retrieving countries with states'
                    });
                })
                .finally(() => {
                    this.loading_states = false;
                });
        },
    }
}
