<template>
    <div>
        <router-view></router-view>
    </div>
</template>

<script>
import BreadCrumbs from 'BreadCrumbs';

export default {
    mixins: [BreadCrumbs],

    beforeMount() {
        this.pushBreadcrumb({
            title: 'Timesheets',
            to: {name: 'admin-timesheets'},
        });
    },
}
</script>
