<template>
    <div>
        <router-view></router-view>
    </div>
</template>

<script>
    // External Dependencies

    // Internal Dependencies
    import BreadCrumbs from '@Vault/BreadCrumbs';

    export default {
        name: 'AdminNotificationTemplates',

        mixins: [BreadCrumbs],

        beforeMount() {
            this.pushBreadcrumb({
                title: 'Notification Templates',
                to: {
                    name: 'admin-notification-templates'
                }
            });
        },
    }
</script>