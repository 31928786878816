<template>
    <div class="row">
        <div class="col tab-12">
            <router-view></router-view>
        </div>
    </div>
</template>

<script>
    // External Dependencies

    // Internal Dependencies
    import BreadCrumbs from 'BreadCrumbs';

    export default {
        name: 'WorkSchedule',

        mixins: [BreadCrumbs],

        beforeMount() {
            this.pushBreadcrumb({
                title: 'Work Schedule: Templates',
                to: {
                    name: 'admin-work-schedule-template'
                }
            })
        }
    }
</script>