const AdminTimesheets = () => import ('@Vault/Admin/Timesheets/components/AdminTimesheets.vue');
const AdminTimesheetsTabs = () => import ('@Vault/Admin/Timesheets/components/AdminTimesheetsTabs.vue');
const AdminTimesheetCalendar = () => import('@Vault/Admin/Timesheets/components/AdminTimesheetCalendar.vue');
const AdminTimesheetCalendarParent = () => import('@Vault/Admin/Timesheets/components/AdminTimesheetCalendarParent.vue');
const AdminTimesheetEdit = ()  => import('@Vault/Admin/Timesheets/components/AdminTimesheetEdit.vue');

export default [
    {
        path: 'timesheets',
        component: AdminTimesheets,
        meta: {
            perms: ['admin_timesheet_settings', 'admin_user_timesheets', 'admin_user_vacations'],
        },
        children: [
            {
                path: '',
                component: AdminTimesheetsTabs,
                name: 'admin-timesheets',
            },
            {
                path: 'users/:user_id',
                component: AdminTimesheetCalendarParent,
                props: true,
                children: [
                    {
                        path: '',
                        name: 'admin-user-timesheet-calendar',
                        component: AdminTimesheetCalendar,
                        props: true,
                        meta: {
                            perms: ['admin_user_timesheets'],
                        },
                    },
                    {
                        path: ':date',
                        name: 'admin-user-timesheet-edit',
                        component: AdminTimesheetEdit,
                        props: true,
                        meta: {
                            perms: ['admin_user_timesheets'],
                        },
                    },
                ],
            },
        ],
    },
]
