import Vue from 'vue';
import Vuex from 'vuex';
import { setSentryUser } from 'helpers/sentry.js';

Vue.use(Vuex);

const state = {
    app_version: null,
    refresh_required: false,
    entity_id: 0,
    entity: {
        entity_name: '',
        timezone_code: '',
    },
    user_id: 0,
    user_role_id: 0,
    user_customer_id: 0,
    username: '',
    is_impersonating: false,
    permissions: [],
    breadcrumbs: [],
    app_loaded: false,
    route_to: null,
};

const mutations = {
    SET_ENTITY_ID(state, entity_id) {
        state.entity_id = entity_id
    },

    SET_USER_ID(state, user_id) {
        state.user_id = user_id;
    },

    SET_USER_ROLE_ID(state, user_role_id) {
        state.user_role_id = user_role_id;
    },

    SET_USER_CUSTOMER_ID(state, customer_id) {
        state.user_customer_id = customer_id;
    },

    SET_USERNAME(state, username) {
        state.username = username;
        setSentryUser(username);
    },

    SET_IS_IMPERSONATING(state, is_impersonating) {
        state.is_impersonating = is_impersonating;
    },

    SET_APP_LOADED(state, loaded) {
        state.app_loaded = loaded;
    },

    //Breeadcrumb methods
    RESET_BREADCRUMB(state, breadcrumb) {
        Vue.nextTick(() => {
            state.breadcrumbs = breadcrumb ? [breadcrumb] : [];
        });
    },
    PUSH_BREADCRUMB(state, breadcrumb) {
        Vue.nextTick(() => {
            state.breadcrumbs.push(breadcrumb);
        });
    },
    POP_BREADCRUMB(state) {
        state.breadcrumbs.pop();
    },
    EDIT_BREADCRUMB(state, breadcrumb) {
        Vue.set(state.breadcrumbs, state.breadcrumbs.length - 1, breadcrumb)
    },
    SET_PERMISSIONS(state, permissions) {
        Vue.set(state, 'permissions', permissions);
    },
    SET_APP_VERSION(state, app_version) {
        Vue.set(state, 'app_version', app_version);
    },
    SET_REFRESH_REQUIRED(state) {
        Vue.set(state, 'refresh_required', true);
    },

    SET_ROUTE_TO(state, route_to) {
        state.route_to = route_to;
    },
};

import * as getters from './getters';
import * as actions from './actions';

export default new Vuex.Store({
    state,
    mutations,
    getters,
    actions,
});
