<template>
    <div class="form-group">
        <div class="label">
            {{label}}
            <div class="right text-xs" v-if="showActiveToggle" >
                <a href="#" @click.prevent="toggleUserStatus" :class="{'text-green-500':  user_status === 'active'}">
                    {{ userStatus }}
                </a>
            </div>
        </div>
        <ks-select
            ref="select"
            :name="name"
            :label-key="labelKey"
            :placeholder="placeholder"
            v-model="model"
            item-key="user_id"
            :single-deselect="true"
            :disabled="disabled"
            @search="searchUsers"
            :paginated="true"
        >
            <template v-slot:default="{item}">
                <div class="flex jc-between">
                    {{item[labelKey]}}
                    <span v-if="item.deactivated_on" class="flex-end ks-badge warning">
                        Deactivated
                    </span>
                </div>
            </template>
        </ks-select>
    </div>
</template>

<script>
    // External Dependencies

    // Internal Dependencies
    import FilterMixin from'../../../Filters/mixins/FilterMixin';
    import Users from '@Vault/Users/repositories/UsersRepository';

    export default {
        name: 'UserSelectFilter',

        mixins: [FilterMixin],

        props: {
            name: {
                type: String,
                default: ''
            },

            placeholder: {
                type: String,
                default: 'Select...'
            },

            label: {
                type: String,
                default: ''
            },

            labelKey: {
                type: String,
                default: 'display_name'
            },

            filterKey: {
                type: String,
                default: ''
            },

            value: {},

            disabled: {
                type: Boolean,
                default: false
            },

            userType: {
                type: Array,
                default() {
                    return [];
                }
            },

            additionalFilters: {
                type: Object,
                default() {
                    return {};
                }
            },

            showActiveToggle: {
                type: Boolean,
                default: false,
            }
        },

        computed: {
            userStatus() {
                switch ( this.user_status ) {
                    case 'active':
                        return 'Active Users';
                    default:
                        return 'All Users';
                }
            },
            model: {
                get() {
                    let user = this.getFilter(this.name);

                    // If the user is null then just return it
                    // -or- If the user has a user_id key
                    if ( !user || 'user_id' in user ) {
                        return user;
                    }

                    // If we don't have a user_id key then it is only a filter object...
                    /// therefore we need to pull back the correct user_object details
                    // in order for the select to display properly
                    return Object.assign({
                        [this.labelKey]: user.filter_label
                    }, user);
                },

                set(value) {
                    let key = this.labelKey;

                    if ( !value ) {
                        this.removeFilter(this.name);
                        return null;
                    }

                    if ( this.filterKey != '' ) {
                        key = this.filterKey;
                    }

                    this.addFilter(this.name, Object.assign(
                        value, {
                            filter_key: value[key],
                            filter_label: value[this.labelKey]
                        })
                    );
                }
            },

            userFilters() {
                return {
                    user_type: this.userType,
                    ...this.additionalFilters,
                    ...(this.showActiveToggle ? {user_status: this.user_status} : {}),
                };
            },
        },

        data() {
            return {
                user_status: 'active',
            }
        },

        methods: {
            toggleUserStatus() {
                if ( this.user_status === 'active' ) {
                    this.user_status = 'all';
                } else {
                    this.user_status = 'active';
                }

                this.$refs.select.reset();
            },
            searchUsers({term, callback, page}) {
                callback(Users.endpointUri({...this.userFilters, page, search_name: term}));
            },
        },
    }
</script>
