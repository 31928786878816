<template>
    <div id="admin-certifications">
        <ks-tabs ref="tabs">
            <ks-tab ref="certifications" title="Certifications" @active="setRoute('admin-certifications')">
                <certifications-list
                    :group-types="true"
                    :admin="true"
                ></certifications-list>
            </ks-tab>
            <ks-tab ref="types" title="Types" @active="setRoute('admin-certification-types')">
                <certification-types-list></certification-types-list>
            </ks-tab>
        </ks-tabs>
    </div>
</template>

<script>
    import BreadCrumbs from '@Vault/BreadCrumbs.js';
    import CertificationsMixin from '@Vault/Admin/Certifications/mixins/CertificationsMixin.js';
    import CertificationsList from '@Vault/Certifications/CertificationsList.vue';
    import CertificationTypesList from '@Vault/Admin/Certifications/components/CertificationTypesList.vue';

    export default {
        name: 'AdminCertifications',

        mixins: [BreadCrumbs, CertificationsMixin],

        beforeMount() {
            this.pushBreadcrumb({
                title: 'Certifications',
                to: {
                    name: 'admin-certifications',
                    query: {},
                },
            });
        },

        data() {
            return {
                tabs: {
                    'admin-certifications': 'certifications',
                    'admin-certification-types': 'types',
                },
                certification_type_id: null,
                user_id: null,
            };
        },

        mounted() {
            this.setTab(this.$route.name);
        },

        methods: {
            setRoute(route_name) {
                if ( this.$route.name !== route_name ) {
                    this.$router.push({ name: route_name });
                }
            },

            setTab(route_name) {
                if ( !Object.keys(this.tabs).includes(route_name) ) {
                    return;
                }

                this.$refs.tabs.setActiveTab(this.$refs[this.tabs[route_name]]);
            },
        },

        watch: {
            '$route.name'(route_name) {
                this.setTab(route_name);
            },
        },

        components: {
            CertificationsList,
            CertificationTypesList,
        },
    }
</script>
