const Customers = () => import('./components/Customers.vue');
const CustomerList = () => import('./components/CustomerList.vue');
const CustomerShow = () => import('./components/CustomerShow.vue');
const CustomerForm = () => import('./components/CustomerForm.vue');
const CustomerNotificationsForm = () => import('./components/CustomerNotificationsForm.vue');
const CustomerTabs = () => import('./components/CustomerTabs.vue');
const CustomerContactForm = () => import('./components/CustomerContactForm.vue');
const CustomerCreate = () => import('./components/CustomerCreate.vue');
const LocationForm = () => import('../Locations/components/LocationForm.vue');
const LocationProfile = () => import('../Locations/components/LocationProfile.vue');
const LocationTabs = () => import('../Locations/components/LocationTabs.vue');
const LocationEquipmentForm = () => import('../Locations/components/LocationEquipmentForm.vue');
const CustomerLocations = () => import('./components/CustomerLocations.vue');
const CustomerSchedules = () => import('./components/Schedules/CustomerSchedules.vue');
const CustomerExecutedSchedules = () => import('./components/Schedules/CustomerExecutedSchedules.vue');
const CustomerViewExecutedSchedule = () => import('./components/Schedules/CustomerViewExecutedSchedule.vue');
import invoice_routes from '../Invoices/invoices.routes';

export default [
    {
        path: '/customers',
        component: Customers,
        meta: {
            group: 'main',
            title: 'Customers',
            icon: 'IconUserCircle',
            perms: ['customer_view'],
        },
        children: [
            {
                path: '',
                name: 'customers',
                component: CustomerList,
                props: true,
                meta: {
                    title: 'Customers'
                }
            },
            {
                path: 'create',
                name: 'customer-add',
                component: CustomerCreate,
                props: true,
                meta: {
                    title: 'New Customer',
                    perms: ['customer_create']
                }
            },
            {
                path: 'locations',
                name: 'customer-locations',
                component: CustomerLocations,
                meta: {
                    group: 'main',
                    title: 'Locations',
                    perms: ['customer_locations'],
                }
            },
            {
                path: 'locations/:location_id',
                component: LocationProfile,
                props: true,
                children: [
                    {
                        path: '',
                        name: 'customer-location-profile',
                        component: LocationTabs
                    },
                    {
                        path: 'equipment',
                        name: 'location-equipment-create',
                        component: LocationEquipmentForm,
                    },
                    {
                        path: 'equipment/:equipment_id',
                        name: 'location-equipment-edit',
                        props: true,
                        component: LocationEquipmentForm,
                    },
                ],
            },
            {
                path: ':customer_id(\\d+)',
                component: CustomerShow,
                props: true,
                meta: {
                    name: 'customer_id',
                    title: 'Customer Profile'
                },
                children: [
                    {
                        path: '',
                        name: 'customer-profile',
                        component: CustomerTabs,
                        props: true,
                        meta: {
                            title: 'Customer Profile'
                        }
                    },
                    {
                        path: 'contacts/create',
                        name: 'customer-contact-add',
                        component: CustomerContactForm,
                        props: true,
                        meta: {
                            title: 'Add Customer Contact'
                        }
                    },
                    {
                        path: 'contacts/:customer_contact_id/edit',
                        name: 'customer-contact-edit',
                        component: CustomerContactForm,
                        props: true,
                        meta: {
                            title: 'Edit Customer Contact'
                        }
                    },
                    {
                        path: 'edit',
                        name: 'customer-edit',
                        component: CustomerForm,
                        props: true,
                        meta: {
                            title: 'Update Customer'
                        }
                    },
                    {
                        path: 'notifications',
                        name: 'customer-notifications',
                        component: CustomerNotificationsForm,
                        props: true,
                        meta: {
                            title: 'Manage Customer Notifications',
                            perms: ['customer_edit'],
                        }
                    },
                    {
                        path: 'locations/create',
                        name: 'customer-location-add',
                        component: LocationForm,
                        props: true,
                        meta: {
                            title: 'Add Customer Location',
                            back: 'customer-profile'
                        }
                    },
                    {
                        path: 'locations/:location_id/edit',
                        name: 'customer-location-edit',
                        component: LocationForm,
                        props: true,
                        meta: {
                            title: 'Edit Customer Location',
                            back: 'customer-profile'
                        }
                    }
                ]
            },
            ...invoice_routes,
            {
                path: 'schedules',
                component: CustomerSchedules,
                props: true,
                meta: {
                    group: 'main',
                    title: 'schedules',
                    perms: ['work_create'],
                },
                children: [
                    {
                        path: '',
                        name: 'customer-work-schedules',
                        component: CustomerExecutedSchedules,
                        meta: {
                            perms: ['work_create'],
                        }
                    },
                    {
                        path: ':work_schedule_id',
                        name: 'customer-work-schedule-view',
                        component: CustomerViewExecutedSchedule,
                        props: true,
                    }
                ]
            },
        ]
    },
];
