import Users from '@Vault/Users/repositories/UsersRepository';

export default {
    methods: {
        runFieldUserSearch({term, callback, page}, params = {}) {
            this.runUserSearch({term, callback, page}, {
                user_type: ['field', 'sub_contractor'],
                ...params,
            });
        },
        runAdminUserSearch({term, callback, page}, params = {}) {
            this.runUserSearch({term, callback, page}, {
                user_type: ['admin', 'account_admin'],
                ...params,
            });
        },
        runNonCustomerUserSearch({term, callback, page}, params = {}) {
            this.runUserSearch({term, callback, page}, {
                user_type: ['admin', 'account_admin', 'field', 'sub_contractor'],
                ...params,
            });
        },
        runUserSearch({term, callback, page}, params) {
            callback(
                Users.endpointUri({
                    ...params,
                    search_name: term,
                    page,
                })
            ).catch((error) => {
                console.error('UserSearchMixin::runUserSearch: ', error);
            });
        },
    }
}