<template>
    <div>
        <div class="label" v-if="label">{{label}}</div>
        <ks-select
            :name="name"
            :label-key="labelKey"
            @search="runFieldUserSearch"
            :placeholder="placeholder"
            v-model="model"
            @input="$emit('input', $event ? $event.user_id : null)"
            item-key="user_id"
            :single-deselect="true"
            :paginated="true"
            :disabled="disabled"
        ></ks-select>
    </div>
</template>

<script>

    // Mixins
    import UserSearchMixin from '@Vault/Users/mixins/UserSearchMixin';

    export default {
        name: 'UserSelect',

        mixins: [UserSearchMixin],

        props: {
            name: {
                type: String,
                default: 'user'
            },

            placeholder: {
                type: String,
                default: 'Select...'
            },

            label: {
                type: String,
                default: ''
            },

            labelKey: {
                type: String,
                default: 'display_name'
            },

            filterKey: {
                type: String,
                default: ''
            },

            value: {},

            disabled: {
                type: Boolean,
                default: false
            }
        },

        data() {
            return {
                model: null,
            };
        },

        watch: {
            value() {
                if ( this.value instanceof Object ) {
                    this.model = {...this.value};
                } else {
                    this.model = this.value;
                }
            }
        }
    }
</script>