<template>
    <div id="inventory-locations">
        <router-view></router-view>
    </div>
</template>


<script>

    import BreadCrumbs from '@Vault/BreadCrumbs';

    export default {
        name: 'InventoryLocations',

        mixins: [BreadCrumbs],

        beforeMount() {
            this.pushBreadcrumb({
                title: 'Locations',
                to: {
                    name: 'inventory'
                }
            });
        },
    }
</script>
