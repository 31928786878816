<template>
    <div id="inventory">
        <page-header>
            <page-breadcrumb></page-breadcrumb>
        </page-header>

        <router-view></router-view>
    </div>
</template>


<script>
    import BreadCrumbs from '@Vault/BreadCrumbs.js';

    export default {
        name: 'Inventory',

        mixins: [BreadCrumbs],

        beforeMount() {
            this.resetBreadcrumb({
                title: 'Inventory',
                to: { name: 'inventory-dashboard' },
            });
        }
    }
</script>
