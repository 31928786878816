<template>
    <ks-side-nav id="sidebar" class="vertical" :fixed="true">
        <nav-links :routes="routes"></nav-links>
    </ks-side-nav>
</template>


<script>
    // External Dependencies
    import KsSideNav from 'kickstart-ui/lib/KsSideNav';

    // Internal Dependencies
    import NavLinks from './NavLinks.vue';
    import {mapGetters} from 'vuex';

    export default {
        name: 'NavMenu',

        props: {
            group: {
                type: String,
                default: 'main'
            }
        },

        data() {
            return {};
        },

        computed: {
            ...mapGetters(['user_type']),

            routes() {
                return this.$router.options.routes.filter((route) => {
                    if ( !route.meta ) {
                        return false;
                    }

                    if ( Array.isArray(route.meta.perms) && !this.$userCan.apply(this, route.meta.perms) ) {
                        return false;
                    }

                    if ( Array.isArray(route.meta.user_types) && route.meta.user_types.indexOf(this.user_type) === -1 ) {
                        return false;
                    }

                    return route.meta.group && route.meta.group === this.group;
                });
            }
        },

        methods: {
            hasChildren(route) {
                return (typeof route.children !== 'undefined');
            },

            defaultRoute(route) {
                return this.hasChildren(route) && route.children[0].path === ''
                    ? route.children[0].name
                    : false;
            }
        },

        components: {
            KsSideNav,
            NavLinks
        }
    }
</script>
