function lighten(color, amount) {
    let num = parseInt(color, 16);
    amount = Math.round(amount);

    let r = (num >> 16) + amount;
    if ( r > 255 ) {
        r = 255;
    } else if ( r < 0 ) {
        r = 0;
    }

    let b = ((num >> 8) & 0x00FF) + amount;
    if ( b > 255 ) {
        b = 255;
    } else if ( b < 0 ) {
        b = 0;
    }

    let g = (num & 0x0000FF) + amount;
    if ( g > 255 ) {
        g = 255;
    } else if ( g < 0 ) {
        g = 0;
    }

    return (0x1000000 + r * 0x10000 + b * 0x100 + g).toString(16).slice(1);
}

export default {
    data() {
        return {
            heatmap: []
        }
    },

    methods: {
        /**
         *
         * @param {[]} data
         * @param {string} count_column
         * @param {?string} [color='1D6F98'] - RGB hex string without the #
         * @returns {(*&{color: string|*})[]}
         */
        injectHeatmapColors(data, count_column, color = '1D6F98') {
            if ( !data.length ) {
                return data;
            }

            // get the unique values
            const values = [...(new Set(data.map(i => i[count_column])))]
                .sort((a, b) => parseInt(b) - parseInt(a));
            const increment = Math.round(100 / values.length);

            return data.map(item => ({
                ...item,
                color: item[count_column] === values[0]
                    ? color // max value
                    : lighten(color, values.findIndex(value => value === item[count_column]) * increment)
            }));
        }
    }
}
