export default {
    methods: {
        /**
         *
         * @param {ServiceItem[]} items
         * @param {InventoryTransaction[]} transactions
         * @returns {ServiceItem[]}
         */
        mergeItemTransactions(items, transactions) {
            return items?.map((item) => ({
                ...item,
                transaction: transactions?.find(({inventory_transaction_id}) => inventory_transaction_id === item.inventory_transaction_id) || null,
            })) || [];
        },
    },
}
