<template>
    <div class="muted text-xs text-right bg-gray-100 p-2" v-if="showFooter">
        <div class="diagram-version-data" v-if="diagramCreator">
            Last modified by {{diagramCreator.display_name}} at {{diagramVersionDate | date}} (v{{diagramVersionNumber}})
        </div>
        <div class="diagram-approval-data pt-1" v-if="diagramApproval">
            Verified by {{diagramApprovedBy.display_name}} at {{diagramApprovedOn | date}} {{diagramApprovedOn | time}}
        </div>
    </div>
</template>

<script>
    export default {
        name: "LocationDiagramFooter",

        props: {
            diagram: {
                required: true,
                type: Object,
                default() {
                    return {};
                }
            }
        },

        computed: {
            showFooter() {
                return this.diagramCreator || this.diagramApproval;
            },

            diagramApproval() {
                return this.diagram?.current_approval || null;
            },

            diagramApprovedBy() {
                return this.diagramApproval?.approved_by;
            },

            diagramApprovedOn() {
                return this.diagramApproval?.approved_on;
            },

            diagramCreator() {
                return this.diagram?.current_version?.creator || this.diagram?.creator;
            },

            diagramVersionDate() {
                return this.diagram?.current_version?.created_at || this.diagram?.created_at;
            },

            diagramVersionNumber() {
                return this.diagram?.current_version?.version_number || 1;
            }
        }
    }
</script>