<template>
    <div>
        <div class="heading">Location</div>

        <div class="col">
            <form-group label="Address" :name="'location_address'">
                <input type="text" v-model="location_address"/>
            </form-group>
            <form-group label="City" :name="'location_city'">
                <input type="text" v-model="location_city"/>
            </form-group>
            <form-group label="State" :name="'location_state'">
                <ks-autocomplete
                        :items="states"
                        selection-key="state"
                        group-by="country_name"
                        :class="{disabled: loading_states}"
                        v-model="location_state"
                        :multiple="true"
                        placeholder="Select States..."
                >
                </ks-autocomplete>
                <ks-loader-line :show="loading_states"></ks-loader-line>
            </form-group>
            <form-group label="County" name="location_county">
                <ks-autocomplete
                    v-model="locationCounty"
                    @search="searchCounties"
                    selection-key="county_name"
                    placeholder="Select County..."
                    :cache-results="false"
                >
                    <template v-slot="{item}">
                        <div v-if="item.state_name" class="text-sm muted">{{item.state_name}}</div>
                        <span>
                            {{item.county_name}}
                        </span>
                    </template>
                </ks-autocomplete>
            </form-group>
            <form-group label="Zip" :name="'location_zip'">
                <input type="text" v-model="location_zip"/>
            </form-group>
        </div>
    </div>
</template>

<script>
    import keyBy from 'lodash/keyBy';

    // Filter Mixin
    import FilterMixin from '../../../Filters/mixins/FilterMixin';
    import {isString} from 'helpers/types.js';
    import Vault from 'helpers/vault.js';

    export default {
        name: 'LocationFilters',

        mixins: [FilterMixin],

        computed: {
            location_address: {
                get() {
                    let location_address = this.getFilterValue('location_address');
                    if ( location_address && location_address['location_address'] ) {
                        return this.getFilterValue('location_address')['location_address'];
                    } else {
                        return '';
                    }
                },

                set(val) {
                    this.setFilterValueTerm(val, 'location_address', 'Address');
                },
            },

            location_state: {
                get() {
                    let filter_obj = this.getFilterValue('location_state');
                    if ( filter_obj && isString(filter_obj['location_state']) ) {
                        return filter_obj['location_state'].split(',').map(state_id => {
                            return this.states.find(el => {
                                return el.state_id == state_id;
                            });
                        });
                    } else {
                        return [];
                    }
                },

                set(val) {
                    if ( !val || Object.keys(val).length < 1 ) {
                        this.removeFilter('location_state');
                        return;
                    }
                    let filter = {
                        location_state: val.map(el => el.state_id).join(','),
                        label: val.map(el => el.state).join(', '),
                    };
                    this.setFilterValue(filter, 'location_state', 'label', 'location_state', 'State');
                },
            },

            location_city: {
                get() {
                    let location_city = this.getFilterValue('location_city');
                    if ( location_city && location_city['location_city'] ) {
                        return this.getFilterValue('location_city')['location_city'];
                    } else {
                        return '';
                    }
                },

                set(val) {
                    this.setFilterValueTerm(val, 'location_city', 'City');
                },
            },

            location_zip: {
                get() {
                    let location_zip = this.getFilterValue('location_zip');
                    if ( location_zip && location_zip['location_zip'] ) {
                        return this.getFilterValue('location_zip')['location_zip'];
                    } else {
                        return '';
                    }
                },

                set(val) {
                    this.setFilterValueTerm(val, 'location_zip', 'Zip');
                },
            },

            locationCounty: {
                get() {
                    if ( this.getFilterValue('location_county') ) {
                        return this.getFilterValue('location_county');
                    }

                    return null;
                },

                set(val) {
                    if ( val ) {
                        this.setFilterValue(val, 'location_county', 'county_name', 'county_id', 'County');

                        return val;
                    }

                    this.removeFilter('location_county');
                }
            },
        },

        data() {
            return {
                loading: false,
                loading_states: false,
                states: [],
            };
        },

        mounted() {
            this.loadStates();
        },

        methods: {
            loadStates() {
                this.loading_states = true;

                let query_states = this.$route.query.states || [];

                Vault.get('countries', {with: 'states'}).then(({data}) => {
                    this.states = data.reduce((states, country) => {
                        return states.concat(country.states.map((state) => {
                            state.country_name = country.country_name;
                            return state;
                        }));
                    }, []);
                    if ( isString(query_states) ) {
                        query_states = [query_states];
                    }

                    let keyed_states = keyBy(this.states, 'state');
                    let filter_states = [];

                    for ( let i in query_states ) {
                        let query_state = query_states[i];

                        if ( keyed_states.hasOwnProperty(query_state) ) {
                            filter_states.push(keyed_states[query_state]);
                        }
                    }
                }).catch(() => {
                    this.$toast({
                        type: 'error',
                        message: 'Failed to fetch countries and state data.'
                    });
                }).then(() => {
                    this.loading_states = false;
                });
            },

            searchCounties({term, callback, page}) {
                callback(`counties?term=${term}&page=${page}`);
            },
        },
    }
</script>
