// External Dependencies
import Vue from 'vue';
import * as Sentry from '@sentry/browser';
import * as Integrations from '@sentry/integrations';

if ( import.meta.env.PROD ) {
    Sentry.init({
        environment: import.meta.env.VITE_APP_ENV,
        dsn: import.meta.env.VITE_SENTRY_LARAVEL_DSN,
        integrations: [
            new Integrations.Vue({
                Vue,
                attachProps: true,
            }),
        ],
        beforeSend(event, hint) {
            // prevent module preload errors from being sent
            const error = hint.originalException;
            if ( error && /TypeError:.+is undefined/.test(error.toString()) ) {
                return null;
            }

            return event;
        },
    });
}


import KickStart from 'kickstart-ui/dist/kickstart-ui.min';
import VueMask from 'v-mask';

// Internal Dependencies
import refresher from './helpers/refresher';
import {userCan, userCanGroup} from './helpers/permissions';

window.user_acted = false;
window.refreshing_token = false;

Vue.use(KickStart);
Vue.api.baseUrl(import.meta.env.VITE_API_URL);
Vue.use(VueMask);
Vue.prototype.$userCan = userCan;
Vue.prototype.$userCanGroup = userCanGroup;

import { initializeInterceptors } from './helpers/interceptors';
initializeInterceptors(Vue);

import './helpers/filters';
import './helpers/directives';

// Setup global components
import './Vault/UI';

// Watch for user actions and ping server to keep session active
refresher();
