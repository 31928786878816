import { mapActions, mapGetters } from 'vuex';
import CertificationTypes from '@Vault/Certifications/repositories/CertificationTypesRepository.js';

export default {
    computed: {
        ...mapGetters('certifications', [
            'types',
            'typesPaginator',
        ]),
    },

    data() {
        return {
            loading_types: false,
        };
    },

    methods: {
        ...mapActions('certifications', [
            'setCertificationTypes',
            'setTypesPaginator',
        ]),

        loadCertificationTypes(params) {
            this.loading_types = true;

            return CertificationTypes.list({
                    with: 'certificationsCount,monitorsCount',
                    page: this.page,
                    ...params
                })
                .then(({data, paginator}) => {
                    this.setCertificationTypes(data);
                    this.setTypesPaginator(paginator);
                })
                .finally(() => {
                    this.loading_types = false;
                });
        },
    },
}
