export default {
    props: {
        services: {
            type: Array,
            default: () => [],
        },
    },

    computed: {
        conditionsServiceItemData() {
            return {
                service_item_id: this.services.map(({service_item_id}) => service_item_id),
            };
        },
    }
}
