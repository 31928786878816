import { refresh } from './index.js';
import { routeIsAuthorized } from '../helpers/permissions.js';

const addRouterHooks = (router, store, refresh_url) => {
    router.beforeEach((to, from, next) => {
        // Save the route being navigated to
        const href = `/${refresh_url}${to.fullPath}`;
        store.dispatch('setRouteTo', href);

        if ( store.state.refresh_required ) {
            refresh(href);
            next(false);
            return;
        }

        if ( to.params?.prevent_scroll !== true ) {
            window.scrollTo(0, 0);
        }

        // If the app is not loaded then we can't check permissions
        // In that instance however it is checked in the App.vue component on initial load
        if ( !appLoaded() ) {
            next();
            return;
        }

        // Check if the user is authorized for the route
        if ( !routeIsAuthorized(to) ) {
            next({name: 'not-authorized'});
        }

        next();
    });

    let timer;
    router.afterEach((to, from) => {
        if ( !(to.fullPath === '/' && from.fullPath === '/') ) {
            window.user_acted = true;
        }
        clearTimeout(timer);
        timer = setTimeout(() => {
            if ( store.state.refresh_required ) {
                console.log('App requires a restart: new version (after)')
                window.location.reload();
            }
        }, 500);
    });

    /**
     * @returns {boolean}
     */
    function appLoaded() {
        return store.getters.app_loaded;
    }
};

export default addRouterHooks;
