<template>
    <ks-select
        dusk="country_select"
        :items="countries"
        label-key="country_name"
        selection-key="country_id"
        :placeholder="placeholder"
        name="country_id"
        v-model="selected"
        :single-deselect="true"
    >
        <template v-slot="props">
            {{props.item.country_name}}
        </template>
    </ks-select>
</template>

<script>
    // External Dependencies
    import {mapGetters, mapActions} from 'vuex';

    // Internal Dependencies
    import {isObject, isUndefined} from 'helpers/types';
    import CountriesMixin from '@Vault/Locations/components/mixins/CountriesMixin';

    export default {
        name: 'CountrySelect',

        mixins: [CountriesMixin],

        props: {
            value: {
                default: '1'
            },

            forceValues: {
                type: Boolean,
                default: false,
            },

            placeholder: {
                type: String,
                default: 'Select Country...'
            }
        },

        computed: {
            ...mapGetters('locations', [
                'countries',
            ]),

            selected: {
                get() {
                    if ( this.value !== null && isObject(this.value) && !isUndefined(this.value.filter_label) ) {
                        this.value.country_name = this.value.filter_label;
                    }

                    return this.value;
                },

                set(value) {
                    this.$emit('input', this.forceValues ? value?.country_id || value : value);
                }
            }
        },

        mounted() {
            if ( !this.countries || this.countries.length === 0 ) {
                this.loadAllCountriesWithStates();
            }

            if ( this.value ) {
                this.setCountryId(this.value);
            }
        },

        methods: {
            ...mapActions('locations', [
                'setCountryId',
            ]),
        },

        watch: {
            value() {
                this.setCountryId(this.value);
            }
        },

        components: {},
    }
</script>
