const AdminCustomerGroups = () => import('./components/AdminCustomerGroups.vue')
const AdminCustomerNotifications = () => import('./components/Notifications/AdminCustomerNotifications.vue');
const AdminNotificationTemplates = () => import('./components/NotificationTemplates/AdminNotificationTemplates.vue');
const NotificationTemplateList = () => import('./components/NotificationTemplates/NotificationTemplateList.vue');
const NotificationTemplateForm = () => import('./components/NotificationTemplates/NotificationTemplateForm.vue');
const AdminLockedContacts = () => import('./components/AdminLockedContacts.vue');

export default [
    {
        path: 'groups',
        component: AdminCustomerGroups,
        name: 'admin-customer-groups',
        props: true,
    },
    {
        path: 'locked-contacts',
        component: AdminLockedContacts,
        name: 'admin-locked-contacts',
        props: true,
        meta: {
            perms: ['admin_approve_contacts'],
        },
    },
    {
        path: 'notifications',
        component: AdminCustomerNotifications,
        name: 'admin-customer-notifications',
        props: true,
        meta: {
            perms: ['admin_notifications'],
        },
    },
    {
        path: 'notification-templates',
        component: AdminNotificationTemplates,
        meta: {
            perms: ['admin_notification_templates'],
        },
        children: [
            {
                path: '',
                name: 'admin-notification-templates',
                component: NotificationTemplateList,
                props: true,
            },
            {
                path: ':code',
                name: 'admin-notification-template-edit',
                component: NotificationTemplateForm,
                props: true,
            }
        ]
    }
];
