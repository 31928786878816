<template>
    <div class="flex jc-between">
        <div>
            <slot>
                <strong>{{item.name}}</strong> ({{item.user_defined_number}})
                <product-image-icon-tooltip
                    v-if="imageIcon"
                    :item="item"
                    class="ml-1 text-gray-400"
                ></product-image-icon-tooltip>
            </slot>

            <div class="text-xs">
                {{itemClass}}
            </div>

            <div v-if="description" class="text-xs muted">
                {{ description }}
            </div>

            <div v-if="productNumber" class="text-xs muted">
                Mfr. P/N: {{ productNumber }}
            </div>
        </div>

        <img v-if="!!item.image && !imageIcon" :src="'/service_item/' + this.item.service_item_id + '/files/' + this.item.image.file_id" :alt="item.image.name" class="block ml-2 h-12">
    </div>
</template>

<script>
    import { ellipsis } from 'kickstart-ui/lib/strings';

    import ProductImageIconTooltip from '@Vault/Admin/WorkOrder/components/ServiceItems/ProductImageIconTooltip.vue';

    export default {
        name: 'ProductDropdownSlot',

        props: {
            item: {
                required: true,
            },

            imageIcon: {
                type: Boolean,
                default: false,
            },

            fullDescription: {
                type: Boolean,
                default: false
            }
        },

        computed: {
            description() {
                if ( !this.item.description ) {
                    return null;
                }

                return this.fullDescription
                    ? this.item.description
                    : ellipsis(this.item.description, 50);
            },

            productNumber() {
                return this.fullDescription
                    ? this.item.product_number
                    : null;
            },

            itemClass() {
                return this.item.class_name ||
                    this.item.item_class?.item_class ||
                    this.item.item_class;
            },
        },

        components: {
            ProductImageIconTooltip,
        },
    }
</script>
