<template>
    <div>
        <ks-modal
            ref="download_modal"
            title="Download Inventory"
            min-width="400px"
        >
            <form-group>
                Exporting inventory records.
            </form-group>

            <ks-loader-spin :show="loading_templates" size="medium" height="200px"></ks-loader-spin>
            <template v-if="!loading_templates">
                <form-group label="Export Template" v-if="export_templates.length > 1">
                    <ks-select
                        name="export_template_id"
                        :items="export_templates"
                        label-key="export_template_name"
                        v-model="export_template"
                    >
                        <template v-slot:label="{value, label}">
                            {{value.export_template_name}} <span class="muted">({{value.format_extension}})</span>
                        </template>
                        <template v-slot:default="{item}">
                            {{item.export_template_name}} <span class="muted">({{item.format_extension}})</span>
                        </template>
                    </ks-select>
                </form-group>

                <div>
                    <table class="full-width my-2" style="border-spacing: 0;">
                        <tbody>
                            <export-template-progress-row
                                v-if="pending"
                                :pending="pending"
                                @succeeded="exportSuccess"
                                @file-ready="downloadFile"
                                @failed="exportFail"
                            ></export-template-progress-row>
                        </tbody>
                    </table>
                </div>

                <div slot="footer" v-if="export_templates.length > 1">
                    <vault-button :spin="!!(exporting || loading_pending_export)" @click.prevent="startExport">Export</vault-button>
                    <vault-button class="outline" @click.prevent="$refs.download_modal.close()">Cancel</vault-button>
                </div>
            </template>
        </ks-modal>
    </div>
</template>

<script>

    // Helpers
    import _cloneDeep from 'lodash/cloneDeep.js';

    // Repositories
    import Exports from '@Vault/Admin/Exports/repositories/ExportsRepository';

    // Components
    import ExportTemplateProgressRow from '@Vault/Admin/Exports/components/ExportTemplateProgressRow.vue';

    export default {
        name: 'InventoryDownload',

        data() {
            return {
                loading_templates: true,
                loading_pending_export: false,
                exporting: false,
                filters: {},
                export_templates: [],
                export_template: null,
                pending: null,
            }
        },

        methods: {
            download(filters) {
                this.$set(this, 'filters', _cloneDeep(filters));
                this.$refs.download_modal.open();
                this.findExportTemplates();
            },

            findExportTemplates() {
                this.loading_templates = true;

                Exports.get('templates', {
                        'export_code': 'inventory',
                    })
                    .then(({data}) => {
                        this.export_templates = data;

                        if ( data.length >= 1 ) {
                            this.export_template = data[0];
                        }

                        if ( data.length <= 1 ) {
                            this.startExport();
                        }
                    })
                    .catch(() => {
                        this.$toast({
                            message: 'Error fetching export templates.',
                            type: 'error',
                        });
                    })
                    .finally(() => {
                        this.loading_templates = false;
                    });
            },

            startExport() {
                this.loading_pending_export = true;
                if ( !this.export_template ) {
                    Exports.post(`auto-export/inventory`, {
                            ...this.filters,
                        })
                        .then(({data}) => {
                            this.$toast({
                                type: 'success',
                                message: 'Export started.',
                            });
                            this.pending = data.data.pending_export;

                        })
                        .catch((response) => {
                            this.$toast({
                                type: 'error',
                                message: 'There was an error generating export.',
                            });

                            this.resetForm();
                        })
                        .finally(() => {
                            this.loading_pending_export = false;
                        })

                    return;
                }

                Exports.post(this.export_template.export_template_id, {...this.filters})
                    .then(({data}) => {
                        this.$toast({
                            type: 'success',
                            message: 'Export started.',
                        });
                        this.pending = data.data.pending_export;
                    })
                    .catch((response) => {
                        this.$toast({
                            type: 'error',
                            message: 'There was an error generating export.',
                        });

                        this.resetForm();
                    })
                    .finally(() => {
                        this.loading_pending_export = false;
                    })
            },

            exportSuccess() {
                this.exporting = false;
            },

            exportFail() {
                this.exporting = false;
                this.file_url = false;
            },

            resetForm() {
                this.exporting = false;
                this.pending = null;
                this.file_url = null;
            },

            downloadFile(file_url) {
                if ( file_url ) {
                    window.open(file_url, '_blank');
                }
            }
        },

        components: {
            ExportTemplateProgressRow,
        }
    }
</script>
