<template>
    <ul :class="{'nav-links': true, 'sub-links': subMenu}" v-if="routes.length">
        <li v-for="route in routes" v-if="showRouteTree(route)">
            <template v-if="defaultRoute(route)">
                <router-link :to="{ name: defaultRoute(route) }">
                    <span v-if="hasIcon(route)"><component :is="route.meta.icon" size="16"></component></span>
                    {{ getRouteMetaTitle(route) || getRouteName(route.children[0]) | ucwords}}
                </router-link>
            </template>
            <template v-else>
                <router-link :to="{ name: route.name }" :exact="route.meta.exact || false">
                    <span v-if="hasIcon(route)"><component :is="route.meta.icon" size="16"></component></span>
                    {{getRouteName(route) | ucwords}}
                </router-link>
            </template>
            <!-- Sub nav -->
            <nav-links
                v-if="hasChildren(route)"
                :routes="filterRouteGroups(route.children)"
                :group="group"
                :sub-menu="true"
            ></nav-links>
        </li>
    </ul>
</template>

<script>
    // Store
    import {mapGetters, mapActions} from 'vuex';

    // Internal Dependencies
    import SubMenu from './SubMenu.vue';
    import * as Icons from 'Icons';

    export default {
        name: 'NavLinks',

        props: {
            routes: {
                required: true
            },

            group: {
                type: String,
                default: 'main'
            },

            subMenu: {
                type: Boolean,
                default: false
            }
        },

        computed: {
            ...mapGetters(['user_type']),

            ...mapGetters('config', [
                'configs'
            ]),
        },

        methods: {
            ...mapActions('config', [
                'loadEntityConfigs'
            ]),
            filterRouteGroups(r) {
                return r.filter((route) => {
                    if ( !route.meta ) {
                        return false;
                    }

                    if ( Array.isArray(route.meta.perms) ) {
                        if ( !this.$userCan.apply(this, route.meta.perms) ) {
                            return false;
                        }
                    }

                    if ( Array.isArray(route.meta.user_types) && route.meta.user_types.indexOf(this.user_type) === -1 ) {
                        return false;
                    }

                    return route.meta.group ? route.meta.group == this.group : false;
                });
            },
            hasChildren(route) {
                if ( typeof route.children == 'undefined' ) {
                    return false;
                }

                return true;
            },
            getRouteMetaTitle(route) {
                if ( route.meta && route.meta.title ) {
                    return route.meta.title;
                }

                return null;
            },
            getRouteName(route) {
                let meta_title = this.getRouteMetaTitle(route);

                return meta_title || route.name;
            },
            defaultRoute(route) {
                if ( !this.hasChildren(route) ) {
                    return false;
                }

                if ( route.children[0].path == '' ) {
                    return route.children[0].name;
                }

                return false;
            },
            showRouteTree(route) {
                if ( route.meta.config ) {
                    if ( !this.configs[route.meta.config] ) {
                        return false;
                    }
                }

                return true;
            },
            hasIcon(route) {
                if ( typeof route.meta !== 'undefined' ) {
                    return typeof route.meta.icon !== 'undefined';
                }

                return false;
            }
        },

        components: {
            SubMenu,
            ...Icons
        },
    }
</script>
