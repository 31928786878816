<template>
    <div class="row">
        <router-view class="sm-12"></router-view>
    </div>
</template>

<script>
    // Internal Dependencies
    import BreadCrumbs from 'BreadCrumbs';

    export default {
        name: 'AdminReports',

        mixins: [
            BreadCrumbs,
        ],

        beforeMount() {
            this.pushBreadcrumb({
                title: 'Reports',
                to: {
                    name: 'admin-reports'
                },
            })
        },
    }
</script>
