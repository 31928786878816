<template>
    <div class="row">
        <div class="col tab-12">
            <router-view></router-view>
        </div>
    </div>
</template>

<script>

    import BreadCrumbs from 'BreadCrumbs';

    export default {
        name: 'AdminWorkReasons',

        mixins: [BreadCrumbs],

        beforeMount() {
            this.pushBreadcrumb({
                title: 'Work: Service Reasons',
                to: {
                    name: 'admin-work-reasons'
                }
            })
        }
    }
</script>
