<template>
	<div>
        <page-header>
            <page-breadcrumb></page-breadcrumb>
        </page-header>

        <div class="row">
            <router-view></router-view>
        </div>
	</div>
</template>


<script>
    import BreadCrumbs from '../../BreadCrumbs';

	export default {
		name: 'Customers',

        mixins: [BreadCrumbs],

        data() {
		    return {}
        },

        beforeMount() {
		    this.resetBreadcrumb({
                title: 'Customers',
                to: {
                    name: 'customers'
                }
            });
        },
	}
</script>
