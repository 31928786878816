<template>
    <ks-select
        dusk="state_select"
        ref="select"
        :items="states"
        label-key="state_name"
        placeholder="Select state..."
        name="state_id"
        :value="value"
        @input="$emit('input', $event)"
        :force-values="true"
        group-by="country_name"
        :single-deselect="singleDeselect"
    >
        <template v-slot="props">
            {{props.item.state_name}}
        </template>
    </ks-select>
</template>

<script>
import Countries from '@Vault/Locations/CountryRepository';

export default {
    name: "StateGroupSelect",

    props: {
        value: {},
        singleDeselect: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            states: []
        }
    },

    mounted() {
        this.getStatesList();
    },

    methods: {
        getStatesList() {
            this.loading = true;

            Countries.list({
                with: 'states'
            }).then(({data}) => {
                this.states = data.map((country) => {
                    return country.states.map((state) => {
                        return {
                            ...state,
                            country_name: country.country_name,
                        }
                    })
                }).flat(1)

            }).catch((e) => {
                console.error(e);
                this.$toast({
                    type: 'error',
                    message: 'Failed retrieving countries with states'
                });
            }).finally(() => {
                this.loading = false;
            })
        }
    }
}
</script>