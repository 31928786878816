import { mapGetters } from 'vuex';

export default {
    props: {
        namespace: {
            type: String,
            required: true,
        },

        ignoredFilters: {
            type: Array,
            default: () => [],
        },
    },

    computed: {
        ...mapGetters('search_filters', [
            'filters',
            'dependent_filters'
        ]),

        allFilters() {
            return Object.fromEntries(this.filteredFilters);
        },

        filteredFilters() {
            return Object.entries(this.filters[this.namespace] || {})
                .filter(([name,]) => name !== 'term' && !this.ignoredFilters.includes(name));
        },

        dependentFilters() {
            if ( !this.dependent_filters?.[this.namespace] ) {
                return {};
            }

            return this.dependent_filters[this.namespace];
        },
    },
}
