const Invoices = () => import('./components/Invoices.vue');
const InvoiceList = () => import('./components/InvoiceList.vue');
const InvoiceForm = () => import('./components/InvoiceForm.vue');

export default [
    {
        path: 'invoices',
        component: Invoices,
        props: true,
        meta: {
            group: 'main',
            perms: ['invoice_manage'],
        },
        children: [
            {
                path: '',
                component: InvoiceList,
                name: 'invoice-list',
                props: true,
                meta: {
                    title: 'Invoices',
                    perms: ['invoice_manage'],
                }
            },
            {
                path: 'create',
                component: InvoiceForm,
                name: 'invoice-create',
                props: true,
                meta: {
                    title: 'Create Invoice',
                    back: 'invoice-list',
                    perms: ['invoice_manage'],
                }
            },
            {
                path: ':invoice_number/edit',
                component: InvoiceForm,
                name: 'invoice-edit',
                props: true,
                meta: {
                    title: 'Edit Invoice',
                    back: 'invoice-list',
                    perms: ['invoice_manage'],
                }
            }
        ]
    }
]
