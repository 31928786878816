<template>
    <ks-modal id="edit-certification-type-modal" ref="modal" :title="modalTitle" @close="close(false)" min-width="480px" max-width="480px">
        <div>
            <form-group label="Name" name="name" :errors="errors" :required="true">
                <input v-model="model.name" name="name" type="text">
            </form-group>

            <form-group label="Issuing Organization" name="certification_authority" :errors="errors">
                <input v-model="model.certification_authority" name="certification_authority" type="text">
                <div class="text-xs">
                    <span class="muted">(Optional)</span> Enter the organization responsible for issuing the certification.  Leave blank for generic certification types or if various organizations issue it (e.g. driver's license).
                </div>
            </form-group>

            <div class="row row-collapse">
                <form-group label="Country" name="state_id" :errors="errors" class="sm-6">
                    <country-select v-model="model.country_id"></country-select>
                </form-group>
                <form-group label="State" name="state_id" :errors="errors" class="sm-6">
                    <state-dropdown v-model="model.state_id" :states="countryStates" :force-values="true" group-by="" :class="{ 'disabled': !model.country_id }"></state-dropdown>
                </form-group>
                <div class="-mt-4 mb-4 sm-12 text-xs">
                    <span class="muted">(Optional)</span> Select a country/state this certification type is associated with or applicable to.
                </div>
            </div>

            <form-group label="Company Certificate Number" name="company_certificate_number" :errors="errors">
                <input v-model="model.company_certificate_number" name="company_certificate_number" type="text">
            </form-group>

            <form-group label="Notify At" name="notify_at" :errors="errors">
                <input v-model="model.notify_at" name="notify_at" type="text">
                <div class="text-xs">
                    The date interval(s) prior to a certification expiring at which to notify users (e.g. "3M 6W 10D"
                    will send notifications at three months out, at six weeks out, and at ten days out).
                </div>
            </form-group>

            <form-group label="" name="notify_employee" :error="errors">
                <ks-checkbox v-model="model.notify_employee" value="1" label="Notify Employee"></ks-checkbox>
            </form-group>

            <form-group label="Monitors" name="monitors" :errors="errors" class="mb-6">
                <ks-autocomplete
                    v-model="model.monitors"
                    name="monitors"
                    :cache-results="false"
                    :multiple="true"
                    :paginated="true"
                    placeholder="Search users"
                    selection-key="display_name"
                    @search="searchUsers"
                ></ks-autocomplete>
                <div class="text-xs">
                    Who should receive notifications for this certification type
                </div>
            </form-group>

            <form-group label="Comments" name="comments" :errors="errors">
                <textarea v-model="model.comments" name="comments" rows="3" maxlength="255"></textarea>
            </form-group>

        </div>
        <div slot="footer">
            <vault-button type="save" :spin="saving" @click="save">
                Save Certification Type
            </vault-button>

            <button class="button outline" @click.prevent="close">Cancel</button>
        </div>
    </ks-modal>
</template>

<script>
    import { mapGetters } from 'vuex';

    import CertificationTypes from '@Vault/Certifications/repositories/CertificationTypesRepository.js';
    import Users from '@Vault/Users/repositories/UsersRepository.js';
    import StateDropdown from 'UI/StateDropdown.vue';
    import CountrySelect from '@Vault/Locations/components/CountrySelect.vue';

    const blank_certification_type = /** @type {CertificationType} */ {
        certification_type_id: null,
        name: null,
        certification_authority: null,
        state_id: null,
        notify_at: null,
        monitors: [],
        notify_employee: 0,
    };

    export default {
        name: 'EditCertificationTypeModal',

        computed: {
            ...mapGetters('locations', [
                'all_states',
            ]),

            modalTitle() {
                return this.updating
                    ? 'Edit Certification Type'
                    : 'Add Certification Type';
            },

            countryStates() {
                return this.all_states.filter(({country_id}) => country_id == this.model.country_id);
            },
        },

        data() {
            return {
                updating: false,
                saving: false,
                model: /** @type {CertificationType} */ {...blank_certification_type},
                errors: {},
            };
        },

        methods: {
            reset() {
                this.updating = false;
                this.saving = false;
                this.model = {...blank_certification_type, monitors: []}
                this.errors = {};
            },

            /** @param {?CertificationType} type */
            open(type = null) {
                if ( !!type ) {
                    this.updating = true;
                    this.model = {
                        ...blank_certification_type,
                        ...type,
                    };

                    this.loadMonitors();
                }

                this.$refs.modal.open();
            },

            close(close_modal = true) {
                this.reset();
                this.$emit('close');

                if ( close_modal ) {
                    this.$refs.modal.close();
                }
            },

            loadMonitors() {
                CertificationTypes.get(this.model.certification_type_id + '/monitors')
                    .then(({data}) => {
                        this.model.monitors = data;
                    })
                    .catch(error => {
                        console.error(error);
                    });
            },

            createOrUpdate() {
                let params = {
                    ...this.model,
                    state_id: this.model.state_id || null,
                    monitors: this.model.monitors.map(({user_id}) => user_id),
                };
                delete params.state;

                return this.updating
                    ? CertificationTypes.update(this.model.certification_type_id, params)
                    : CertificationTypes.create(params);
            },

            save() {
                this.saving = true;

                this.createOrUpdate()
                    .then(({data}) => {
                        this.$toast({
                            type: 'success',
                            message: 'Saved certification type',
                        });

                        this.$emit('saved', data);
                        this.close();
                    })
                    .catch(({errors, message}) => {
                        this.errors = errors;
                        this.$toast({
                            type: 'error',
                            message,
                        });
                    })
                    .finally(() => {
                        this.saving = false;
                    });
            },

            searchUsers({term, callback, page}) {
                callback(
                        Users.endpointUri({
                            search_name: term,
                            page,
                            exclude_users: this.model.monitors.map(({user_id}) => user_id),
                            user_type: ['account_admin', 'admin', 'field'],
                        })
                    )
                    .catch((error) => {
                        console.error('USERS:ERR: ', error);
                    });
            },
        },

        components: {
            CountrySelect,
            StateDropdown,
        },
    }
</script>
