const TaskBase = () => import(/* webpackChunkName: "tasks" */ './components/TaskBase.vue');
const TaskDashboard = () => import(/* webpackChunkName: "tasks" */ './components/TaskDashboard.vue');

export default [
    {
        path: '/tasks',
        component: TaskBase,
        children: [
            {
                path: '',
                component: TaskDashboard,
                name: 'task-dashboard',
                meta: {
                    title: 'Tasks',
                    user_types: ['admin', 'account_admin'],
                    perms: ['task_view', 'task_manage', 'task_create_work'],
                }
            }
        ]
    }
];