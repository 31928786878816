<template>
    <div class="ks-badge" :class="nextCalibrationClass" title="Next Calibration">
        <vault-icon v-if="showIcon" :icon="icons.calibration" class="mr-2"></vault-icon>
        {{nextCalibrationDate | date}}
    </div>
</template>

<script>
    import {diffInDays} from 'kickstart-ui/lib/dates';
    import {faCalendar} from '@fortawesome/pro-duotone-svg-icons/faCalendar';

    export default {
        name: 'EquipmentNextCalibrationBadge',

        props: {
            equipment: {
                required: true,
            },
            showIcon: {
                type: Boolean,
                default: true
            }
        },

        data() {
            return {
                icons: {
                    calibration: faCalendar,
                }
            }
        },

        computed: {
            nextCalibrationDate() {
                return this.equipment?.calibration?.next_calibration_date || null;
            },

            nextCalibrationClass() {
                if ( !this.nextCalibrationDate ) {
                    return {};
                }

                let diff = diffInDays(new Date, this.nextCalibrationDate, 'Y-m-d')

                return {
                    'warning': diff <= 30 && diff >= 0,
                    'error': diff <= 0,
                    'muted': diff >= 30
                }
            }
        }
    };
</script>