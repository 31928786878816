import Repository from '../../../Repository';

export class ExportsRepository extends Repository {
    constructor() {
        super();
        this.resource = 'exports';
    }
}

const Exports = new ExportsRepository();

export default Exports;
