export default {
    computed: {
        screenWidth() {
            if ( this.viewport_width < 480 ) {
                return 'xs'; // nonstandard
            }

            // kickstart breakpoints
            if ( this.viewport_width <= 768 ) {
                // TODO: update condition above if ks breakpoint gets fixed
                return 'sm';
            }
            if ( this.viewport_width < 1024 ) {
                return 'tab';
            }
            if ( this.viewport_width < 1224 ) {
                return 'med';
            }
            if ( this.viewport_width < 1400 ) {
                return 'lg';
            }
            return 'xlg';
        },
    },

    data() {
        return {
            viewport_width: window.innerWidth,
        };
    },

    beforeMount() {
        window.addEventListener('resize', this.__onResize);
    },

    beforeDestroy() {
        window.removeEventListener('resize', this.__onResize);
    },

    methods: {
        __onResize() {
            this.onResize();

            this.viewport_width = window.innerWidth;
        },

        onResize() {
            // override in using component if needed
        },
    },
}
