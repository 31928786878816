<template>
    <router-view></router-view>
</template>

<script>
    import BreadCrumbs from '@Vault/BreadCrumbs';

    export default {
        name: 'AdminWorkProductsServices',

        mixins: [BreadCrumbs],

        beforeMount() {
            this.pushBreadcrumb('Work: Products/Services');
        },
    }
</script>
