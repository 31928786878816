import { mapActions, mapGetters } from 'vuex';

export default {
    computed: {
        ...mapGetters('locations', [
            'countries',
            'counties',
        ]),
    },

    methods: {
        ...mapActions('locations', [
            'loadAllCountriesWithStates',
        ]),
    }
}
