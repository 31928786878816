<template>
    <component :is="tag">
        <component :is="columnTag" :colspan="colspan">
            <progress-bar :status="status" :value="value">
                <div slot="text" v-if="file_link"><a :href="file_link" target="_blank">Download File</a></div>
                <div slot="text" v-else>{{ value }}%</div>
            </progress-bar>
        </component>
    </component>
</template>

<script>
    const STATUS_FAILED = "failed";
    const STATUS_SUCCEEDED = "complete";
    const STATUS_IN_PROGRESS = "progress";
    const STATUS_PENDING = "pending";

    import ProgressBar from 'UI/ProgressBar.vue';
    import EventHandler from 'helpers/EventHandler';
    import Exports from '../repositories/ExportsRepository';

    export default {
        name: 'ExportTemplateProgressRow',

        props: {
            colspan: {
                type: Number,
                default: 1,
            },

            pending: {
                required: true,
                type: Object
            },

            tag: {
                type: String,
                default: 'tr',
            }
        },

        computed: {
            file_event() {
                return `export-file-${this.pending.export_pending_id}`;
            },
            columnTag() {
                return this.tag === 'tr' ? 'td' : 'div';
            }
        },

        data() {
            return {
                value: 0,
                status: STATUS_PENDING,
                file_link: null,
            };
        },

        mounted() {
            EventHandler.channel('user')
                .listen(this.pending.event_name, (data) => {
                    if (!data.hasOwnProperty('progress')) {
                        console.error("Malformed event message", data);
                    } else {
                        this.status = STATUS_IN_PROGRESS;
                        this.value = parseFloat(data.progress);

                        if ( this.value >= 100 ) {
                            this.updateStatus();
                        }
                    }
                }).listen(this.file_event, (data) => {
                    if (data.hasOwnProperty('file_id') && data.hasOwnProperty('export_history_id')) {
                        this.file_link = `/export_history/${data.export_history_id}/files/${data.file_id}?stream=1`;
                        this.$emit('file-ready', this.file_link);
                    } else {
                        console.error('file_id or export_history_id missing');
                    }

                    EventHandler.channel('user').unlisten(this.file_event);
                });
        },

        methods: {
            updateStatus() {
                Exports.get(`pending/${this.pending.export_pending_id}`)
                    .then(({data}) => {
                        if (data.status_code == 1) {
                            this.$emit('succeeded', this.file_link);
                            this.status = STATUS_SUCCEEDED;
                        } else if (data.status_code == 2) {
                            this.status = STATUS_FAILED;
                            this.$emit('failed');
                        } else {
                            return;
                        }

                        EventHandler.channel('user').unlisten(this.pending.event_name);
                    });
            },
        },

        components: {
            ProgressBar,
        },
    }
</script>
