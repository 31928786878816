import customers from '../Vault/Customers/customers.routes'
import work from '../Vault/WorkOrder/work.routes';
import admin from '../Vault/Admin/admin.routes';
import registration from '../Vault/Registration/registration.routes';
import users from '../Vault/Users/users.routes';
import errors from './errors.routes';
import forms from '../Vault/Forms/forms.routes.js';
import inventory from '../Vault/Inventory/inventory.routes.js';
import reference_documents from '../Vault/ReferenceDocuments/reference-documents.routes.js';
import timesheets from '../Vault/Timesheets/timesheets.routes.js';
import tasks from '../Vault/Tasks/tasks.routes.js';

const NotFound = () => import('../Vault/StaticPages/Errors/NotFound.vue');
const Dashboard = () => import('../Vault/Dashboard/components/Dashboard.vue');
const DashboardBase = () => import('../Vault/Dashboard/components/DashboardBase.vue');
const FieldInventory = () => import('../Vault/Inventory/components/FieldInventory.vue');
const UserDirectory = () => import('../Vault/UserDirectory/components/UserDirectory.vue');


export default [
    {
        path: '/dashboard',
        component: DashboardBase,
        meta: {
            title: 'Dashboard',
            group: 'main',
            icon: 'IconHome',
            permissions: [],
        },
        children: [
            {
                path: '',
                component: Dashboard,
                name: 'dashboard',
            },
            {
                path: '/fleet-inventory',
                component: FieldInventory,
                name: 'fleet-inventory',
                meta: {
                    group: 'main',
                    title: 'Fleet/Inventory',
                    user_types: ['field'],
                    config: 'enable_inventory',
                },
            },
        ],
    },
    ...errors,
    ...admin,
    ...customers,
    ...work,
    ...forms,
    ...registration,
    ...users,
    ...inventory,
    ...timesheets,
    ...reference_documents,
    ...tasks,
    {
        path: '/directory',
        component: UserDirectory,
        name: 'user-directory',
        meta: {
            title: 'UserDirectory',
        },
    },
    {
        path: '*',
        component: NotFound,
        name: '404',
    },
]
