<template>
    <div id="saved-search-filters" class="relative">
        <vault-button
            :class="buttonClass"
            @click.stop="toggleMenu"
            @keyup.enter.prevent="toggleMenu"
            @keyup.esc.prevent="hideMenu"
        >
            <vault-icon :icon="icons.star" class="mx-auto">Saved Searches</vault-icon>
        </vault-button>

        <div v-show="menu_open" ref="savedSearchMenu" class="absolute shadow bg-white text-sm" style="z-index:50;min-width:150px;max-width:300px;">
            <a
                href="#"
                title="Save current search criteria"
                class="flex align-center py-2 pl-2 pr-3 hover:bg-blue-600 hover:text-white"
                :class="{ 'disabled': !canSaveSearch }"
                @click.prevent="showSaveSearchModal"
            >
                <div class="flex align-center jc-center w-4"><vault-icon :icon="icons.plus"></vault-icon></div>
                <div class="ml-1.5">Save search</div>
            </a>
            <router-link
                v-if="saved_searches.length > 0"
                :to="{ name: 'user-profile', query: { tab: 'searches' } }"
                class="flex align-center py-2 pl-2 pr-3 nowrap hover:bg-blue-600 hover:text-white"
            >
                <div class="flex align-center jc-center w-4"><vault-icon :icon="icons.manage"></vault-icon></div>
                <div class="ml-1.5">Manage saved searches</div>
            </router-link>

            <a
                v-for="(search, index) in saved_searches"
                :key="search.saved_search_id"
                href="#"
                class="flex align-center py-2 pl-2 pr-3 hover:bg-blue-600 hover:text-white"
                :class="{ 'separator': index === 0 }"
                @click.prevent="applySavedSearchFilters(search)"
            >
                <div class="flex align-center jc-center w-4">
                    <icon-saved-search-public v-if="search.public" title="Available to all users within the organization"></icon-saved-search-public>
                </div>
                <div class="ml-1.5 ellipsis">{{ search.name }}</div>
            </a>
            <span v-if="saved_searches.length === 0" href="#" class="flex py-1.5 px-3 italic separator muted">
                No saved searches
            </span>
        </div>

        <ks-modal ref="saveSearchModal" title="Save search criteria" min-width="480px" max-width="600px">
            <div class="text-sm">This will save the current search criteria.</div>

            <search-filters-display
                :filters="allFilters"
                :term="term"
                :read-only="true"
                class="mt-2"
            ></search-filters-display>

            <form-group label="Name" name="name" :errors="errors" class="mt-4 mb-0">
                <input v-model="name" type="text" class="text-sm">
            </form-group>

            <div v-if="active_saved_search && active_saved_search.user_id === user_id" class="mt-2 text-sm">
                <ks-checkbox v-model="overwrite" :value="true" :switch-style="true">
                    Overwrite "{{ active_saved_search.name }}"?
                </ks-checkbox>
            </div>

            <div slot="footer" :class="{ 'flex jc-between align-center': canSavePublic }">
                <ks-checkbox v-if="canSavePublic" v-model="public" :value="true" :switch-style="true" class="text-sm">
                    Make available to all users
                </ks-checkbox>
                <div>
                    <vault-button type="save" @click="saveCurrentSearch">Save</vault-button>
                    <vault-button class="outline" @click="$refs.saveSearchModal.close()">Cancel</vault-button>
                </div>
            </div>
        </ks-modal>
    </div>
</template>

<style scoped>
    .separator {
        border-top: 1px solid #cccccc;
    }
</style>

<script>
    // External dependencies
    import { mapGetters } from 'vuex';
    import Popper from 'popper.js';
    import _isEmpty from 'lodash/isEmpty';
    import _isEqual from 'lodash/isEqual';
    import _cloneDeep from 'lodash/cloneDeep';
    import { faStar } from '@fortawesome/pro-solid-svg-icons/faStar';
    import { faPlus } from '@fortawesome/pro-regular-svg-icons/faPlus';
    import { faUserCog } from '@fortawesome/pro-regular-svg-icons/faUserCog';

    // Helpers, repositories, and mixins
    import SavedSearches from '@Vault/SavedSearches/repositories/SavedSearchRepository.js';
    import ActiveFiltersMixin from '@Vault/Filters/mixins/ActiveFiltersMixin.js';

    // Components
    import ActiveSearchFilters from '@Vault/Filters/components/ActiveSearchFilters.vue';
    import IconSavedSearchPublic from 'Icons/IconSavedSearchPublic.vue';
    import SearchFiltersDisplay from '@Vault/Filters/components/SearchFiltersDisplay.vue';

    export default {
        name: 'SavedSearchFilters',

        mixins: [ActiveFiltersMixin],

        props: {
            buttonClass: {
                type: String,
                default: 'text-normal w-8 px-0',
            },
        },

        computed: {
            ...mapGetters([
                'user_id',
            ]),

            apiNamespace() {
                switch ( this.namespace ) {
                    case 'work_orders':
                    case 'work_dashboard':
                        return 'work';

                    case 'work_visits':
                        return 'work_visit';

                    case 'certifications':
                        return 'certification';

                    case 'invoice_list':
                        return 'invoice';

                    case 'location_list':
                        return 'location';

                    case 'tasks':
                        return 'task';

                    case 'dashboard':
                        return 'dashboard';

                    default:
                        return null;
                }
            },

            allFiltersWithTerm() {
                let filters = _cloneDeep(this.allFilters);
                if ( this.term ) {
                    filters.term = this.term;
                }
                return filters;
            },

            term() {
                return this.$route.query?.term || this.allFilters.term?.filter_key || null;
            },

            isFiltered() {
                return !_isEmpty(this.allFiltersWithTerm);
            },

            dirty() {
                if ( !this.active_saved_search ) {
                    return false;
                }

                return !_isEqual(this.active_saved_search.data, this.allFiltersWithTerm);
            },

            canSaveSearch() {
                // not currently filtering data = cannot save
                if ( !this.isFiltered ) {
                    return false;
                }

                // active saved search not set (i.e. not currently filtered by saved search criteria) = can save
                if ( !this.active_saved_search ) {
                    return true;
                }

                // current filters are the same as the active saved search = cannot save
                if ( !this.dirty ) {
                    return false;
                }

                // saved search is owned by current user, or is public = can save
                return this.active_saved_search.user_id === this.user_id || this.active_saved_search.public;
            },

            canSavePublic() {
                return this.$userCan('admin_saved_search_create_public');
            },
        },

        data() {
            return {
                menu_open: false,
                saved_searches: /** @type {SavedSearch[]} */ [],
                active_saved_search: null,
                icons: {
                    star: faStar,
                    plus: faPlus,
                    manage: faUserCog,
                },
                overwrite: false,
                errors: {},
                name: '',
                public: false,
                popover: null,
            };
        },

        mounted() {
            SavedSearches.list({ namespace: this.apiNamespace })
                .then(({data}) => {
                    this.saved_searches = data;
                })
                .catch((error) => {
                    console.error(error);
                    this.$toast({
                        type: error,
                        message: 'Error loading saved searches',
                    });
                });

            this.$nextTick(() => {
                this.initPopover();
            });

            document.addEventListener('click', this.hideMenu);
        },

        beforeDestroy() {
            document.removeEventListener('click', this.hideMenu);
        },

        methods: {
            initPopover() {
                this.popover = new Popper(this.$el, this.$refs.savedSearchMenu, {
                    placement: 'bottom-start',
                    removeOnDestroy: true,
                    modifiers: {
                        preventOverflow: {
                            boundariesElement: 'viewport',
                            padding: 20,
                            priority: ['left', 'right']
                        },
                        flip: {
                            behavior: 'clockwise'
                        }
                    }
                });
            },

            toggleMenu() {
                this.menu_open = !this.menu_open;

                if ( this.menu_open ) {
                    this.$emit('menu-open');
                    this.$nextTick(() => {
                        this.popover.update();
                    });
                }
            },

            hideMenu() {
                this.menu_open = false;
            },

            showSaveSearchModal() {
                // set up modal
                if ( this.active_saved_search ) {
                    this.name = this.active_saved_search.name;
                    this.public = this.active_saved_search.public;
                } else {
                    this.name = '';
                    this.public = false;
                }
                this.overwrite = false;

                this.menu_open = false;
                this.$refs.saveSearchModal.open();
            },

            saveCurrentSearch() {
                this.saving = true;

                this.createOrUpdate()
                    .then(() => {
                        this.$refs.saveSearchModal.close();
                    })
                    .catch(({errors}) => {
                        this.errors = errors;
                        this.$toast({
                            type: 'error',
                            message: 'Error saving search criteria'
                        });
                    })
                    .finally(() => {
                        this.saving = false;
                    });
            },

            createOrUpdate() {
                let params = {
                    user_id: this.user_id,
                    namespace: this.apiNamespace,
                    name: this.name,
                    public: this.$userCan('admin_saved_search_create_public') && this.public,
                    data: this.allFiltersWithTerm,
                };

                if (
                    // if we don't have a saved search loaded
                    !this.active_saved_search ||
                    // if the saved search is public and doesn't belong to the user
                    (this.active_saved_search.public && this.active_saved_search.user_id !== this.user_id) ||
                    // if the saved search belongs to the user and overwrite is not checked
                    (this.active_saved_search.user_id === this.user_id && !this.overwrite)
                ) {
                    return SavedSearches.create(params)
                        .then(({data}) => {
                            this.saved_searches.push(data.data);
                            this.$toast({
                                type: 'success',
                                message: 'Saved search criteria'
                            });
                        });
                }

                return SavedSearches.update(this.active_saved_search.saved_search_id, {
                        ...this.active_saved_search,
                        ...params
                    })
                    .then(({data}) => {
                        let index = this.saved_searches.findIndex(({saved_search_id}) => saved_search_id === data.saved_search_id);
                        this.saved_searches.splice(index, 1, data.data);
                        this.active_saved_search = data.data;

                        this.$toast({
                            type: 'success',
                            message: 'Updated search criteria'
                        });
                    });
            },

            applySavedSearchFilters(search, merge = false) {
                this.active_saved_search = search;
                this.$emit('apply', search.data, merge);
                this.menu_open = false;
            },
        },

        watch: {
            allFiltersWithTerm(filters) {
                if ( !this.active_saved_search ) {
                    this.active_saved_search = this.saved_searches.find(({data}) => _isEqual(data, filters)) || null;
                }
            },
        },

        components: {
            SearchFiltersDisplay,
            ActiveSearchFilters,
            IconSavedSearchPublic,
        },
    }
</script>
