// External Dependencies
import {object_get} from 'kickstart-ui/lib/objects.js';

export default {
    props: {
        formModal: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            // Request flags which will be set while the respective actions are executing.
            model_form_loading: false,
            model_form_saving: false,
            model_form_deleting: false,
            
            toast_messages: true,
            model_name: 'item',
            repository: null,
            errors: {},
            model_id: null,
            model_modal: null,
            model: {}
        }
    },

    computed: {
        model_name_lowercase() {
            return this.model_name.toLowerCase();
        }
    },

    created() {
        this.initModal();
    },

    methods: {
        initModal() {
            // If this form component is inside a FormModal,
            // we need to let the parent hook into this mixin methods.
            if (this.formModal) {
                this.$parent.$parent.registerForm(this);
                this.model_modal = this.$parent.$parent;
            }
        },

        fetchModel(model_id, request = {}) {
            this.$emit('form-loading');
            this.model_form_loading = true;

            this.model_id = model_id;

            return this.repository.get(this.model_id, request)
                .then(response => {
                    this.model = response.data;
                    this.$emit('form-loaded');
                    this.model_form_loading = false;

                    return response;
                })
                .catch(response => {
                    this.$emit('form-load-error', response);
                    this.model_form_loading = false;

                    if (this.toast_messages) {
                        this.$toast({
                            type: 'error',
                            message: `Error getting ${this.model_name_lowercase} info.`,
                        });
                    }

                    console.error(this.model_name, response);

                    return Promise.reject(response);
                });
        },

        createModel(form_data = null) {
            this.$emit('saving');
            this.model_form_saving = true;

            if ( this.stepThrough ) {
                this.model.is_billing = 0;
            }
            
            return this.repository.post('', form_data || this.model)
                .then(response => {
                    this.model_form_saving = false;
                    if (this.toast_messages) {
                        this.$toast({
                            type: 'success',
                            message: `${this.model_name} added.`,
                        });
                    }

                    this.$emit('saved', response.data);

                    return response;
                })
                .catch((response) => {
                    this.$emit('save-error', response);
                    this.model_form_saving = false;

                    if (this.toast_messages) {
                        this.$toast({
                            type: 'error',
                            message: `Error creating ${this.model_name_lowercase}.`,
                        });
                    }

                    this.errors = response.errors;

                    console.error(this.model_name, response);

                    return Promise.reject(response);
                });
        },

        updateModel(form_data = null) {
            this.$emit('saving');
            this.model_form_saving = true;

            return this.repository.put(this.model_id, form_data || this.model)
                .then(response => {
                    this.model_form_saving = false;
                    if (this.toast_messages) {
                        this.$toast({
                            type: 'success',
                            message: `${this.model_name} updated.`,
                        });
                    }

                    this.$emit('saved', response.data);

                    return response;
                })
                .catch((response) => {
                    this.$emit('save-error', response);
                    this.model_form_saving = false;

                    if (this.toast_messages) {
                        this.$toast({
                            type: 'error',
                            message: `Error updating ${this.model_name_lowercase}.`,
                        });
                    }

                    this.errors = response.errors;

                    console.error(this.model_name, response);

                    return Promise.reject(response);
                });
        },

        deleteModel(model_id) {
            this.$emit('deleting');
            this.model_form_deleting = false;

            return this.repository.delete(model_id)
                .then(response => {
                    this.model_form_deleting = false;
                    this.$toast({
                        type: 'success',
                        message: `${this.model_name} deleted.`,
                    });

                    this.$emit('deleted');

                    return response;
                })
                .catch((response) => {
                    this.$emit('delete-error', response);
                    this.model_form_deleting = false;

                    let error_msg = `Error deleting ${this.model_name_lowercase}.`;

                    if (object_get(response, 'errors')) {
                        for (let i in response.errors) {
                            error_msg += ' ' + response.errors[i];
                            break;
                        }
                    }

                    this.$toast({
                        type: 'error',
                        message: error_msg,
                    });

                    console.error(this.model_name, response);

                    return Promise.reject(response);
                });
        },

        submit() {
            if ( this.model_modal ) {
                return this.model_modal.save();
            }

            return this.save();
        },

        save() {
            if ( this.model_id ) {
                return this.updateModel();
            } else {
                return this.createModel();
            }
        },

        clearForm() {
            this.errors = {};
        }

    },
}
