<template>
    <div class="drop-down-filter" :class="{ 'disabled': disabled }">
        <div class="drop-down-filter-trigger" ref="hello">
            <vault-button :class="{'outline': selected === false, 'small': small}">
                <div>
                    <slot name="button"></slot>
                </div>

                <div class="drop-down-filter-trigger-icons">
                    <icon-arrow-down color="white" v-show="!open">
                    </icon-arrow-down>
                    <icon-arrow-up color="white" v-show="open">
                    </icon-arrow-up>
                </div>
            </vault-button>
        </div>

        <ks-tooltip
            ref="tooltip"
            class="drop-down-filter-tooltip"
            trigger="click"
            target="hello"
            @tooltip-showed="setOpen(true)"
            @tooltip-hidden="setOpen(false)"
            :width="'300px'"
            :constraints="[{to: 'window', attachment: 'together', pin: true}]"
        >
            <div class="drop-down-filter-body">
                <slot name="body" v-if="open"></slot>
            </div>
        </ks-tooltip>
    </div>
</template>

<script>
    import {
        IconArrowUp,
        IconArrowDown
    } from 'Icons';

    export default {
        name: 'DropDownFilter',

        props: {
            selected: {
                type: Boolean,
                default: false
            },

            name: {
                type: String,
                required: true
            },

            disabled: {
                type: Boolean,
                default: false,
            },

            small: {
                type: Boolean,
                default: false,
            },
        },

        data() {
            return {
                open: false,
                event: new CustomEvent('close:active-ticket-schedule-filter', {
                    detail: {
                        'filter-name': this.name
                    }
                })
            }
        },

        mounted() {
            document.addEventListener('close:active-ticket-schedule-filter', this.closeTooltip);
        },

        destroyed() {
            // Clean up and remove registered events.
            document.removeEventListener('close:active-ticket-schedule-filter', this.closeTooltip, true);
        },

        methods: {
            setOpen(open) {
                this.open = open;

                // This is needed so the ks-autocomplete has time to register everything since the slot is v-if
                // nextTick doesn't give enough time so setTimeout is used. Don't change this unless ks-autocomplete is fixed
                setTimeout(() => {
                    if (this.open === true) {
                        document.dispatchEvent(this.event);

                        this.$emit('clicked');
                    }
                })
            },

            closeTooltip(e) {
                // If this tooltip is open, and the we aren't the one who triggered it, we should close ourselves.
                if (this.open && (!e || e.detail['filter-name'] !== this.name) && this.$refs?.tooltip) {
                    this.$refs['tooltip'].triggerHide();
                    this.open = false;
                }
            },


        },

        components: {
            IconArrowUp,
            IconArrowDown,
        }
    }
</script>
