<template>
    <ul class="suggestion-list m-0 p-0 bg-white text-sm list-none">
        <li v-if="apiLookup && fields.length === 0" class="p-1">
            {{ prompt }}
        </li>

        <li
            v-for="(fields, group, index) in grouped"
            :key="groupKey(group, index)"
            class="suggestion-list-group"
        >
            <span class="p-1 block bold">{{ group }}</span>

            <ul class="m-0 p-0 list-none">
                <li
                    v-for="field in fields"
                    :key="field.index"
                    class="suggestion-list-item py-1 pl-4 pr-2"
                    :class="{ 'is-selected bg-blue-600 text-white': navigatedIndex === field.index }"
                    @click="$emit('selected', field)"
                >
                    {{field.label}}
                </li>
            </ul>
        </li>
    </ul>
</template>

<script>
    import _groupBy from 'lodash/groupBy';
    import _sortBy from 'lodash/sortBy';

    export default {
        name: 'FormFieldSuggestionTooltip',

        props: {
            fields: {
                type: Array,
                required: false,
                default: () => [],
            },

            navigatedIndex: {
                type: Number,
                required: false,
                default: 0,
            },

            groupBy: {
                type: String,
                required: false,
                default: 'group',
            },

            apiLookup: {
                type: Boolean,
                default: false,
            },

            prompt: {
                type: String,
                default: 'Type to begin searching...',
            },
        },

        computed: {
            grouped() {
                let index = 0;

                // sort first to ensure groups stay in order while filtering suggestions
                let sorted = _sortBy(this.fields, [this.groupBy])

                    // add an index so we can still compare fields sequentially
                    .map(field => ({...field, index: index++}));

                return _groupBy(sorted, this.groupBy);
            },
        },

        methods: {
            groupKey(group, index) {
                return index + '-' + group.replaceAll(/\s+/g, '-');
            },
        },

        watch: {
            navigatedIndex(newVal, oldVal) {
                const item = this.$el.querySelector('.is-selected');

                if ( !item ) {
                    return;
                }

                const direction = oldVal < newVal ? 'down' : 'up';
                const item_count = this.fields.length - 1;
                const item_offset = item.offsetTop;
                const item_height = item.offsetHeight;
                const list_offset = this.$el.scrollTop;
                const list_height = this.$el.offsetHeight;

                if ( oldVal === item_count && newVal === 0 ) {
                    this.$el.scrollTop = 0;
                } else if ( newVal === item_count && oldVal === 0 ) {
                    this.$el.scrollTop = item_height * newVal;
                } else if ( direction === 'down' && (list_offset + list_height) - (item_offset + item_height) < item_height ) {
                    this.$el.scrollTop += item_height;
                } else if ( direction === 'up' && item_offset < list_height ) {
                    this.$el.scrollTop -= item_height;
                }
            },
        }
    }
</script>
