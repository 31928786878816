import _sortBy from 'lodash/sortBy';
import tippy, {sticky} from 'tippy.js';
import { arrayWrap } from '../../../helpers/array.js';
import FormFieldSuggestionTooltip from '@Vault/Admin/Forms/components/FormFieldSuggestionTooltip.vue';

export default {
    computed: {
        showMentionedFields() {
            return this.query !== null;
        },

        sortedMentionFields() {
            if ( !this.grouped ) {
                return this.mentionFields;
            }

            return _sortBy(this.mentionFields, [this.groupBy]);
        },
    },

    data() {
        return {
            query: null,
            mentionFields: [],
            insertMention: () => {},
            suggestionRange: null,
            navigatedIndex: 0,
            grouped: false,
            groupBy: 'group_name',
            popup: null,
            popup_trigger: 'mouseenter',
        };
    },

    methods: {
        selectField(field) {
            this.insertMention({
                range: this.suggestionRange,
                attrs: field,
            });
        },

        mentionDefaultOptions() {
            return {
                matcher: {
                    char: '@',
                    allowSpaces: true,
                    startOfLine: false,
                    allowMatchWithin: false,
                },
                onEnter: ({items, query, range, command, virtualNode}) => {
                    this.query = query;
                    this.mentionFields = items;
                    this.suggestionRange = range;
                    this.renderPopup(virtualNode);
                    this.insertMention = command;
                },
                onChange: ({items, query, range, virtualNode}) => {
                    this.query = query;
                    this.mentionFields = items;
                    this.suggestionRange = range;
                    this.navigatedIndex = 0;
                    this.renderPopup(virtualNode);
                },
                onExit: () => {
                    this.query = null;
                    this.mentionFields = [];
                    this.suggestionRange = null;
                    this.navigatedIndex = 0;
                    this.destroyPopup();
                },
                onKeyDown: ({event}) => {
                    if ( event.key === 'ArrowUp' ) {
                        this.upHandler();
                        return true;
                    }

                    if ( event.key === 'ArrowDown' ) {
                        this.downHandler();
                        return true;
                    }

                    if ( event.key === 'Enter' ) {
                        this.enterHandler();
                        return true;
                    }

                    return false;
                },
                onFilter: (searchItems, query) => {
                    if ( !query ) {
                        return searchItems;
                    }

                    return searchItems.filter(item => item.label.toLowerCase().includes(query.toLowerCase()));
                },
            };
        },

        renderPopup(vNode) {
            if ( this.popup ) {
                return;
            }

            this.popup = tippy(this.component_selector, {
                getReferenceClientRect: vNode.getBoundingClientRect,
                interactive: true,
                sticky: true,
                plugins: [sticky],
                content: this.$refs.tooltip.$el,
                trigger: this.popup_trigger,
                showOnCreate: true,
                placement: 'bottom-start',
                inertia: true,
                duration: [400, 200],
            });
        },

        destroyPopup() {
            if ( this.popup ) {
                // Destroy all tippy instances returned by the constructor
                arrayWrap(this.popup).forEach(popup => popup.destroy());
                this.popup = null;
            }
        },

        upHandler() {
            this.navigatedIndex = ((this.navigatedIndex + this.mentionFields.length) - 1) % this.mentionFields.length
        },

        downHandler() {
            this.navigatedIndex = (this.navigatedIndex + 1) % this.mentionFields.length
        },

        enterHandler() {
            const field = this.sortedMentionFields[this.navigatedIndex];

            if ( field ) {
                this.selectField(field);
            }
        },
    },

    components: {
        FormFieldSuggestionTooltip,
    },
}
