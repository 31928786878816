<template>
    <ks-modal
        ref="modal"
        title="Download Report"
        min-width="400px"
        :close-on-escape="false"
        :close-on-mask-click="false"
        @close="$emit('close')"
    >
        <div>
            <progress-bar :value="progressValue" :status="status">
                <a v-if="downloadLink" :href="downloadLink" slot="text" target="_blank">Download File</a>
                <span v-else slot="text">{{ progressValue }}%</span>
            </progress-bar>
        </div>

        <div slot="footer">
            <vault-button :disabled="!completed" @click.prevent="$refs.modal.close()">
                Close
            </vault-button>
        </div>
    </ks-modal>
</template>

<script>
    import ProgressBar from 'UI/ProgressBar.vue';

    export default {
        name: 'DownloadReportModal',

        props: {
            status: {
                type: String,
                required: true,
            },

            progressValue: {
                type: Number,
                required: true,
            },

            completed: {
                type: Boolean,
                required: true,
            },

            downloadLink: {
                type: String,
                required: false,
            },
        },

        methods: {
            open() {
                this.$refs.modal.open();
            },
        },

        components: {
            ProgressBar,
        },
    };
</script>
