<template>
    <div class="admin-dashboard">
        <div class="row">
            <page-header>
                <page-breadcrumb></page-breadcrumb>
            </page-header>
        </div>

        <div class="row">
            <transition :name="transition">
                <router-view></router-view>
            </transition>
        </div>
    </div>
</template>

<script>
    // External Dependencies

    // Internal Dependencies
    import BreadCrumbs from '../BreadCrumbs';

    export default {
        name: 'Admin',

        mixins: [BreadCrumbs],

        data() {
            return {
                transition: 'slide-left'
            }
        },

        beforeMount() {
            this.resetBreadcrumb({
                title: 'Admin',
                to: {
                    name: 'admin-dashboard'
                }
            });
        }
    }
</script>
