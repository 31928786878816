<template>
    <span v-if="hasImage()">
        <ks-tooltip trigger="click">
            <img :src="imageUrl()" :alt="imageAlt()" class="h-48">
        </ks-tooltip>
        <vault-icon :icon="icon_image"></vault-icon>
    </span>
</template>

<script>
    import { faImage } from '@fortawesome/pro-regular-svg-icons/faImage';

    import ServiceItemImageMixin from '@Vault/WorkOrder/mixins/ServiceItemImageMixin.js';

    export default {
        name: 'ProductImageIconTooltip',

        mixins: [
            ServiceItemImageMixin,
        ],

        props: {
            item: {
                type: Object,
                required: true,
            },
        },

        data() {
            return {
                icon_image: faImage,
            };
        },
    };
</script>
