<template>
    <div class="active-search-filters text-xs">
        <slot></slot>
        <search-filters-display
            :term="term"
            :filters="allFilters"
            :read-only="readOnly"
            @clear="clear"
            @clear-term="$emit('clear-term')"
        ></search-filters-display>
    </div>
</template>

<script>
    import {mapActions} from 'vuex';

    // Internal Dependencies
    import ActiveFiltersMixin from '@Vault/Filters/mixins/ActiveFiltersMixin.js';
    import SearchFiltersDisplay from '@Vault/Filters/components/SearchFiltersDisplay.vue';

    export default {
        name: 'ActiveSearchFilters',

        mixins: [ActiveFiltersMixin],

        props: {
            term: {
                type: String,
                default: '',
            },

            readOnly: {
                type: Boolean,
                default: false,
            },
        },

        methods: {
            ...mapActions('search_filters', [
                'clearFilter'
            ]),

            clear(filter, key, sub_key = null) {
                this.$emit('clear', {filter, key, sub_key});

                if ( this.dependentFilters?.[key] ) {
                    key = this.dependentFilters[key];
                }

                this.$router.replace({
                    name: this.$route.name,
                    query: this.getQueryParamsWithout(key, sub_key),
                    params: this.$route.params,
                });
                this.clearFilter({
                    namespace: this.namespace,
                    which: key,
                    sub_key,
                });
            },

            getQueryParamsWithout(key, sub_key = null, query = null) {
                query ??= {...this.$route.query};

                if ( Array.isArray(key) ) {
                    return key.reduce((new_query, query_key) => {
                        return this.getQueryParamsWithout(query_key, sub_key, query);
                    }, query);
                }

                if ( sub_key && query[key].includes(':') ) {
                    // filter object - remove sub_key/value
                    query[key] = query[key].split(';')
                        .filter(sub_key_val => sub_key_val.split(':')[0] !== sub_key)
                        .join(';');

                    // if resulting filter object has empty value, remove it
                    if ( query[key] === '' ) {
                        delete query[key];
                    }

                } else {
                    // legacy filter - remove key
                    delete query[key];
                }

                return query;
            },
        },

        components: {
            SearchFiltersDisplay,
        },
    }
</script>
