// External Dependencies
import Vue from 'vue';

export default function refresher() {
    setTimeout(() => {
        if ( window.user_acted ) {
            Vue.api.get('/ping');

            window.user_acted = false;
        }

        refresher();
    }, 600000);
}
