const WorkCategories = () => import('./components/Categories/WorkCategories.vue');
const AdminSubContractors = () => import('./components/SubContractors/AdminSubContractors.vue');
const WorkSchedule = () => import('../WorkSchedule/components/WorkSchedule.vue');
const AdminWorkReasons = () => import('./components/Reasons/AdminWorkReasons.vue');
const AdminWorkReasonsList = () => import('./components/Reasons/AdminWorkReasonsList.vue');
const AdminWorkStatuses = () => import('./components/Statuses/AdminWorkStatuses.vue');
const AdminWorkProductsServices = () => import( './components/ServiceItems/AdminWorkProductsServices.vue');
const AdminWorkServiceItemForm = () => import( './components/ServiceItems/AdminWorkServiceItemForm.vue');
const AdminWorkServiceItems = () => import( './components/ServiceItems/AdminWorkServiceItems.vue')
const AdminComplianceRules = () => import('./components/ServiceCompliance/AdminComplianceRules.vue');
const AdminWorkProjects = () => import('./components/Projects/WorkProjects.vue');

import work_schedule from '../WorkSchedule/work-schedule.routes';

export default [
    {
        path: 'categories',
        component: WorkCategories,
        name: 'admin-work-orders-categories',
        meta: {
            perms: ['admin_work'],
        },
    },
    {
        path: 'projects',
        component: AdminWorkProjects,
        name: 'admin-work-orders-projects',
        meta: {
            perms: ['admin_work_project'],
        },
    },
    {
        path: 'products-services',
        component: AdminWorkProductsServices,
        meta: {
            perms: ['admin_work'],
        },
        children: [
            {
                path: '',
                props: {
                    refresh: true,
                },
                component: AdminWorkServiceItems,
                name: 'admin-work-service-items',
            },
            {
                path: ':id/edit',
                props: true,
                component: AdminWorkServiceItemForm,
                name: 'admin-work-service-item-edit'
            },
            {
                path: 'create',
                props: true,
                component: AdminWorkServiceItemForm,
                name: 'admin-work-service-item-create'
            },
        ],
    },
    {
        path: 'sub-contractors',
        component: AdminSubContractors,
        name: 'admin-sub-contractors',
        meta: {
            perms: ['admin_work'],
        },
    },
    {
        path: 'work-schedule',
        component: WorkSchedule,
        meta: {
            perms: ['admin_work']
        },
        children: [
            ...work_schedule
        ]
    },
    {
        path: 'reasons',
        component: AdminWorkReasons,
        meta: {
            perms: ['admin_work']
        },
        children: [
            {
                path: '' ,
                component: AdminWorkReasonsList,
                name: 'admin-work-reasons',
            },
        ]

    },
    {
        path: 'statuses',
        component: AdminWorkStatuses,
        name: 'admin-work-statuses',
        meta: {
            perms: ['admin_work_status']
        },
    },
    {
        path: 'compliance',
        component: AdminComplianceRules,
        name: 'admin-compliance-rules',
        meta: {
            perms: ['admin_manage_service_rules'],
        },
    },
]
