<template>
    <div>
        <router-view></router-view>
    </div>
</template>

<script>

    import BreadCrumbs from '@Vault/BreadCrumbs';

    export default {
        name: 'InventoryFleet',

        mixins: [BreadCrumbs],

        props: [],

        data() {
            return {};
        },

        beforeMount() {
            this.pushBreadcrumb({
                title: 'Fleet',
                to: {
                    name: 'fleet-list'
                }
            });
        },


        components: {},
    };
</script>
