
const FormEntry = () => import('./components/FormEntry.vue');

export default [
    {
        path: '/forms/:form_entry_id',
        component: FormEntry,
        props: true,
        name: 'form-entry-edit'
    }
]
