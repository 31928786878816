<template>
    <div class="row">
        <div class="col tab-full med-3 panel">
            <ul class="page-side-nav">
                <router-link tag="li" to="/admin/admin-events-integrations/events" v-if="$userCan('admin_events')">
                    <a href="#" @click.prevent>Events/Subscriptions</a>
                </router-link>
                <router-link tag="li" to="/admin/admin-events-integrations/list" v-if="$userCan('admin_events')">
                    <a href="#" @click.prevent>Integrations</a>
                </router-link>
                <router-link tag="li" to="/admin/admin-events-integrations/sources" v-if="$userCan('admin_data_sources')">
                    <a href="#" @click.prevent>Data Sources</a>
                </router-link>
            </ul>
        </div>
        <div class="col tab-full med-9">
            <router-view></router-view>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'AdminEventsIntegrations',
    }
</script>