// Components

const Admin = () => import('./Admin.vue');
const AdminDashboard = () => import('./AdminDashboard.vue');
const Company = () => import('./Company/Company.vue');
const Billing = () => import('./Billing/components/Billing.vue');
const WorkOrder = () => import('./WorkOrder/components/AdminWorkOrder.vue');
const AdminEventsIntegrations = () => import('./DataIntegrations/AdminEventsIntegrations.vue');
const AdminEventSystem = () => import('./DataIntegrations/components/AdminEventSystem.vue');
const AdminDataSources = () => import('./DataIntegrations/components/AdminDataSources.vue');
const DataSourceList = () => import('./DataIntegrations/components/DataSourceList.vue');
const DataSourceImportTemplate = () => import('./DataIntegrations/components/DataSourceImportTemplate.vue');
const DataIntegrationList = () => import('./DataIntegrations/components/DataIntegrationList.vue');
const QuickBooks = () => import('./DataIntegrations/components/QuickBooks.vue');
const WorkFossaSubscription = () => import('./Subscription/WorkFossaSubscription.vue');
const Assets = () => import('./Assets/components/Assets.vue');
const UserManagement = () => import('./Users/components/UserManagement.vue');
const UsersMain = () => import('./Users/components/UsersMain.vue');
const UserList = () => import('./Users/components/UserList.vue');
const UserShow = () => import('./Users/components/UserShow.vue');
const AdminRolesAndGroups = () => import('./Users/components/AdminRolesAndGroups.vue');
const RoleForm = () => import('./Users/components/RoleForm.vue');
const UserGroupForm = () => import('./Users/components/UserGroupForm.vue');
const AdminSavedSearches = () => import('@Vault/Admin/SavedSearches/AdminSavedSearches.vue');
const Imports = () => import('./Imports/components/Imports.vue');
const Exports = () => import('./Exports/components/Exports.vue');
const SystemAdmin = () => import('./SystemAdmin/components/SystemAdmin.vue');
const AdminCustomFields = () => import('./CustomFields/components/AdminCustomFields.vue');
const AdminEquipment = () => import('./Equipment/components/AdminEquipment.vue');
const WorkScheduleTemplateForm = () => import('./WorkSchedule/components/Templates/WorkScheduleTemplateForm.vue');
const AdminReports = () =>  import('@Vault/Admin/Reports/components/AdminReports.vue');
const AdminTaskBase = () => import('@Vault/Admin/Users/components/Tasks/AdminTaskBase.vue');
const AdminTasksTabs = () => import('@Vault/Admin/Users/components/Tasks/AdminTaskTabs.vue');
const AdminCustomerPanel = () =>  import('@Vault/Admin/Customers/components/AdminCustomersPanel.vue');

// Routes
import work from './WorkOrder/workorder.routes';
import billing from './Billing/billing.routes';
import customers from './Customers/customers.routes';
import imports from './Imports/imports.routes';
import exports from './Exports/exports.routes';
import forms from './Forms/forms.routes';
import certifications from './Certifications/certifications.routes.js';
import timesheets from './Timesheets/timesheets.routes.js';
import reports from './Reports/reports.routes.js';

export default [
    {
        path: '/admin',
        component: Admin,
        children: [
            {
                path: '/',
                component: AdminDashboard,
                name: 'admin-dashboard',
                meta: {
                    perm_group: ['admin', 'certification'],
                }
            },
            {
                path: 'company',
                component: Company,
                name: 'admin-company',
                meta: {
                    perms: ['admin_company'],
                },
            },
            {
                path: 'user-management',
                name: 'admin-users-dashboard',
                component: UserManagement,
                meta: {
                    perms: [
                        'admin_field_manager',
                        'admin_saved_search_manage',
                        'admin_timesheet_settings',
                        'admin_user_roles',
                        'admin_user_timeoff',
                        'admin_user_timesheets',
                        'admin_user_vacations',
                        'admin_users',
                        'certification_types_manage',
                        'certifications_view',
                    ],
                },
                children: [
                    {
                        path: 'users',
                        component: UsersMain,
                        meta: {
                            perms: ['admin_users', 'admin_field_manager'],
                        },
                        children: [
                            {
                                path: '',
                                name: 'admin-users',
                                component: UserList,
                            },
                            {
                                path: ':user_id(\\d+)',
                                name: 'user-show',
                                props: true,
                                component: UserShow,
                            }
                        ]
                    },
                    {
                        path: 'roles',
                        name: 'admin-roles',
                        component: AdminRolesAndGroups,
                        meta: {
                            perms: ['admin_user_roles', 'admin_user_groups']
                        },
                    },
                    {
                        path: 'roles/:role_id(\\d+)',
                        name: 'admin-role-form',
                        component: RoleForm,
                        props: true,
                        meta: {
                            perms: ['admin_user_roles']
                        },
                    },
                    {
                        path: 'roles/groups/:user_group_id(\\d+)',
                        name: 'admin-user-group-form',
                        component: UserGroupForm,
                        props: true,
                        meta: {
                            perms: ['admin_user_groups']
                        },
                    },
                    ...certifications,
                    ...timesheets,
                    {
                        path: 'saved-searches',
                        name: 'admin-saved-searches',
                        component: AdminSavedSearches,
                        props: true,
                        meta: {
                            perms: ['admin_saved_search_manage'],
                        },
                    },
                    {
                        path: 'tasks',
                        component: AdminTaskBase,
                        meta: {
                            perms: ['admin_tasks'],
                        },
                        children: [
                            {
                                path: '',
                                component: AdminTasksTabs,
                                name: 'admin-tasks',
                            },
                        ]
                    },
                ]
            },
            {
                path: 'billing',
                name: 'admin-customers-dashboard',
                component: Billing,
                meta: {
                    perms: [
                        'admin_billing',
                        'admin_invoices',
                        'admin_pricing',
                        'admin_terms',
                    ],
                },
                children: [
                    ...billing,
                ]
            },
            {
                path: 'customers',
                name: 'admin-customers',
                component: AdminCustomerPanel,
                meta: {
                    perms: [
                        'admin_approve_contacts',
                        'admin_notification_templates',
                        'admin_notifications',
                    ],
                },
                children: [
                    ...customers,
                ]
            },
            {
                path: 'work-orders',
                name: 'admin-work-dashboard',
                component: WorkOrder,
                meta: {
                    perms: ['admin_work', 'admin_work_project', 'admin_work_status']
                },
                children: [
                    ...work
                ]
            },
            {
                path: 'admin-events-integrations',
                component: AdminEventsIntegrations,
                meta: {
                    perms: ['admin_events', 'admin_integrations'],
                },
                children: [
                    {
                        path: 'events',
                        component: AdminEventSystem,
                        name: 'admin-event-system',
                    },
                    {
                        path: 'list',
                        component: DataIntegrationList,
                        name: 'admin-data-integrations-list',
                    },
                    {
                        path: 'qbo',
                        component: QuickBooks,
                        name: 'admin-data-integrations-quick-books',
                    },
                    {
                        path: 'sources',
                        component: AdminDataSources,
                        props: true,
                        children: [
                            {
                                path: '',
                                component: DataSourceList,
                                name: 'admin-data-sources-list',
                            },
                            {
                                path: ':data_source_id/imports/:import_template_id',
                                component: DataSourceImportTemplate,
                                name: 'admin-data-source-imports',
                                props: true,
                            },
                        ]
                    }
                ]
            },
            {
                path: 'assets',
                component: Assets,
                name: 'admin-assets'
            },
            {
                path: 'imports',
                component: Imports,
                meta: {
                    perms: ['admin_imports'],
                },
                children: [
                    ...imports
                ]
            },
            {
                path: 'exports',
                component: Exports,
                meta: {
                    perms: ['admin_exports'],
                },
                children: [
                    ...exports
                ]
            },
            {
                path: 'system-admin',
                component: SystemAdmin,
                name: 'admin-system-admin'
            },
            ...forms,
            {
                path: 'custom-fields',
                component: AdminCustomFields,
                name: 'admin-custom-fields',
                props: true,
                meta: {
                    perms: ['admin_custom_fields']
                }
            },
            {
                path: 'equipment',
                component: AdminEquipment,
                name: 'admin-equipment',
                props: true,
                meta: {
                    perms: ['admin_equipment']
                }
            },
            {
                path: 'work-orders/work-schedule/create',
                component: WorkScheduleTemplateForm,
                name: 'admin-work-schedule-template-create'
            },
            {
                path: 'work-orders/work-schedule/:workScheduleTemplateId/edit',
                component: WorkScheduleTemplateForm,
                name: 'admin-work-schedule-template-edit',
                props: true,
            },
            {
                path: 'reports',
                component: AdminReports,
                children: [
                    ...reports,
                ]
            }
        ]
    },
]
