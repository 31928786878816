<template>
    <ks-modal ref="modal" :title="modalTitle" :loading="loading" @close="close(false)" min-width="480px" max-width="480px">
<!--        <ks-loader-spin v-if="loading" size="medium" :show="true"></ks-loader-spin>-->

<!--        <div v-else>-->
        <div>
            <form-group v-if="admin" label="User" name="user_id" :errors="errors" :required="true">
                <ks-autocomplete
                    v-model="model.user"
                    selection-key="display_name"
                    placeholder="Select user..."
                    @search="searchUsers"
                    @input="checkForExistingCertification"
                ></ks-autocomplete>
            </form-group>

            <form-group v-if="!loading && types.length > 0" label="Certification Type" name="certification_type_id" :errors="errors" :required="true">
                <ks-select
                    v-model="certType"
                    name="certification_type_id"
                    :paginated="true"
                    @search="searchCertificationTypes"
                    selection-key="certification_type_id"
                    label-key="name"
                    @input="checkForExistingCertification"
                ></ks-select>
            </form-group>

            <form-group label="Certificate Number" name="certificate_number" :errors="errors">
                <input v-model="model.certificate_number" name="certificate_number" type="text">
            </form-group>

            <div class="row row-collapse">
                <form-group label="Issue Date" name="issue_date" :errors="errors" class="sm-6">
                    <ks-datepicker v-model="model.issue_date" name="issue_date" month-picker year-picker></ks-datepicker>
                </form-group>

                <form-group label="Expiration Date" name="expiration_date" :errors="errors" class="sm-6">
                    <ks-datepicker v-model="model.expiration_date" name="expiration_date" month-picker year-picker></ks-datepicker>
                </form-group>
            </div>

            <div class="flex jc-between text-sm">
                <form-group label="Upload File" name="file" :errors="errors">
                    <div v-if="hasFile && !updating" class="mb-2">{{ model.file.name }}</div>
                    <file-uploader
                        button-text="Choose File"
                        :auto-upload="true"
                        :mime-types="[{ extensions: 'jpeg,jpg,png,pdf' }]"
                        :multiple="false"
                        :upload-params="upload_params"
                        upload-url="/api/certifications/files"
                        @file-uploaded="fileUploaded"
                    ></file-uploader>
                </form-group>

                <div v-if="hasFile">
                    <img v-if="hasImageFile" :src="fileImageUrl" class="h-24" :alt="model.file.name" />
                    <div v-else>{{ model.file.name }}</div>
                </div>
            </div>

            <form-group label="Comments" name="comments" :errors="errors">
                <textarea v-model="model.comments" name="comments" rows="2"></textarea>
            </form-group>

            <slide-transition>
                <div v-if="certificate_already_exists" class="alert warning">
                    <div class="text-sm">
                        An active certificate already exists for this certification type.
                    </div>
                    <ks-checkbox v-model="replace_existing_certificate" :value="true" :switch-style="true" class="mt-2">
                        Replace existing certificate
                    </ks-checkbox>
                </div>
            </slide-transition>

        </div>
        <div slot="footer">
            <vault-button type="save" :spin="saving" @click="save" :disabled="disableSave">
                Save Certification
            </vault-button>

            <button class="button outline" @click.prevent="close">Cancel</button>
        </div>
    </ks-modal>
</template>

<script>
    // External dependencies
    import { mapGetters } from 'vuex';
    import { formatDate } from 'kickstart-ui/lib/dates';
    import _cloneDeep from 'lodash/cloneDeep';

    // Internal dependencies
    import Certifications from '@Vault/Certifications/repositories/CertificationsRepository.js';
    import CertificationTypes from '@Vault/Certifications/repositories/CertificationTypesRepository.js';
    import FileUploader from 'UI/Files/FileUploader.vue';
    import Users from '@Vault/Admin/Users/UserRepository.js';

    const blank_certification = /** @type {Certification} */ {
        certification_type_id: null,
        user_id: null,
        issue_date: formatDate(new Date),
        expiration_date: null,
        certificate_number: null,
        comments: null,
    };

    export default {
        name: 'EditCertificationModal',

        props: {
            userId: {
                type: [Number, String],
                default: null,
            },

            certificationTypeId: {
                type: [Number, String],
                default: null,
            },

            admin: {
                type: Boolean,
                default: false,
            },
        },

        computed: {
            ...mapGetters('certifications', [
                'activeCertifications',
            ]),

            activeCertificationIds() {
                return this.activeCertifications
                    .flatMap(({certification_id, certification_type_id, user_id}) => ({
                        certification_id,
                        certification_type_id,
                        user_id,
                    }));
            },

            modalTitle() {
                return this.updating
                    ? 'Edit Certificate'
                    : 'Add Certificate';
            },

            hasFile() {
                return !!this.model.file
            },

            hasImageFile() {
                return ['jpg', 'jpeg', 'png'].includes(this.model.file.extension);
            },

            fileImageUrl() {
                if ( !this.hasFile ) {
                    return null;
                }

                return this.model.file.signed_thumbnail_url || this.model.file.url;
            },

            selectedTypeUserHasActiveCertifications() {
                return this.activeCertificationIds
                    .filter(({certification_type_id, user_id}) => {
                        return certification_type_id === this.model.certification_type_id &&
                            user_id === this.model.user_id;
                    })
                    .length > 0;
            },

            creatingCertificationForActiveCertificationTypeUser() {
                return !this.updating && this.selectedTypeUserHasActiveCertifications;
            },

            updatingCertificationToActiveCertificationTypeUser() {
                return this.updating &&
                    this.dirtyTypeOrUser &&
                    this.selectedTypeUserHasActiveCertifications;
            },

            disableSave() {
                return this.loading || (this.certificate_already_exists && !this.replace_existing_certificate);
            },

            dirtyTypeOrUser() {
                if ( !this.original_certification ) {
                    return false;
                }

                return this.original_certification.certification_type_id !== this.model.certification_type_id ||
                    this.original_certification.user_id !== this.model.user_id;
            },

            selectedTypeUserIds() {
                return {
                    user_id: this.model.user?.user_id || this.userId,
                    certification_type_id: this.model.certification_type_id || this.certificationTypeId,
                };
            },

            certType: {
                get() {
                    return this.model.type;
                },
                set(value) {
                    if ( !value ) {
                        this.model.type = null;
                        this.model.certification_type_id = null;
                    }
                    this.$set(this.model, 'type', value);
                    this.model.certification_type_id = this.model.type.certification_type_id;
                }
            }
        },

        data() {
            return {
                updating: false,
                loading: false,
                saving: false,
                types: /** @type {CertificationType[]} */ [],
                model: /** @type {Certification} */ {
                    ...blank_certification,
                    user_id: this.userId,
                },
                original_certification: null,
                certificate_already_exists: false,
                replace_existing_certificate: false,
                errors: {},
                user: null,
                upload_params: {
                    group: 'certification',
                },
            };
        },

        methods: {
            /** @param {?Certification} certification */
            open(certification = null) {
                if ( !!certification ) {
                    this.updating = true;
                    this.original_certification = certification;
                    this.setModel(certification);
                }

                this.$refs.modal.open();

                if ( this.types.length === 0 ) {
                    this.loadCertificationTypes();
                }
            },

            close(close_modal = true) {
                this.reset();

                if ( close_modal ) {
                    this.$refs.modal.close();
                }
            },

            reset() {
                this.updating = false;
                this.saving = false;
                this.loading = false;
                this.certificate_already_exists = false;
                this.original_certification = null;
                this.setModel();
                this.errors = {};
            },

            /** @param {?Certification} certification */
            setModel(certification = null) {
                this.model = _cloneDeep(Object.assign({}, blank_certification, certification));
            },

            fileUploaded(uploader, file, {data}) {
                this.$set(this.model, 'file', data);
                this.$set(this.model, 'file_id', data?.file_id || null);
            },

            createOrUpdate() {
                let params = {
                    ...this.model,
                    ...this.selectedTypeUserIds,
                };

                return this.updating
                    ? Certifications.update(this.model.certification_id, params)
                    : Certifications.create(params);
            },

            save() {
                this.saving = true;

                this.createOrUpdate()
                    .then(() => {
                        this.$toast({
                            type: 'success',
                            message: 'Saved certification',
                        });

                        this.$emit('saved');
                        this.close();
                    })
                    .catch(({errors, message}) => {
                        this.errors = errors;
                        this.$toast({
                            type: 'error',
                            message,
                        });
                    })
                    .finally(() => {
                        this.saving = false;
                    });
            },

            checkForExistingCertification() {
                if ( !this.model.user || !this.model.certification_type_id ) {
                    return this.certificate_already_exists = false;
                }

                if ( this.updating && !this.dirtyTypeOrUser ) {
                    return this.certificate_already_exists = false;
                }

                if ( this.creatingCertificationForActiveCertificationTypeUser || this.updatingCertificationToActiveCertificationTypeUser ) {
                    return this.certificate_already_exists = true;
                }

                this.certificate_already_exists = false;
                this.loading = true;

                Certifications.list(this.selectedTypeUserIds)
                    .then(({data}) => {
                        this.certificate_already_exists = data.length > 0;
                    })
                    .catch(({errors, message}) => {
                        this.errors = errors;
                        this.$toast({
                            type: 'error',
                            message,
                        });
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            },

            searchCertificationTypes({term, callback, page}) {
                callback(
                    CertificationTypes.endpointUri({
                        term,
                        page
                    })
                );
            },

            loadCertificationTypes() {
                this.loading = true;

                CertificationTypes.list()
                    .then(({data}) => {
                        this.types = data;
                    })
                    .catch(({message}) => {
                        this.$toast({
                            type: 'error',
                            message,
                        });
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            },

            searchUsers({term, callback, page}) {
                callback(Users.endpointUri({search_name: term, page}));
            },
        },

        components: {
            FileUploader
        },
    }
</script>
