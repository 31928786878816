export default {
    methods: {
        visitRoute(visit) {
            return {
                name: 'work-visit-show',
                params: {
                    work_number: visit.work?.work_number,
                    work_visit_number: visit.work_visit_number
                },
            };
        },
    }
}
