import { displayValue } from 'helpers/custom-fields';

export default {
    methods: {
        /**
         * Use this method to group values by custom field id.
         * This is needed to handle multi value fields like checkboxes
         *
         * @param field_values
         * @param as_dictionary
         */
        transformValues(field_values, as_dictionary = false) {
            let value_groups = {};


            for ( let i in field_values ) {
                let field_value = field_values[i];
                let field = field_value.field;

                if ( field.custom_field_id in value_groups && field.is_multi_value_field) {
                    if ( as_dictionary ) {
                        value_groups[field_values[i].custom_field_id].push(field_value.value);
                    } else {
                        value_groups[field_values[i].custom_field_id].push(field_value);
                    }
                } else {
                    if ( as_dictionary ) {
                        value_groups[field.custom_field_id] = field.is_multi_value_field
                            ? [field_value.value]
                            : field_value.value;
                    } else {

                        value_groups[field.custom_field_id] = field.is_multi_value_field
                            ? [field_value]
                            : field_value;
                    }
                }
            }

            return value_groups;
        },

        /**
         * Use this method to convert the field value to a simple dictionary keyed by custom_field_id
         *
         * @param field_values
         * @returns {*}
         */
        valueDictionary(field_values) {
            return this.transformValues(field_values, true);
        },

        /**
         * Takes a field with a raw value and will convert it for display
         *
         * @param field
         * @param value
         * @returns {string}
         */
        displayValue(field, value) {
            return displayValue(field, value);
        }
    }
}
