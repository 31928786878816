<template>
    <div>
        <router-view></router-view>
    </div>
</template>

<script>
    import BreadCrumbs from 'BreadCrumbs';

    export default {
        name: 'AdminTaxes',

        mixins: [BreadCrumbs],

        beforeMount() {
            this.pushBreadcrumb({
                title: 'Tax Categories',
                to: {
                    name: 'admin-tax-categories'
                }
            });
        },
    }
</script>
