<template>
    <ks-select
        v-model="state"
        name="state"
        :items="loaded_states"
        item-key="state_id"
        label-key="state_name"
        :group-by="groupBy"
        :placeholder="placeholder"
        :single-deselect="true"
    >
        <template v-slot="{item}">
            {{ item.state_name }}
            <span class="muted">{{ item.state }}</span>
        </template>
    </ks-select>
</template>

<script>
    import LoadsStatesMixin from '@Vault/Locations/mixins/LoadsStatesMixin.js';

    export default {
        name: 'StateDropdown',

        mixins: [LoadsStatesMixin],

        props: {
            value: {
                type: [Number, String, Object],
                default: null,
            },

            states: {
                type: Array,
                default: () => [],
            },

            /**
             * When set to true, the v-model will bind to the state_id instead of the entire state object.
             */
            forceValues: {
                type: Boolean,
                default: false,
            },

            groupBy: {
                type: String,
                default: 'country_name',
            },
        },

        computed: {
            state: {
                get() {
                    return this.loaded_states
                        ?.find(({state_id}) => state_id == (this.value?.state_id || this.value))
                },

                set(value) {
                    this.$emit('input', this.forceValues ? value?.state_id : value);
                },
            },

            placeholder() {
                return this.multiple ? 'Select state(s)...' : 'Select state...';
            },
        },

        mounted() {
            if ( this.states.length > 0 ) {
                this.loaded_states = this.states;
                return;
            }

            this.loadStates();
        },

        watch: {
            states(states) {
                this.loaded_states = states;
            },
        },
    }
</script>
