<template>
    <router-view></router-view>
</template>

<script>
    import BreadCrumbs from '@Vault/BreadCrumbs';

    export default {
        name: 'UsersMain',

        mixins: [BreadCrumbs],

        props: [],

        data() {
            return {}
        },

        beforeMount() {
            this.pushBreadcrumb({
                title: 'Users',
                to: {
                    name: 'admin-users',
                }
            });
        },

        components: {}
    }
</script>
