/**
 * PageHelper
 *
 * Mixin that exposes some methods and computed properties to help manage the query params
 */
export default {

    computed: {
        page() {
            return this.$route.query?.page || 0;
        },
        query() {
            return this.$route.query;
        }
    },

    data() {
        return {
            previous_route_query: {},
        };
    },

    methods: {
        /**
         * Removes a variable from the query string
         *
         * @param name
         */
        removeQueryParam(name) {
            let query = Object.assign({}, this.$route.query) || {};

            if ( name in query ) {
                delete query[name];
                this.$router.replace({ query });
            }
        },

        /**
         * Gets a value from the query params
         *
         * @param name
         * @param default_value
         * @return {*}
         */
        getQueryParam(name, default_value = null) {
            if ( 'query' in this.$route ) {
                if ( name in this.$route.query ) {
                    return this.$route.query[name];
                }
            }

            return default_value;
        },

        /**
         * Adds params to the query
         *
         * @param params
         */
        addQueryParams(params) {
            this.previous_route_query = {...this.$route.query};
            this.$router.replace({ query: Object.assign({}, this.$route.query, params) });
        },

        /**
         * Adds a single param to the query
         *
         * @param name
         * @param value
         */
        addQueryParam(name, value) {
            let params = {};
            params[name] = value;

            this.addQueryParams(params);
        },

        revertQueryParams() {
            this.$router.replace({ query: {...this.previous_route_query} });
            this.previous_route_query = {};
        },

        /**
         * Adds or removes a param to the query at key based on its value.
         *
         * @param key
         * @param value
         */
        updateQueryParam(key, value) {
            if ( value === undefined || value === null || value == '' ) {
                this.removeQueryParam(key);
            } else {
                this.addQueryParam(key, value);
            }
        },
    }
}
