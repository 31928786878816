import {faFileAlt} from '@fortawesome/pro-solid-svg-icons/faFileAlt';
import {faTable} from '@fortawesome/pro-solid-svg-icons/faTable';
import {faFilePowerpoint} from '@fortawesome/pro-solid-svg-icons/faFilePowerpoint';
import {faFileArchive} from '@fortawesome/pro-solid-svg-icons/faFileArchive';
import {faFileImage} from '@fortawesome/pro-solid-svg-icons/faFileImage';
import {faFileVideo} from '@fortawesome/pro-solid-svg-icons/faFileVideo';
import {faFileAudio} from '@fortawesome/pro-solid-svg-icons/faFileAudio';
import {faFilePdf} from '@fortawesome/pro-solid-svg-icons/faFilePdf';
import {faFile} from '@fortawesome/pro-solid-svg-icons/faFile';
import {faFolderDownload} from '@fortawesome/pro-light-svg-icons/faFolderDownload';


export default {
    data() {
        return {
            file_icons: {
                file_alt: faFileAlt,
                table: faTable,
                powerpoint: faFilePowerpoint,
                archive: faFileArchive,
                image: faFileImage,
                video: faFileVideo,
                audio: faFileAudio,
                pdf: faFilePdf,
                file: faFile,
                download: faFolderDownload,
            },
        };
    },

    methods: {
        getIcon(extension) {
            switch (extension) {
                case 'doc':
                case 'docx':
                case 'docm':
                case 'dot':
                case 'dotm':
                case 'docb':
                case 'txt':
                case 'rtf':
                    return {icon: this.file_icons.file_alt, color: 'grey'};

                case 'xls':
                case 'xlr':
                case 'xlt':
                case 'xlm':
                case 'xlsx':
                case 'xltx':
                case 'csv':
                case 'dat':
                    return {icon: this.file_icons.table, color: 'green'};

                case 'ppt':
                case 'pot':
                case 'pps':
                case 'pptx':
                case 'pptm':
                case 'potx':
                case 'ppsx':
                    return {icon: this.file_icons.powerpoint, color: 'orange'};

                case '7z':
                case 'gz':
                case 'zip':
                case 'rar':
                    return {icon: this.file_icons.archive, color: 'beige'};

                case 'bmp':
                case 'gif':
                case 'psd':
                case 'png':
                case 'jpg':
                case 'tif':
                    return {icon: this.file_icons.image, color: 'blue'};

                case 'avi':
                case 'mov':
                case 'mp4':
                case 'mpg':
                case 'flv':
                case '3gp':
                case 'm4v':
                    return {icon: this.file_icons.video, color: 'blue'}

                case 'm4a':
                case 'mp3':
                case 'wav':
                    return {icon: this.file_icons.audio, color: 'black'};

                case 'pdf':
                    return {icon: this.file_icons.pdf, color: 'red'};

                default:
                    return {icon: this.file_icons.file, color: 'black'};
            }
        },
    },
}
