const ReferenceDocuments = () => import('./components/ReferenceDocuments.vue');
const ReferenceDocumentShow = () => import('@Vault/ReferenceDocuments/components/ReferenceDocumentShow.vue');
const ReferenceCategoryShow = () => import('@Vault/ReferenceDocuments/components/ReferenceCategoryShow.vue');

export default [
    {
        path: '/references',
        component: ReferenceDocuments,
        meta: {
            perms: ['reference_documents_view'],
        },
        children: [
            {
                name: 'reference-documents',
                path: '',
                props: true,
                component: ReferenceCategoryShow,
            },
            {
                name: 'reference-category-show',
                path: 'category/:category_id(\\d+)',
                props: true,
                component: ReferenceCategoryShow,
            },
            {
                name: 'reference-document-show',
                path: 'document/:document_id(\\d+)',
                props: true,
                component: ReferenceDocumentShow,
            },
            {
                name: 'reference-document-create',
                path: 'document/create',
                props: true,
                component: ReferenceDocumentShow,
            },
        ],
    },
];
