import {ucwords} from 'kickstart-ui/lib/strings';
import deepSet from '@/helpers/deepSet.js';
import {object_get} from 'kickstart-ui/lib/objects.js';

export default {
    inject: {
        ns: {
            default: () => ({}),
        },
    },

    props: {
        baseName: {
            type: String,
            default: 'fields',
        },
    },

    data() {
        return {
            ns: this.ns
        }
    },

    methods: {
        getModel() {
            return this.ns.model;
        },

        getNamespace() {
            return this.ns.namespace;
        },

        setToFilterStore(which, value) {
            return deepSet(this.getModel()[this.getNamespace()], which, value);
        },

        getFromFilterStore(which) {
            if ( object_get(this.getModel(), this.getNamespace(), false) === false ||
                object_get(this.getModel()[this.getNamespace()], which, false) === false ) {
                return null;
            }

            return object_get(this.getModel()[this.getNamespace()], which, null);
        },

        addFilter(which, value, push = false) {
            if ( push ) {
                let filter = this.getFilter(which);

                if ( filter != null ) {
                    let index = filter.length;
                    if ( index > 0 ) {
                        which = `${which}.${index}`;
                    }
                } else if ( filter == null ) {
                    value = [value];
                }
            }

            return this.setToFilterStore(which, value);
        },

        getFilter(which) {
            return this.getFromFilterStore(which);
        },

        removeFilter(which) {
            return this.setToFilterStore(which, null);
        },

        getFilterValue(name, labelKey, idKey = name) {
            let obj = this.getFilter(name);

            // If the obj is null then just return it
            // -or- If the obj has a user_id key
            if ( !obj || name in obj ) {
                return obj;
            }

            let filterVal = {};
            filterVal[idKey] = obj.filter_key;
            filterVal[labelKey] = obj.filter_label
            return Object.assign(obj, filterVal);
        },

        setFilterValue(val, name, labelKey, idKey = name, searchLabel = name) {
            if ( !val ) {
                this.removeFilter(name);
                return null;
            }
            this.addFilter(name, Object.assign(val, {
                filter_name: searchLabel ? searchLabel : ucwords(searchLabel.replace(/_/g, ' ')),
                filter_key: val[idKey],
                filter_label: val[labelKey]
            }));
        },

        setFilterValueTerm(val, name, searchLabel) {
            if ( !val ) {
                this.removeFilter(name);
                return null;
            }

            let val_obj = {};
            val_obj[name] = val;
            this.setFilterValue(val_obj, name, name, name, searchLabel);
        },


        setCustomFieldFilter(field, val) {

            if ( [null, undefined, ''].includes(val) || val.length === 0 ) {
                this.removeFilter(`${this.baseName}[${field.custom_field_id}]`);
                return;
            }

            let filter_label = val;

            if ( field.requires_options && !!field.options.options ) {
                filter_label = Array.isArray(val)
                    ? field.options.options
                        .filter(({value}) => val.includes(value))
                        .map(({label}) => label)
                        .join(' OR ')
                    : field.options.options
                        .find(({value}) => value == val)?.label;
            }

            this.addFilter(`${this.baseName}[${field.custom_field_id}]`, {
                filter_name: field.label,
                filter_key: val,
                filter_label
            });
        },

        getCustomFieldFilter(field) {
            let value = this.getFilterValue(`${this.baseName}[${field.custom_field_id}]`);

            if ( value && 'filter_key' in value ) {
                return value.filter_key;
            }

            return value;
        }
    }
}
