<template>
    <router-view :work-loading="loading"></router-view>
</template>

<script>
    import BreadCrumbs from 'BreadCrumbs';

    export default {
        name: 'WorkVisitShowRoot',

        mixins: [BreadCrumbs],

        props: {
            work_visit_number: {},
            work_number: {},
            loading: {},
        },

        computed: {
            breadcrumb() {
                return {
                    title: `Visit: #${this.work_visit_number}`,
                    to: {
                        name: 'work-visit-show',
                        params: {
                            work_visit_number: this.work_visit_number
                        }
                    }
                };
            }
        },

        beforeMount() {
            this.pushBreadcrumb(this.breadcrumb);
        },

        watch: {
            work_visit_number() {
                this.popBreadcrumb();

                // update the work breadcrumb
                this.editBreadcrumb({
                    title: `#${this.work_number}`,
                    to: {
                        name: 'work-show',
                        params: {
                            work_number: this.work_number,
                        },
                    },
                });

                this.pushBreadcrumb(this.breadcrumb);
            },
        },
    }
</script>
