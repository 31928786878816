<template>
    <div :style="styleProperties">
        <slot></slot>
    </div>
</template>

<script>

    import {scrolledToBottom} from 'kickstart-ui/lib/events.js';

    export default {
        name: 'ScrollableContent',

        props: {
            height: {
                required: true
            },

            scrollEvents: {
                type: Boolean,
                default: true,
            },

            addedStyle: {
                type: Object,
                default() {
                    return {};
                }
            },

            delay: {
                type: Number,
                default: 150
            }
        },

        computed: {
            styleProperties() {
                return {
                    height: this.height,
                    overflow: 'auto',
                    ...this.addedStyle
                }
            }
        },

        mounted() {
            if ( this.scrollEvents ) {
                scrolledToBottom(this.$el, (e) => {
                    this.$emit('scrolled-to-bottom', e);
                }, this.delay);
            }
        },

        components: {},
    };
</script>
