import {formatDate} from 'kickstart-ui/lib/dates';
import {object_get} from 'kickstart-ui/lib/objects.js';

export default class ConditionChecker {

    /**
     * ConditionChecker constructor.
     *
     * @param {Array} conditions
     */
    constructor(conditions) {
        this.conditions = conditions || [];

        this.condition_status = {
            passed: [],
            failed: [],
        };

        this.multi_index_check = false;

        // Set the match type based on the value in our first condition
        if ( this.conditions.length > 0) {
            if ( 'match_type' in this.conditions[0] ) {
                this.match_type = this.conditions[0].match_type;

            } else {
                console.error('missing match type in conditions: defaulting to \'all\'');

                this.match_type = 'all';
            }
        }
    }

    /**
     *
     * @param {number} value_index
     * @returns {ConditionChecker}
     */
    setValueIndex(value_index = 0) {
        this.value_index = value_index;
        return this;
    }

    /**
     *
     * @param {boolean} multi_index_check
     * @returns {ConditionChecker}
     */
    setMultiIndex(multi_index_check) {
        this.multi_index_check = multi_index_check;
        return this;
    }

    /**
     *
     * @param values
     * @param location
     * @param equipment
     * @param customer
     * @param service_item_data
     * @returns {boolean}
     */
    testConditions(values, location, equipment, customer, service_item_data) {
        // Return true for fields without conditions
        if ( this.conditions.length == 0 ) {
            return true;
        }

        let pass = this.match_type !== 'any';

        for ( let i in this.conditions ) {
            let condition = this.conditions[i];
            let condition_passed = true;

            // Test the condition of the field based on the type
            switch ( condition.condition_type ) {
                case 'custom_field':
                    condition_passed = this.multi_index_check ?
                        this.testMultiIndexCustomFieldCondition(condition, values) :
                        this.testCustomFieldCondition(condition, values);
                    break;
                case 'location':
                    if ( location && !this.testLocationCondition(condition, location) ) {
                        condition_passed = false;
                    }
                    break;
                case 'customer':
                    if ( customer ) {
                        condition_passed = this.testCustomerCondition(condition, customer);
                    }

                    break;
                case 'equipment':
                    if ( equipment ) {
                        condition_passed = this.multi_index_check ?
                            this.testMultiIndexEquipmentCondition(condition, equipment) :
                            this.testEquipmentCondition(condition, equipment);
                    } else {
                        condition_passed = false;
                    }
                    break;

                case 'service_item':
                    if ( service_item_data ) {
                        condition_passed = this.testServiceItemCondition(condition, service_item_data);
                    }
                    break;
            }

            // based on our
            switch ( this.match_type ) {
                case 'any':
                    if ( condition_passed ) {
                        return true;
                    }
                    break;
                case 'all':
                    if ( !condition_passed ) {
                        return false;
                    }
                    break;
                case 'none':
                    if ( condition_passed ) {
                        return false;
                    }
                    break;
            }
        }

        return pass;
    }

    testCustomFieldCondition(condition, values) {
        let actual_value = null;
        for ( let custom_field_id in values ) {
            if ( custom_field_id == condition.custom_field_id ) {
                actual_value = values[custom_field_id];
            }
        }

        return this.doesValuePassCondition(condition, actual_value);
    }

    testMultiIndexCustomFieldCondition(condition, values) {
        if ( condition.custom_field_id in values ) {
            return this.doesAnyValuePassCondition(condition, values[condition.custom_field_id]);
        }

        return false;
    }

    testLocationCondition(condition, values) {
        let location_params = ['country_id', 'state_id', 'county_id'];

        for ( let i in location_params ) {
            let location_parameter = location_params[i];

            let condition_parameter = condition[location_parameter];

            if ( condition_parameter ) {
                if ( location_parameter === 'state_id' || location_parameter === 'county_id' ) {
                    // see ConditionChecker.php: state and county conditions need to be checked *together*

                    let condition_passes = false;

                    let condition_states = Array.isArray(condition['state_id']) ? condition['state_id'] : [condition['state_id']];
                    if ( condition_states.find(cond_state => cond_state == values['state_id']) ) {
                        condition_passes = true;
                    }

                    let condition_counties = Array.isArray(condition['county_id']) ? condition['county_id'] : [condition['county_id']];
                    if ( condition_counties.find(cond_county => cond_county == values['county_id']) ) {
                        condition_passes = true;
                    }

                    if ( !condition_passes ) {
                        return false;
                    }
                } else {
                    if ( condition_parameter != values[location_parameter] ) {
                        return false;
                    }
                }
            }
        }

        return true;
    }

    cleanDateValue(value) {
        if ( !value ) {
            return null;
        }

        return parseInt(value.replace(/-/g, '').trim() || 0);
    }

    cleanTimeValue(value) {
        if ( !value ) {
            return null;
        }

        return new Date(formatDate(new Date()) + ' ' + value).getTime()
    }

    testMultiIndexEquipmentCondition(condition, equipment_data) {
        if ( condition.form_section_id in equipment_data ) {
            return this.doesAnyEquipmentPassCondition(condition, equipment_data[condition.form_section_id]);
        }

        return false;
    }

    doesAnyEquipmentPassCondition(condition, equipments) {
        for ( let value_index in equipments ) {
            if ( this.testEquipmentCondition(condition, equipments[value_index]) ) {
                return true;
            }
        }

        return false;
    }

    testEquipmentCondition(condition, equipment) {

        if ( condition.equipment_field && !equipment ) {
            return false;
        }

        // Check for equipment structured by section id to extract the equipment data being tested
        if ( !equipment.equipment_id && condition.form_section_id && equipment[condition.form_section_id] ) {
            return this.testEquipmentCondition(condition, equipment[condition.form_section_id] || {})
        }

        if ( condition['equipment_field'] === 'custom_field' ) {
            if ( !equipment.equipment_id ) {
                // get the form section id containing this equipment custom field value
                for ( let form_section_id in equipment ) {
                    for ( let custom_field_value of (equipment[form_section_id]?.field_values || []) ) {
                        if ( condition.custom_field_id == custom_field_value.custom_field_id ) {
                            return this.testEquipmentCondition(condition, equipment[form_section_id]);
                        }
                    }
                }
                return false;
            }

            return this.testCustomFieldCondition(condition, this.extractEquipmentCustomFieldValues(equipment));

        } else if ( ![undefined, null].includes(condition['condition_value']) ) {

            return this.doesValuePassCondition(condition, equipment[condition['equipment_field']]);
        }

        return true;
    }

    testCustomerCondition(condition, customer) {
        return (condition.customer_id || []).some((id) => {
            // id and customer_id might be a mix of string / number
            return id == customer.customer_id;
        });
    }

    testServiceItemCondition(condition, service_item_data) {
        let service_item_ids = (service_item_data.service_item_id || [])
            .map(item => Number(item));

        return (condition.service_item_id || [])
            .map(item => Number(item))
            .some((service_item_id) => service_item_ids.includes(service_item_id));
    }

    extractEquipmentCustomFieldValues(equipment) {
        let values = {};

        object_get(equipment, 'field_values', []).forEach((field_value) => {
            let current_field_value = values[field_value['custom_field_id']];
            if ( !current_field_value ) {
                values[field_value['custom_field_id']] = field_value.value;
                return;
            }

            if ( !Array.isArray(current_field_value) ) {
                current_field_value = [current_field_value];
                values[field_value['custom_field_id']] = current_field_value;
            }

            current_field_value.push(field_value.value);
        });

        return values;
    }

    doesValuePassCondition(condition, actual_value) {
        // Extract the field type to normalize the values for comparison
        let field_type = condition.field_type;

        // Normalize the values based on the field type for comparison
        actual_value = this.normalizeValue(field_type, actual_value);
        let condition_value = this.normalizeValue(field_type, condition.condition_value);

        switch ( condition.operator ) {
            case '=':
            case '==':
                condition_value = this.normalizeValueForEqualityComparison(condition_value);
                actual_value = this.normalizeValueForEqualityComparison(actual_value);

                return actual_value === condition_value;
            case '!=':
                condition_value = this.normalizeValueForEqualityComparison(condition_value);
                actual_value = this.normalizeValueForEqualityComparison(actual_value);

                return actual_value !== condition_value

            case '>':
                return actual_value > condition_value;

            case '>=':
                return actual_value >= condition_value;

            case '<':
                return actual_value < condition_value;

            case '<=':
                return actual_value <= condition_value;

            case '*':
                if ( !Array.isArray(condition_value) ) {
                    condition_value = [condition_value];
                }

                if ( !Array.isArray(actual_value) ) {
                    if ( actual_value != null ) {
                        actual_value = [actual_value];
                    } else {
                        actual_value = [];
                    }
                }

                // Check all possible condition values against all actual values.
                // If there is any intersection of values then the condition is satisfied
                return !!condition_value.find(v => actual_value.find(x => x == v));

            case 'contains':
                if ( !actual_value && condition_value ) {
                    return false;
                }

                if ( typeof actual_value !== 'string' || !Array.isArray(actual_value) ) {
                    actual_value = String(actual_value);
                }

                return actual_value?.indexOf(condition_value) !== -1;

            default:
                // Return true as a default case
                return true;
        }
    }

    normalizeValueForEqualityComparison(value) {
        if ( Array.isArray(value) ) {
            return String(value.slice().sort());
        }

        return value === null || value === undefined ? '' : String(value);
    }

    doesAnyValuePassCondition(condition, values) {
        for ( let index in values ) {
            if ( this.doesValuePassCondition(condition, values[index]) ) {
                return true;
            }
        }

        return false;
    }

    normalizeValue(field_type, value) {
        switch ( field_type ) {
            case 'date':
                return this.cleanDateValue(value);

            case 'number':
                return parseFloat(value);

            case 'formula':
                return value?.value || parseFloat(value);

            case 'time':
                return this.cleanTimeValue(value);

            default:
                return value;
        }
    }
}
