<template>
    <ks-modal
        v-if="show_modal"
        ref="modal"
        :title="title"
        :show-close="false"
        :close-on-escape="false"
        :close-on-mask-click="false"
    >
        <slot>
            There are unsaved changes. Do you want to leave?
        </slot>

        <div slot="footer">
            <vault-button :prevent="true" @click="confirm">
                {{confirmText}}
            </vault-button>
            <button class="button outline" @click.prevent="cancel">
                {{cancelText}}
            </button>
        </div>
    </ks-modal>
</template>

<script>
    export default {
        name: 'BeforeUnloadModal',

        props: {
            title: {
                default: 'Confirm page leave',
            },

            confirmText: {
                default: 'Yes, Ignore changes',
            },

            cancelText: {
                default: 'Cancel',
            },
        },

        data() {
            return {
                show_modal: false,
                resolve: null,
                reject: null,
            }
        },

        methods: {
            open() {
                this.show_modal = true;

                return this.$nextTick()
                    .then(() => {
                        this.$refs.modal.open();

                        return new Promise((resolve, reject) => {
                            this.resolve = resolve;
                            this.reject = reject;
                        })
                    })
            },

            confirm() {
                this.resolve(true);
                this.show_modal = false;
                this.$emit('confirm');
            },

            cancel() {
                this.resolve(false);
                this.show_modal = false;
            },
        },
    }
</script>
