<template>
    <nav>
        <router-link v-for="route in menu" :key="route.name" :to="{ name: route.name }">
            {{ parseName(route) | ucwords }}
        </router-link>
    </nav>
</template>


<script>
    export default {
        props: {
            group: {},
            menu: {
                type: Array,
                default() {
                    return [];
                }
            }
        },

        data() {
            return {};
        },

        methods: {
            parseName(route) {
                if ( 'meta' in route ) {
                    if ('name' in route.meta) {
                        return route.meta.name;
                    }
                }

                return route.name;
            }
        }
    }
</script>
