import {mapGetters, mapActions} from 'vuex';

export default {

    computed: {
        ...mapGetters([
            'breadcrumbs'
        ])
    },

    data() {
        return {
            auto_pop: true
        }
    },

    methods: {
        ...mapActions([
            'resetBreadcrumb',
            'pushBreadcrumb',
            'popBreadcrumb',
            'editBreadcrumb'
        ])
    },

    beforeDestroy() {
        if ( this.auto_pop ) {
            this.popBreadcrumb();
        }
    },
}
