<template>
    <canvas :width="width" :height="height" ref="chart"></canvas>
</template>

<script>
    // External Dependencies
    import Chart from 'chart.js';
    import _defaultsDeep from 'lodash/defaultsDeep';

    // Internal Dependencies
    import {isObject} from 'helpers/types';

    const chart_color_palette = {
        1: '#6699ff',
        2: '#66ff99',
        3: '#ffcc00',
        4: '#336699',
        5: '#993366',
        6: '#b3ffff',
        7: '#ff6699',
        8: '#6600ff',
        9: '#cc0099',
        10: '#666699',
        11: '#33cccc',
        12: '#ffcccc',
        13: '#ffff66',
        14: '#339966',
        15: '#ff6666',
        16: '#3366cc',
        17: '#ffccee',
        18: '#336600',
        19: '#990099',
        20: '#c6ffb3',
    };

    export default {
        name: 'BaseGraph',

        props: {
            title: {
                default: ''
            },

            width: {
                default: 400
            },

            height: {
                default: 500
            },

            chartType: {
                default: '',
            }
        },

        data() {
            return {
                defaultOptions: {
                    //
                },
                data: {},
                options: {},
                chart: null,
            }
        },

        mounted() {
            if ( this.title !== '' ) {
                this.setTitle(this.title);
            }
        },

        methods: {
            setData(data) {
                let colors = [];

                // Assign colors from the palette. If we reach the end, restart from color #1.
                for (let key in data.datasets[0].data) {
                    let color;

                    if ( 'backgroundColor' in data.datasets[0] && typeof data.datasets[0].backgroundColor[key] != 'undefined' ) {
                        color = data.datasets[0].backgroundColor[key];
                    } else {
                        let color_index = (parseInt(key) + 1) % Object.keys(chart_color_palette).length;

                        if (color_index === 0) {
                            color_index = Object.keys(chart_color_palette).length;
                        }

                        color = chart_color_palette[color_index];
                    }

                    colors.push(color);
                }

                data.datasets[0].backgroundColor = colors;

                this.$set(this, 'data', data);

                return this;
            },

            setTitle(title) {
                if ( !isObject(title) ) {
                    title = {
                        display: true,
                        text: title
                    }
                }
                this.$set(this.options, 'title', title);

                return this;
            },

            setOptions(options) {
                this.$set(this, 'options', _defaultsDeep(options, this.options, this.defaultOptions));

                return this;
            },

            draw() {
                const vm = this;

                this.chart = new Chart(vm.$refs.chart.getContext('2d'), {
                    type: vm.chartType,
                    data: vm.data,
                    options: vm.options
                })
            },

            update() {
                this.chart.data.datasets.forEach((dataset, index) => {
                    dataset.data = this.data.datasets[index].data;
                });

                this.chart.update({
                    duration: 0,
                });
            },

            updateOptions(key) {
                this.chart.options[key] = this.options[key];

                this.update();
            },

            getChart() {
                return this.chart;
            },
        },

        watch: {
            title() {
                if ( this.title !== '' ) {
                    this.setTitle(this.title);

                    this.$nextTick(() => {
                        this.updateOptions('title');
                    });
                }
            }
        }
    }
</script>