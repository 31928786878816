<template>
    <div class="file-uploader relative">
        <div v-if="upload_error && show_error" id="upload-errors" class="error m-8">
            {{upload_error}}
        </div>
        <div
            v-if="allowDrop"
            ref="dropZone"
            @drop="stopDrop"
            class="drop-zone absolute"
            :class="{ 'drop-hover': dropHover, 'drop-active full-height': dragging }"
        ></div>
        <div v-show="canAdd && !hasFilesToUpload" :class="{ 'inline-block': allowDrop || showButton }">
            <div v-if="allowDrop">
                Drag files here to upload
            </div>
            <a href="#" v-show="showButton" :class="buttonClass" ref="browse" draggable="false" @click.prevent="upload_error = ''">{{buttonText}}</a>
        </div>
        <ul v-if="hasFilesToUpload && !autoUpload" class="file-upload-list">
            <li v-for="file in files_to_upload">
                <span
                    class="file-progress ks-badge"
                    :class="{ 'warning': file.percent != 100, 'success': file.percent == 100 }"
                >
                    {{file.percent}}%
                </span>
                <span class="file-name">{{file.name}}</span>
                <span class="file-size">{{humanFileSize(file.size)}}</span>
                <icon-checkmark v-if="file.percent == 100"></icon-checkmark>
            </li>
            <li v-if="files_to_upload.length" class="button-li">
                <button v-if="!autoUpload" @click.prevent="startUpload" class="button green">Upload</button>
                <button @click.prevent="cancelUpload" class="button">Cancel</button>
            </li>
        </ul>
    </div>
</template>

<script>
    import Cookie from 'js-cookie';
    import plupload from 'plupload/js/plupload.min';

    import {humanFileSize} from 'helpers/files';

    import {IconCheckmark} from '../Icons';

    export default {
        name: 'FileUploader',

        props: {
            uploadUrl: {},
            show_error: {
                type: Boolean,
                default: true
            },
            canAdd: {
                type: Boolean,
                default: true,
            },
            mimeTypes: {
                type: Array,
                default() {
                    return [];
                }
            },
            buttonText: {
                type: String,
                default: 'Add Files'
            },
            autoUpload: {
                type: Boolean,
                default: false
            },
            maxFileSize: {
                type: String,
                default: '750mb',
            },
            headers: {
                type: Object,
                default() {
                    return {};
                }
            },
            uploadParams: {
                type: Object,
                default() {
                    return {};
                }
            },
            multiple: {
                type: Boolean,
                default: true
            },
            parseResponse: {
                type: Boolean,
                default: true
            },
            allowDrop: {
                type: Boolean,
                default: false
            },
            removeAfterUpload: {
                type: Boolean,
                default: false
            },
            buttonClass: {
                type: String,
                default: 'button',
            },
            referenceType: {
                default: null,
            },
            referenceId: {
                default: null,
            },
            chunkSize: {
                type: String,
                default: '5mb'
            },
            showButton: {
                type: Boolean,
                default: true,
            },
        },

        computed: {
            pluploadOptions() {
                let options = {
                    browse_button: this.$refs.browse,
                    url: this.url,
                    headers: {
                        "X-Requested-With": "XMLHttpRequest",
                        "Accept": "application/json",
                        'Authorization': 'Bearer ' + Cookie.get('vat'),
                        ...this.headers,
                    },
                    multipart_params: this.uploadParams,
                    filters: {
                        max_file_size: this.maxFileSize,
                        mime_types: this.mimeTypes
                    },
                    multi_selection: this.multiple,
                    chunk_size: this.chunkSize,
                };

                if ( this.allowDrop ) {
                    options.drop_element = this.$refs.dropZone;
                }

                return options;
            },

            hasFilesToUpload() {
                return this.files_to_upload.length > 0;
            },

            url() {
                return this.uploadUrl ||
                    `${import.meta.env.VITE_API_URL}/${this.referenceType}/${this.referenceId}/files`;
            },
        },

        data() {
            return {
                plupload: null,
                files_to_upload: [],
                file_progress: {},
                upload_error: null,
                dropHover: false,
                dragging: false
            }
        },

        mounted() {
            this.$nextTick(() => {
                this.initializePlupload();
                this.$on('upload-completed', () => {
                    setTimeout(() => {
                        this.file_progress = {};
                        this.files_to_upload = [];
                    }, 100);
                });
                if ( this.allowDrop ) {
                    let timer = null;
                    let dropTimer = null;
                    this.addEvent(window, ['dragenter', 'dragover'], () => {
                        clearTimeout(timer);
                        this.dragging = true;
                    });
                    this.addEvent(window, ['dragleave', 'dragstop'], () => {
                        clearTimeout(timer);
                        timer = setTimeout(() => {
                            this.dragging = false;
                        }, 200);
                    });

                    this.addEvent(this.$refs.dropZone, ['dragenter', 'dragover'], () => {
                        clearTimeout(dropTimer);
                        this.dropHover = true;
                    });
                    this.addEvent(this.$refs.dropZone, ['dragleave', 'dragstop'], () => {
                        clearTimeout(dropTimer);
                        dropTimer = setTimeout(() => {
                            this.dropHover = false;
                        }, 200);
                    });
                }
            });
        },

        methods: {
            humanFileSize(size) {
                return humanFileSize(size);
            },

            refreshUploader() {
                this.plupload.refresh();
            },
            addEvent(el, events, callback) {
                for ( var i in events ) {
                    el.addEventListener(events[i], callback);
                }
            },
            stopDrop() {
                this.dragging = false;
                this.dropHover = false;
            },
            startUpload() {
                this.$emit('started-upload', this.files_to_upload);
                this.plupload.start();
            },
            cancelUpload() {
                for ( var i in this.files_to_upload ) {
                    this.plupload.removeFile(this.files_to_upload[i]);
                }

                this.files_to_upload = [];

                this.refreshUploader();
                this.$emit('canceled-upload')
            },
            initializePlupload() {
                this.plupload = new plupload.Uploader(this.pluploadOptions);

                this.plupload.init();

                this.setupPluploadEvents();
            },
            setupPluploadEvents() {
                // Add file
                this.plupload.bind('FilesAdded', function (up, files) {
                    this.$emit('files-added', {up, files});

                    plupload.each(files, function (file) {
                        this.files_to_upload.push(file);
                    }.bind(this));

                    // If auto upload is set
                    if ( this.autoUpload ) {
                        this.startUpload();
                    }
                }.bind(this));

                // File progress
                this.plupload.bind('UploadProgress', function(up, file) {
                    this.$emit('upload-progress', up, file);

                    this.file_progress[file.id] = file.percent;
                }.bind(this));

                // Upload Errors
                this.plupload.bind('Error', function (up, err) {
                    let response = '';
                    try {
                        response = JSON.parse(err.response);
                    } catch ( e ) {}

                    this.$emit('upload-error', response, err);
                    this.upload_error = "Error " + err.message;
                }.bind(this));

                // A file was uploaded
                this.plupload.bind('FileUploaded', function (up, file, response) {
                    response = ( this.parseResponse ? JSON.parse(response.response) : response.response );

                    this.$emit('file-uploaded', up, file, response);

                    this.cleanupFile(file);
                }.bind(this));

                // Entire queue finished uploading
                this.plupload.bind('UploadComplete', function (up, files) {
                    this.upload_error = '';
                    this.$emit('upload-completed', up, files);
                }.bind(this));

                this.plupload.bind('ChunkUploaded', function (up, file, info) {
                    const response = JSON.parse(info.response);

                    up.settings.multipart_params = {
                        ...up.settings.multipart_params,
                        ...{file_id: response.data.file_id, upload_id: response.data.upload_id}
                    }
                }.bind(this));
            },

            triggerBrowse() {
                this.$refs.browse.click();
            },

            cleanupFile(file) {
                this.plupload.removeFile(file);
                if ( this.removeAfterUpload ) {
                    let index = this.files_to_upload.indexOf(file);
                    if ( index != -1 ) {
                        this.files_to_upload.splice(index, 1);
                    }
                }
            },
        },

        watch: {
            url() {
                if ( this.plupload ) {
                    this.plupload.setOption('url', this.url);
                }
            }
        },

        components: {
            IconCheckmark
        }
    }
</script>
