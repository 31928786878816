// External Dependencies

// Internal Dependencies
import EventHandler from 'helpers/EventHandler';
import ImpersonateWarningModal from '@Vault/Impersonate/components/ImpersonateWarningModal.vue';
import Cookie from 'js-cookie';

export default {
    data() {
        return {
            refresh_tab: false,
        }
    },

    methods: {
        impersonateListener() {
            EventHandler.channel('user')
                .listen(`impersonate:${this.userId}`, event => {
                    const impersonate_cookie = Cookie.get('ivat');

                    if ( (event.impersonating && typeof impersonate_cookie !== 'undefined') ||
                        (!event.impersonating && this.is_impersonating)
                    ) {
                        this.refresh_tab = true;
                    }

                    if ( !event.impersonating && !this.is_impersonating ) {
                        this.refresh_tab = false;
                        this.$refs.warningModal.close();
                    }

                    this.handleTabVisibility();
                });
        },

        handleTabVisibility() {
            if ( !this.refresh_tab ) {
                return;
            }

            if ( this.is_impersonating ) {
                try {
                    let location = window.sessionStorage.getItem('fossa_impersonate_exit_location');
                    if ( location ) {
                        window.location.href = location;
                        return;
                    }
                } finally {
                    window.sessionStorage.removeItem('fossa_impersonate_exit_location');
                }
                return location.href = '/app/dashboard';
            }

            this.$refs.warningModal.open();
        },
    },

    components: {
        ImpersonateWarningModal,
    }
}