<template>
    <div>
        <page-header>
            <page-breadcrumb></page-breadcrumb>
        </page-header>

        <router-view></router-view>
    </div>
</template>

<script>
// Internal Dependencies
import BreadCrumbs from 'BreadCrumbs';

export default {
    mixins: [BreadCrumbs],

    beforeMount() {
        this.pushBreadcrumb({
            title: 'Timesheets',
            to: {
                name: 'timesheets',
            }
        });
    },
}
</script>
