<template>
    <div>
        <div class="row">
            <router-view></router-view>
        </div>
    </div>
</template>

<script>
    import BreadCrumb from '../../../BreadCrumbs';
    export default {
        name: 'Imports',

        mixins: [BreadCrumb],

        beforeMount() {
            this.pushBreadcrumb({
                title: 'Import Templates',
                to: {
                    name: 'admin-imports-list'
                }
            });
        }
    }
</script>