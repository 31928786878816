<template>
    <div class="flex jc-between align-center relative">
        <div :style="labelStyles">
            <slot></slot>
        </div>

        <transition name="slide-right">
            <div v-if="allowDelete && !deleting" class="ml-2">
                <div
                    v-if="confirming"
                    class="absolute flex align-center bg-white p-2 text-xs nowrap"
                    style="overflow:hidden;top:0;right:0;bottom:0;"
                    :style="confirmBorderStyle"
                >
                    <a href="#" @click.prevent="deleted" class="uppercase text-red-500">
                        Remove
                    </a>
                    <a href="#" @click.prevent="confirming = false" class="ml-2 uppercase">
                        Cancel
                    </a>
                </div>

                <div v-else class="flex align-center px-1">
                    <a href="#" @click.prevent="beginDelete" :class="deleteIconClass">
                        <vault-icon :icon="deleteIcon"></vault-icon>
                    </a>
                </div>
            </div>
        </transition>

        <transition name="slidefade">
            <div v-if="deleting" class="absolute bg-red-500 full-height"></div>
        </transition>
    </div>
</template>

<script>
    import { faTimes } from '@fortawesome/pro-regular-svg-icons/faTimes';

    export default {
        name: 'SlideDelete',

        props: {
            allowDelete: {
                type: Boolean,
                default: true,
            },

            confirm: {
                type: Boolean,
                default: true,
            },

            deleteIcon: {
                default: () => faTimes,
            },

            visuals: {
                type: Boolean,
                default: false,
            },

            deleteIconClass: {
                default: 'text-sm text-red-500',
            },

            confirmBorderStyle: {
                type: Object,
                default: () => ({})
            },
        },

        computed: {
            labelStyles() {
                return {
                    flexGrow: 1,
                    width: `calc(100% - ${this.allowDelete ? '1.825rem' : '1rem'})`,
                }
            },
        },

        data() {
            return {
                confirming: false,
                deleting: false,
            };
        },

        methods: {
            beginDelete() {
                if ( !this.confirm ) {
                    this.deleted();
                    return;
                }

                this.$emit('click-delete');
                this.confirming = true;
            },

            deleted() {
                this.deleting = true;

                this.$nextTick(() => {
                    this.$emit('deleted', this.visuals ? 500 : 0);
                });
            },
        },
    }
</script>
