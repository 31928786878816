import Repository from '../../Repository';

export class UserRepository extends Repository {
    constructor() {
        super();
        this.resource = 'users'
    }

    deactivate(user_id) {
        return this.put(`${user_id}/deactivate`);
    }

    activate(user_id) {
        return this.put(`${user_id}/activate`);
    }
}

const Users = new UserRepository();

export default Users;