<template>
    <div class="sm-12">
        <router-view></router-view>
    </div>
</template>

<script>
    import Breadcrumbs from '../../BreadCrumbs';

    export default {
        name: 'Invoices',

        mixins: [Breadcrumbs],

        props: {},

        data() {
            return {}
        },

        beforeMount() {
            this.pushBreadcrumb({
                title: 'Invoices',
                to: {
                    name: 'invoice-list'
                }
            });
        },

    }
</script>
