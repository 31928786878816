import Repository from 'Repository';

export class SavedSearchRepository extends Repository {
    constructor() {
        super();
        this.resource = 'saved-search';
    }
}

const SavedSearches = new SavedSearchRepository();

export default SavedSearches;

/**
 * @typedef SavedSearch
 * @type {object}
 * @property {number} saved_search_id
 * @property {number} user_id
 * @property {string} namespace
 * @property {object} data
 * @property {?string} name
 * @property {boolean} public
 * @property {?ScheduledExport[]} scheduled_exports
 */
