<template>
    <div>
        <div class="row rel sm-collapse search-header">
            <div class="sm-half">
                <search-bar placeholder="Search Users..." @search="runSearch" :delay="250" ref="searchBar"></search-bar>
            </div>
        </div>
        <div class="row">
            <div>
                <ks-loader-line class="col" :show="searching"></ks-loader-line>

                <table class="table primary striped text-sm">
                    <thead>
                    <tr>
                        <th>Entity</th>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Role</th>
                        <th>Actions</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="user in users">
                        <td>
                            {{user.entity.entity_name}}
                        </td>
                        <td>{{user.first_name}} {{user.last_name}}</td>
                        <td>{{user.email}}</td>
                        <td>
                            {{user.role.role_name}}
                            <div v-if="user.customer" class="muted">
                                {{user.customer.customer_name}}
                            </div>
                        </td>
                        <td>
                            <a href="#" @click.prevent="impersonateUser(user)" class="button outline nowrap">
                                <vault-icon :icon="userSecretIcon"></vault-icon> Impersonate
                            </a>
                        </td>
                    </tr>
                    </tbody>
                </table>

                <ks-paginator :pagination="pagination" :routed="true"></ks-paginator>
            </div>
        </div>
    </div>
</template>

<script>
    // External Dependencies
    import api from 'kickstart-ui/lib/api';
    import {faUserSecret} from '@fortawesome/pro-solid-svg-icons/faUserSecret';

    // Internal Dependencies
    import Users from '../../Users/UserRepository';
    import BreadCrumbs from 'BreadCrumbs';

    export default {
        name: 'SystemUsers',

        mixins: [BreadCrumbs],

        computed: {
            emptyResults() {
                return !this.searching && this.search_term != '' && this.users.length == 0;
            }
        },

        data() {
            return {
                loading: false,
                search_term: '',
                searching: false,
                users: [],
                pagination: {},
                userSecretIcon: faUserSecret,
            }
        },

        mounted() {
            this.$nextTick(() => {
                this.$refs.searchBar.focus();
                this.pushBreadcrumb('System Users');
            });
        },

        methods: {
            runSearch(e) {
                this.searching = true;
                this.search_term = e;

                if ( !this.search_term ) {
                    this.searching = false;
                    this.$set(this, 'users', []);
                    this.$set(this, 'pagination', {});
                    return;
                }

                Users.list({
                    search_name: e,
                    all_users: true,
                }).then(({data: users, paginator}) => {
                    this.searching = false;

                    this.$set(this, 'users', users);
                    this.$set(this, 'pagination', paginator);
                });
            },

            impersonateUser(user) {
                api.post(`/impersonate/${user.user_id}`)
                    .then(() => {
                        const route = this.$router.resolve({name: 'dashboard'});

                        window.location.href = route.href;
                    })
                    .catch(({response}) => {
                        if ( response.status == '404' || response.status == '403' ) {
                            this.$toast({
                                type: 'error',
                                message: response.data.message
                            })
                        }

                    });
            },
        },
    }
</script>