import { dateModify } from 'kickstart-ui/lib/dates';

/**
 *
 * @param value
 * @returns {string}
 */
export function formatDateWithin(value) {
    let [from, to] = value.split(',');

    if ( to && from ) {
        return `between ${to} and ${from} days ago`;
    }

    if ( to ) {
        return `up to ${to} days ago`;
    }

    return `from ${from} days ago`;
}

/**
 *
 * @param value
 * @returns {string}
 */
export function formatDateUpcoming(value) {
    const upcoming = String(value).split(',');
    let [from, to] = upcoming.length === 1
        ? [null, upcoming[0]]
        : upcoming;

    if ( from && to ) {
        return `between ${from} and ${to} days from now`;
    }

    if ( to ) {
        return `up to ${to} days from now`;
    }

    return `from ${from} days from now`;
}

/**
 *
 * @param {Date} date
 * @param {'Y'|'M'|'W'|'D'} interval
 * @param {number} value
 * @returns {Date}
 */
export function incrementDate(date, interval = 'D', value = 1) {
    const increment = {
        'Y': 'year',
        'M': 'month',
        'W': 'week',
        'D': 'day',
    }[interval] ?? 'day';

    return dateModify(date, increment, value);
}
