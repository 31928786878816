import EventHandler from 'helpers/EventHandler.js';

const STATUS_SUCCEEDED = 'complete';
const STATUS_IN_PROGRESS = 'progress';
const STATUS_PENDING = 'pending';

export default {

    computed: {
        progressIsCompleted() {
            return this.status === STATUS_SUCCEEDED;
        }
    },

    data() {
        return {
            job_key: '',
            progressValue: 0,
            status: STATUS_PENDING,
        };
    },

    methods: {
        resetProgressState() {
            this.progressValue = 0;
            this.status = STATUS_PENDING;
        },
        /**
         * @param {string} job_key
         * @param {function} [onComplete=null]
         */
        addProgressListener(job_key, onComplete = null) {
            this.job_key = job_key;
            this.status = STATUS_IN_PROGRESS;
            EventHandler.channel('user')
                .listen(this.job_key, ({progress, data}) => this.onProgressEvent(progress, data, onComplete));
        },

        onProgressEvent(progress, data, onComplete) {
            this.progressValue = parseFloat(progress);

            if ( progress < 100 ) {
                return;
            }

            this.status = STATUS_SUCCEEDED;

            EventHandler.channel('user').unlisten(this.job_key);

            // Invoke the onComplete callback (if provided) along with the data returned by the broadcasted event.
            if ( !!onComplete ) {
                onComplete(data);
            }
        },
    }
};
