<template>
    <div class="row">
        <router-view></router-view>
    </div>
</template>

<script>

    // Helpers + mixins
    import BreadCrumbs from '@Vault/BreadCrumbs';

    export default {
        name: 'InventoryEquipment',

        mixins: [
            BreadCrumbs,
        ],

        beforeMount() {
            this.pushBreadcrumb({
                title: 'Equipment',
                to: {
                    name: 'inventory-equipment'
                }
            });
        },
    };
</script>
