<template>
    <ks-modal
        ref="modal"
        :title="modalTitle"
        min-width="300px"
    >
        <div class="mb-4">
            <slot name="header">
                Exporting records.
            </slot>
        </div>

        <template v-if="templates.length">
            <form-group v-if="templates.length > 1" label="Export Template">
                <ks-select
                    name="export_template_id"
                    :items="templates"
                    label-key="export_template_name"
                    v-model="export_template"
                >
                    <template v-slot:label="{value, label}">
                        {{value.export_template_name}} <span class="muted">({{value.format_extension}})</span>
                    </template>
                    <template v-slot:default="{item}">
                        {{item.export_template_name}} <span class="muted">({{item.format_extension}})</span>
                    </template>
                </ks-select>
            </form-group>
        </template>

        <div v-if="pending">
            <table class="full-width my-2" style="border-spacing: 0;">
                <tbody>
                    <export-template-progress-row
                        :pending="pending"
                        @succeeded="exportSuccess"
                        @file-ready="downloadFile"
                        @failed="exportFail"
                    ></export-template-progress-row>
                </tbody>
            </table>
        </div>

        <div v-if="!templates.length && !autoExport">
            <router-link :to="{name: 'admin-exports-list'}">Please create a template</router-link> in order to export records.
        </div>

        <div slot="footer">
            <vault-button :disabled="!export_template && !autoExport" :spin="!!(exporting || loading_pending_export)" @click.prevent="startExport">Export</vault-button>
            <vault-button class="outline" @click.prevent="$refs.modal.close()">Cancel</vault-button>
        </div>
    </ks-modal>
</template>

<script>
    import ExportTemplateProgressRow from '@Vault/Admin/Exports/components/ExportTemplateProgressRow.vue';
    import Exports from '@Vault/Admin/Exports/repositories/ExportsRepository';

    export default {
        props: {
            modalTitle: {
                default: 'Export',
                type: String,
            },

            templates: {
                default: null,
                type: Array,
            },

            filters: {
                type: Object,
                default() {
                    return {};
                },
            },

            autoExport: {
                type: Boolean,
                default: false,
            },

            exportCode: {
                type: String,
            },
        },

        computed: {},

        data() {
            return {
                loading_pending_export: false,
                pending: null,
                export_template: null,
                exporting: false,
            };
        },

        mounted() {
            if ( this.autoExport && !this.exportCode ) {
                throw new Error('Prop exportCode is required when autoExport is enabled.');
            }

            if ( this.templates.length === 1 ) {
                this.export_template = this.templates[0];
            }
        },

        methods: {
            open() {
                this.resetForm();
                this.$refs.modal.open();
            },

            exportSuccess() {
                this.exporting = false;
            },

            exportFail() {
                this.exporting = false;
                this.file_url = false;
            },

            resetForm() {
                this.exporting = false;
                this.pending = null;
                this.file_url = null;
            },

            downloadFile(file_url) {
                if ( file_url ) {
                    window.open(file_url, '_blank');
                }
            },

            startExport() {
                this.loading_pending_export = true;
                this.pending = null;

                if ( !this.export_template_id && this.autoExport ) {
                    Exports.post(`auto-export/${this.exportCode}`, {...this.filters, auto_create_all_fields: true})
                        .then(({data}) => {
                            this.$toast({
                                type: 'success',
                                message: 'Export started.',
                            });
                            this.pending = data.data.pending_export;

                        })
                        .catch((response) => {
                            this.$toast({
                                type: 'error',
                                message: 'There was an error generating export.',
                            });

                            this.resetForm();
                        })
                        .finally(() => {
                            this.loading_pending_export = false;
                        });

                    return;
                }

                Exports.post(this.export_template.export_template_id, {...this.filters})
                    .then(({data}) => {
                        this.$toast({
                            type: 'success',
                            message: 'Export started.',
                        });
                        this.pending = data.data.pending_export;
                    })
                    .catch((response) => {
                        this.$toast({
                            type: 'error',
                            message: 'There was an error generating export.',
                        });

                        this.resetForm();
                    })
                    .finally(() => {
                        this.loading_pending_export = false;
                    })
            },
        },

        components: {
            ExportTemplateProgressRow,
        },

        watch: {
            templates() {
                if ( this.templates.length === 1 ) {
                    this.export_template = this.templates[0];
                }
            },
        },
    }
</script>
