
const Inventory = () => import('./components/Inventory.vue');
const InventoryLocations = () => import('./components/InventoryLocations.vue');
const InventoryLocationList = () => import('./components/InventoryLocationsList.vue');
const InventoryDashboard = () => import('./components/Dashboard/InventoryDashboard.vue');
const InventoryEquipment = () => import('./components/InventoryEquipment.vue');
const InventoryEquipmentList = () => import('./components/InventoryEquipmentList.vue');
const EquipmentProfile = () => import('./components/EquipmentProfile.vue');
const InventoryCompare = () => import('./components/InventoryCompare.vue');
const InventoryEquipmentCompare = () => import('./components/InventoryEquipmentCompare.vue');
const InventoryLocationProfile = () => import('./components/InventoryLocationsProfile.vue');
const InventoryShow = () => import('./components/InventoryShow.vue');
const InventoryDetails = () => import('./components/InventoryDetails.vue');
const InventoryLots = () => import('./components/InventoryLots.vue');
const InventoryTransactions = () => import('./components/InventoryTransactions.vue');
const InventoryFleet = () => import('./components/InventoryFleet.vue');
const InventoryProducts = () => import('./components/InventoryProducts.vue');
const InventoryProductList = () => import('./components/InventoryProductList.vue');
const InventoryProductShow = () => import('./components/InventoryProductShow.vue');
const FleetList = () => import('./components/FleetList.vue');
const FleetShow = () => import('./components/FleetShow.vue');
const FleetForm = () => import('./components/FleetForm.vue');
const FleetShowTabs = () => import('./components/FleetShowTabs.vue');
const FleetEquipmentForm = () => import('./components/FleetEquipmentForm.vue');
const EquipmentProfileTabs = () => import('./components/EquipmentProfileTabs.vue');


export default [
    {
        path: '/inventory',
        component: Inventory,
        meta: {
            group: 'main',
            title: 'Inventory',
            icon: 'IconInventory',
            perms: ['inventory_view'],
            config: 'enable_inventory',
        },
        children: [
            {
                path: '',
                component: InventoryDashboard,
                name: 'inventory-dashboard',
                meta: {
                    title: 'Dashboard',
                },
            },
            {
                path: 'products',
                component: InventoryProducts,
                props: true,
                meta: {
                    group: 'main',
                    title: 'Products',
                },
                children: [
                    {
                        path: '',
                        component: InventoryProductList,
                        name: 'inventory-products',
                        props: true,
                    },
                    {
                        path: ':item_id(\\d+)',
                        component: InventoryProductShow,
                        name: 'inventory-product-show',
                        props: true,
                    },
                ],
            },
            {
                path: 'locations',
                component: InventoryLocations,
                props: true,
                meta: {
                    group: 'main',
                    title: 'Locations',
                    exact: true
                },
                children: [
                    {
                        path: '',
                        component: InventoryLocationList,
                        name: 'inventory',
                        props: true,
                    },
                    {
                        path: 'compare',
                        component: InventoryCompare,
                        name: 'compare',
                    },
                    {
                        path: ':reference_type(location|fleet)/:reference_id(\\d+)',
                        component: InventoryLocationProfile,
                        props: true,
                        children: [
                            {
                                path: '',
                                name: 'reference-inventory',
                                props: true,
                                component: InventoryShow,
                            },
                            {
                                path: 'item/:item_id(\\d+)',
                                component: InventoryDetails,
                                props: true,
                                children: [
                                    {
                                        path: 'lots',
                                        name: 'inventory-lots',
                                        props: true,
                                        component: InventoryLots,
                                    },
                                    {
                                        path: 'transactions',
                                        name: 'inventory-transactions',
                                        props: true,
                                        component: InventoryTransactions,
                                    },
                                ],
                            },
                        ]
                    },
                ]
            },
            {
                path: '/fleet',
                component: InventoryFleet,
                meta: {
                    group: 'main',
                    title: 'Fleet'
                },
                children: [
                    {
                        path: '',
                        name: 'fleet-list',
                        component: FleetList,
                    },
                    {
                        path: ':fleet_id(\\d+)',
                        props: true,
                        component: FleetShow,
                        children: [
                            {
                                path: '',
                                name: 'fleet-show',
                                props: true,
                                component: FleetShowTabs,
                            },
                            {
                                path: 'equipment',
                                name: 'fleet-equipment-create',
                                props: true,
                                component: FleetEquipmentForm,
                            },
                            {
                                path: 'equipment/:equipment_id',
                                name: 'fleet-equipment-edit',
                                props: true,
                                component: FleetEquipmentForm,
                            },
                        ]
                    },
                    {
                        path: 'create',
                        name: 'fleet-create',
                        props: true,
                        component: FleetForm,
                    },
                    {
                        path: ':fleet_id/edit',
                        name: 'fleet-edit',
                        props: true,
                        component: FleetForm,
                    }
                ]
            },
            {
                path: 'equipment',
                component: InventoryEquipment,
                props: true,
                meta: {
                    group: 'main',
                    title: 'Equipment',
                    exact: true
                },
                children: [
                    {
                        path: '',
                        name: 'inventory-equipment',
                        props: true,
                        component: InventoryEquipmentList,
                    },
                    {
                        path: 'compare',
                        component: InventoryEquipmentCompare,
                        name: 'inventory-equipment-compare',
                    },
                    {
                        path: 'create',
                        name: 'inventory-equipment-create',
                        props: true,
                        component: FleetEquipmentForm,
                    },
                    {
                        path: ':equipment_id',
                        props: true,
                        component: EquipmentProfile,
                        children: [
                            {
                                path: '',
                                name: 'inventory-equipment-show',
                                component: EquipmentProfileTabs,
                                props: true,
                            },
                            {
                                path: 'edit',
                                name: 'inventory-equipment-edit',
                                props: true,
                                component: FleetEquipmentForm,
                            },
                        ]
                    }
                ]
            },
        ]
    }
]
