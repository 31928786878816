const FormsAdmin = () => import('./components/FormsAdmin.vue');
const FormsAdminDashboard = () => import('./components/FormsAdminDashboard.vue');
const AdminFormsList = () => import('./components/AdminFormsList.vue');
const AdminFormsCreate = () => import('./components/AdminFormsCreate.vue');
const AdminFormsEdit = () => import('./components/AdminFormsEdit.vue');
const AdminFormEntries = () => import('./components/AdminFormEntries.vue');
const AdminFormsImportForm = () => import('./components/AdminFormsImportForm.vue');
const AdminPrintViewBuilder = () => import('./components/PrintView/AdminPrintViewBuilder.vue');
const AdminFormTriggersList = () => import('./components/AdminFormTriggersList.vue');

export default [
    {
        path: 'forms',
        component: FormsAdminDashboard,
        meta: {
            perms: ['admin_forms']
        },
        children: [
            {
                path: 'list',
                component: AdminFormsList,
                name: 'admin-forms-list',
            },
            {
                path: 'form-triggers',
                component: AdminFormTriggersList,
                name: 'admin-forms-triggers'
            }
        ]
    },
    {
        path: 'forms',
        component: FormsAdmin,
        meta: {
            perms: ['admin_forms']
        },
        children: [
            {
                path: 'create',
                component: AdminFormsCreate,
                name: 'admin-forms-create'
            },
            {
                path: ':form_id/edit',
                props: true,
                component: AdminFormsEdit,
                name: 'admin-forms-edit'
            },
            {
                path: 'entries/:form_id',
                props: true,
                component: AdminFormEntries,
                name: 'admin-forms-entries'
            },
            {
                path: 'import',
                props: true,
                component: AdminFormsImportForm,
                name: 'admin-forms-import'
            },
            {
                path: ':form_id/print-view/:form_print_view_id/edit',
                props: true,
                component: AdminPrintViewBuilder,
                name: 'admin-form-print-view-edit'
            },
        ]
    }
]
