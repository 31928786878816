
export default {
    methods: {
        hasImage(item = this.item) {
            return !!item.image;
        },

        imageUrl(item = this.item) {
            if ( !this.hasImage(item) ) {
                return '';
            }

            return item.image.signed_thumbnail_url ||
                item.image.url ||
                '/service_item/' + item.service_item_id + '/files/' + item.image.file_id ||
                '';
        },

        imageAlt(item = this.item) {
            if ( !this.hasImage(item) ) {
                return '';
            }

            return item.image.name;
        },
    },
}
